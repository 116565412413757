import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceFinalService {

  http = inject(HttpClient);
  url = environment.apiEofficeConfig.endpoint + '/invoiceFinal';
  
  getInvoiceFinalData(searchParams: any) {
    return this.http.post(this.url + '/GetAll', searchParams);
  }

  deleteInvoiceFinalData(id: number) {
    return this.http.delete(this.url + '/' + id);
  }

}
