<div class="modal-header">
  <h2 mat-dialog-title>
    @if(this.model.id == 0){
    {{ "seniority.add" | translate }}
    }@else {
    {{ "seniority.edit" | translate }}
    }
  </h2>
  <mat-icon
    aria-hidden="false"
    aria-label="pending icon"
    fontIcon="assured_workload"
  ></mat-icon>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <form [formGroup]="seniorityForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>{{ "seniority.title" | translate }}</mat-label>
        <input
          matInput
          formControlName="title"
          placeholder="{{ 'seniority.title' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "seniority.unitPrice" | translate }}</mat-label>
        <input
          matInput
          formControlName="unitPrice"
          placeholder="{{ 'seniority.unitPrice' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "seniority.description" | translate }}</mat-label>
        <input
          matInput
          formControlName="description"
          placeholder="{{ 'seniority.description' | translate }}"
          required
        />
      </mat-form-field>

      <mat-checkbox formControlName="active" required>
        {{ "seniority.isActive" | translate }}
      </mat-checkbox>
    </div>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1" (click)="onClose()">
    {{ "buttons.cancel" | translate }}
  </button>
  <button mat-raised-button color="primary" type="submit" (click)="onSubmit()">
    {{ "buttons.save" | translate }}
  </button>
</mat-dialog-actions>
