import {
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WorkFromHome } from '../interfaces/workfromhome';
import { WorkFromHomeService } from '../work-from-home.service';
import moment from 'moment';
import { EmployeesService } from '../../employee-menagement/services/employees.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AsyncPipe, DatePipe, formatDate } from '@angular/common';
import { Employee } from '../../employee-menagement/interfaces/employee';
import { MatIconModule } from '@angular/material/icon';
import { Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-work-from-home-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatDivider,
    MatAutocompleteModule,
    AsyncPipe,
    MatIconModule,
    TranslateModule,
  ],
  templateUrl: './work-from-home-dialog.component.html',
  styleUrl: './work-from-home-dialog.component.scss',
})
export class WorkFromHomeDialogComponent implements OnInit {
  //inject services
  formBuilder = inject(FormBuilder);
  employeesService = inject(EmployeesService);
  workFromHomeService = inject(WorkFromHomeService);
  dialogRef = inject<MatDialogRef<WorkFromHomeDialogComponent>>(
    MatDialogRef<WorkFromHomeDialogComponent>
  );
  snackBar = inject(MatSnackBar);
  data = inject(MAT_DIALOG_DATA);
  activatedRoute = inject(ActivatedRoute);
  translateService = inject(TranslateService);

  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  isAdminUser: boolean = false;
  employees: Employee[] = [];
  model: WorkFromHome;
  filteredEmployees: Observable<Employee[]>;
  workFromHomeForm = this.formBuilder.group({
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    description: [''],
    employee: new FormControl<string | Employee>(''),
  });

  ngOnInit(): void {
    this.model = this.data.model;
    this.isAdminUser = this.data.isAdminUser;
    this.employees = this.data.employees;

    if (this.isAdminUser) {
      this.workFromHomeForm.controls.employee.setValidators(
        Validators.required
      );
    }

    if (this.model.id !== 0) {
      this.workFromHomeForm.patchValue({
        startDate: this.model.startDate,
        endDate: this.model.endDate,
        description: this.model.description,
        employee: this.isAdminUser
          ? this.employees.find((e) => e.id === this.model.employeeId)
          : new Employee(),
      });
    }
  }

  filter(): void {
    const filterValue = this.input.nativeElement.value.toLowerCase();
    this.filteredEmployees = filterValue
      ? of(
          this.employees.filter((option) =>
            option.fullName.toLowerCase().includes(filterValue)
          )
        )
      : of(this.employees.slice());
  }

  displayFn(employee: Employee): string {
    return employee && employee.id ? employee.fullName : '';
  }

  WorkFromHomeDateFilter = (d: Date | null): boolean => {
    const month = moment(d).month();
    const day = moment(d).day();
    if (this.isAdminUser) {
      return day !== 0 && day !== 6;
    }
    return day !== 0 && day !== 6 && month == moment().month();
  };

  onSubmit() {
    this.mapFormValuesToModel();
    if (this.model.id === 0) {
      this.createSickLeave();
    } else {
      this.updateSickLeave();
    }
  }

  updateSickLeave(): void {
    this.workFromHomeService.update(this.model).subscribe({
      next: () => {
        this.snackBar.open(
          this.translateService.instant('work_from_home.updated'),
          '',
          {
            panelClass: ['snackbar-success'],
          }
        );
        this.dialogRef.close(true);
      },
      error: (err) => {
        const translatedMessage = this.translateService.instant(
          'error_messages.' + err.error.message
        );
        this.snackBar.open(
          this.translateService.instant('general.error') + translatedMessage,
          '',
          {
            panelClass: ['snackbar-error'],
          }
        );
      },
    });
  }

  createSickLeave(): void {
    this.workFromHomeService.create(this.model).subscribe({
      next: () => {
        this.snackBar.open(
          this.translateService.instant('work_from_home.created'),
          '',
          {
            panelClass: ['snackbar-success'],
          }
        );
        this.dialogRef.close(true);
      },
      error: (err) => {
        const translatedMessage = this.translateService.instant(
          'error_messages.' + err.error.message
        );
        this.snackBar.open(
          this.translateService.instant('general.error') + translatedMessage,
          '',
          {
            panelClass: ['snackbar-error'],
          }
        );
      },
    });
  }

  mapFormValuesToModel() {
    this.model.startDate = formatDate(
      this.workFromHomeForm.value.startDate!,
      'yyyy-MM-dd',
      'en-GB'
    );
    this.model.endDate = formatDate(
      this.workFromHomeForm.value.endDate!,
      'yyyy-MM-dd',
      'en-GB'
    );
    this.model.description = this.workFromHomeForm.value.description ?? '';
    this.model.employeeId = (
      this.workFromHomeForm.controls.employee.value as Employee
    ).id;
  }

  onClose() {
    this.dialogRef.close();
  }
}
