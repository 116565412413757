import { Injectable, inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Project } from './project';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {


  http = inject(HttpClient);
   url = environment.apiEofficeConfig.endpoint + '/Project';

  getAll() {
    return this.http.get(this.url + '/GetAll');
  }
  delete(id: any) {
    return this.http.delete(this.url + '/' + id);
  }

  add(model: Project) {
    return this.http.post(this.url, model);
  }

  edit(model: Project) {
    return this.http.put(this.url, model);
  }

  getProjectsForSelect(clientId: number) {
    return this.http.get(this.url + '/GetProjectsForSelect/' + clientId);
  }
}
