import {
  AfterViewInit,
  Component,
  inject,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Book } from './interfaces/book';
import { BookstoreService } from './bookstore.service';
import { BookstoreDialogComponent } from './bookstore-dialog/bookstore-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoleService } from '../utils/role.service';
import { groups } from '../../environments/environment';
import { LibraryService } from './services/library.service';
import { LibraryBookWithEmployees } from './interfaces/libraryBookWithEmployees';
import { LibraryItemDto } from './interfaces/libraryItemDto';
import { MatSelectModule } from '@angular/material/select';
import { Employee } from '../employee-menagement/interfaces/employee';
import { EmployeesService } from '../employee-menagement/services/employees.service';
import { ConfrmationDialogComponent } from '../utils/confrmation-dialog/confrmation-dialog.component';

@Component({
    selector: 'app-bookstore',
    imports: [
        MatExpansionModule,
        MatButtonModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        CommonModule,
        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        MatDialogModule,
        MatSortModule,
        MatSelectModule,
    ],
    templateUrl: './bookstore.component.html',
    styleUrl: './bookstore.component.scss'
})
export class BookstoreComponent implements OnInit {
  //Table configuration
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<LibraryBookWithEmployees>();
  displayedColumns: string[] = [
    'title',
    'authors',
    'isbn',
    'quantity',
    'inLibrary',
    'reserved',
    'options',
    'actions',
  ];
  page: number = 1;
  total: number;
  pageSize: number = 5;
  isAdminUser: boolean = false;
  previewEmployeeIds: number[];
  libraryBookWithEmployees: LibraryBookWithEmployees[];
  employees: Employee[] = [];

  //Services dependencies
  groups = groups;
  roleService = inject(RoleService);
  employeeService = inject(EmployeesService);
  bookstoreService = inject(BookstoreService);
  libraryService = inject(LibraryService);
  dialog = inject(MatDialog);
  snackBar = inject(MatSnackBar);

  ngOnInit(): void {
    this.isAdminUser = this.roleService.checkIfUserIsInGroup(
      this.groups.EofficeAdmin
    );

    if (this.isAdminUser) {
      this.displayedColumns.push('takenBy');
      this.displayedColumns.push('reservedBy');
      this.getEmployees();
    }

    this.getBooks();
  }

  getBooks(): void {
    this.libraryService.getAll().subscribe({
      next: (result: any) => {
        this.dataSource = new MatTableDataSource(result);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      error: (error: string) => {
        this.snackBar.open('Error retriving data from server!');
      },
    });
  }

  getEmployees(): void {
    this.employeeService.getAll(true).subscribe({
      next: (result: any) => {
        this.employees = result;
      },
      error: (error: string) => {
        this.snackBar.open('Error retriving data from server!');
      },
    });
  }

  cancelReservation(model: Book) {
    this.libraryService.cancelReservation(model.id).subscribe({
      next: () => {
        this.getBooks();
      },
      error: (error: string) => {
        this.snackBar.open('Error canceling reservation!');
      },
    });
  }

  showCaneclReservation(model: LibraryBookWithEmployees): boolean {
    if (model.isMyReservation) {
      return true;
    }
    return false;
  }

  reserveBook(model: Book) {
    this.libraryService.reserveBookForCurrentUser(model.id).subscribe({
      next: () => {
        this.getBooks();
      },
      error: (error: string) => {
        this.snackBar.open('Error reserving book!');
      },
    });
  }

  showReservation(model: LibraryBookWithEmployees): boolean {
    const quantityInLibrary = this.getNumberInLibrary(model);
    const numberOfreservedBooks = this.getNumberOFreservations(model);
    if (model.isMyReservation) {
      return false;
    }

    if (
      quantityInLibrary > 0 &&
      (numberOfreservedBooks < quantityInLibrary ||
        numberOfreservedBooks !== quantityInLibrary)
    ) {
      return true;
    } else {
      return false;
    }
  }

  getNumberOFreservations(model: LibraryBookWithEmployees): number {
    const result =
      model.employeesWhoReservedIds !== null
        ? model.employeesWhoReservedIds.length
        : 0;
    return result;
  }

  getNumberInLibrary(model: LibraryBookWithEmployees): number {
    let quantityInLibrary = model.book.count;
    if (model.employeesIds !== null) {
      quantityInLibrary = model.book.count - model.employeesIds.length;
    }
    return quantityInLibrary;
  }

  getNumberOfTakenForBook(bookId: number) {
    return this.libraryBookWithEmployees.find((c) => c.book.id === bookId)
      ?.employeesIds;
  }
  getNumberOfReservationsForBook(bookId: number) {
    return this.libraryBookWithEmployees.find((c) => c.book.id === bookId)
      ?.employeesWhoReservedIds;
  }

  canThisUserTakeABook(data: any, book: Book): void {
    if (this.previewEmployeeIds < data.employeeIds) {
      this.libraryService
        .canThisUserTakeABook(
          book.id,
          this.getLastChekedEmployee(data.employeeIds)
        )
        .subscribe((response) => {
          if (response === true) {
            this.libraryItemUpdate(data);
          } else {
            if (book.count === data.employeeIds.length - 1) {
              this.snackBar.open('No free book in library!');
            } else {
              this.snackBar.open('This book is reserved by another users.');
            }
          }
        });
    } else {
      this.libraryItemUpdate(data);
    }
  }

  getLastChekedEmployee(data: number[]): number {
    let lastEmployeeId = data[0];
    if (this.previewEmployeeIds !== null) {
      lastEmployeeId = data.filter(
        (item) => this.previewEmployeeIds.indexOf(item) < 0
      )[0];
    }
    return lastEmployeeId;
  }

  libraryItemUpdate(item: LibraryItemDto): void {
    this.libraryService.libraryItemUpdate(item).subscribe({
      next: () => {
        this.previewEmployeeIds = item.employeeIds;
        this.getBooks();
      },
      error: (error: string) => {
        this.snackBar.open('Error updating library item!');
      },
    });
  }

  deleteBookForever(arg0: any) {
    const dialogRef = this.dialog.open(ConfrmationDialogComponent);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        throw new Error('Method not implemented.');
      }
    });
  }

  editBook(book: Book) {
    this.bookstoreService.update(book).subscribe(
      (result: any) => {
        this.bookstoreService.getAllBooks();
        this.snackBar.open('Book reserved');
      },
      (error: string) => {
        this.snackBar.open(error);
      }
    );
  }

  updateReservationsData(data: any, item: LibraryBookWithEmployees) {
    const record: LibraryItemDto = {
      bookId: item.book.id,
      employeeIds: null!,
      employeesWhoReservedIds: data,
    };
    let index = -1;
    if (item.employeesIds !== null) {
      index = item.employeesIds.indexOf(data[data.length - 1]);
    }
    if (index > -1) {
      this.snackBar.open('This user has already taken a book!');
    } else {
      this.updateReservations(record);
    }
  }

  updateReservations(item: LibraryItemDto): void {
    this.libraryService.updateReservations(item).subscribe({
      next: () => {
        this.getBooks();
      },
      error: (error: string) => {
        this.snackBar.open('Error updating reservations!');
      },
    });
  }

  updateLibrary(data: any, book: Book) {
    this.previewEmployeeIds = this.getNumberOfTakenForBook(book.id)!;
    const record: LibraryItemDto = {
      bookId: book.id,
      employeeIds: data,
      employeesWhoReservedIds: null!,
    };
    if (
      this.previewEmployeeIds !== null &&
      this.previewEmployeeIds.length > record.employeeIds.length
    ) {
      this.removeUserFromTakenList(record);
    } else {
      this.canThisUserTakeABook(record, book);
    }
  }

  removeUserFromTakenList(data: LibraryItemDto) {
    this.previewEmployeeIds = data.employeeIds;
    this.libraryItemUpdate(data);
  }

  addNewBook() {
    this.dialog
      .open(BookstoreDialogComponent, {
        data: { model: { id: 0, name: '', authors: '', isbn: '', count: 0 } },
        width: '400px',
        maxHeight: '500px',
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.bookstoreService.getAllBooks();
        }
      });
  }

  applyFilter($event: Event) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
