import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SickLeave } from '../interfaces/sickLeave';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SickLeavesService } from '../sick-leaves.service';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { SickLeavesDialogComponent } from '../sick-leaves-dialog/sick-leaves-dialog.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { RoleService } from '../../utils/role.service';
import { groups } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { approveModel } from '../../shared/interfaces/approveModel';
import { ConfrmationDialogComponent } from '../../utils/confrmation-dialog/confrmation-dialog.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Employee } from '../../employee-menagement/interfaces/employee';
import { EmployeesService } from '../../employee-menagement/services/employees.service';
import { ExportComponent } from '../../shared/export/export.component';

@Component({
    selector: 'app-sick-leaves',
    imports: [
        MatExpansionModule,
        MatButtonModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        CommonModule,
        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        MatDialogModule,
        MatSortModule,
        TranslateModule,
        FormsModule,
        MatCheckboxModule,
    ],
    templateUrl: './sick-leaves.component.html',
    styleUrl: './sick-leaves.component.scss'
})
export class SickLeavesComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'employeeFullName',
    'note',
    'startDate',
    'endDate',
    'numberOfDays',
    'type',
    'typeOfContract',
    'approved',
    'actions',
    'approve',
  ];
  dataSource = new MatTableDataSource<SickLeave>();
  page: number = 1;
  total: number;
  pageSize: number = 5;
  isAdminUser: boolean;
  authGroups = groups;
  activeonly = true;
  employees: Employee[] = [];

  roleService = inject(RoleService);
  sickLeaveService = inject(SickLeavesService);
  employeesService = inject(EmployeesService);
  dialog = inject(MatDialog);
  snack = inject(MatSnackBar);
  translateService = inject(TranslateService);

  ngOnInit(): void {
    this.isAdminUser = this.roleService.checkIfUserIsInGroup(
      this.authGroups.EofficeAdmin
    );

    if (!this.isAdminUser) {
      this.displayedColumns.pop();
    }

    this.getSickLeaveForEmployee(this.isAdminUser);
    this.getListOfEmployees();
  }

  getListOfEmployees() {
    this.employeesService.getEmployeesForSelect().subscribe((data: any) => {
      this.employees = data;
    });
  }

  OnActiveOnlyChange() {
    this.getSickLeaveForEmployee(this.isAdminUser);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter($event: Event) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editSickLeave(model: SickLeave) {
    this.dialog
      .open(SickLeavesDialogComponent, {
        data: { model: model, isAdminUser: this.isAdminUser },
        width: '500px',
        maxHeight: '500px',
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getSickLeaveForEmployee(this.isAdminUser);
        }
      });
  }

  addSickLeave() {
    this.dialog
      .open(SickLeavesDialogComponent, {
        data: {
          model: {
            id: 0,
            startDate: '',
            endDate: '',
            title: '',
            employeeId: '',
          },
          isAdminUser: this.isAdminUser,
        },
        width: '500px',
        maxHeight: '500px',
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getSickLeaveForEmployee(this.isAdminUser);
        }
      });
  }

  deleteSickLeave(id: number) {
    const dialogRef = this.dialog.open(ConfrmationDialogComponent);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.sickLeaveService.delete(id).subscribe(() => {
          this.getSickLeaveForEmployee(this.isAdminUser);
        });
      }
    });
  }

  changeStatusOfSickLeaveRequest(element: SickLeave, approve: boolean) {
    let sickLeaveApproveModel: approveModel = {
      entityId: element.id,
      employeeId: element.employeeId,
      approved: approve,
    };

    this.sickLeaveService
      .changeStatusOfSickLeaveRequest(sickLeaveApproveModel)
      .subscribe({
        next: () => {
          this.getSickLeaveForEmployee(this.isAdminUser);
          const translatedMessage = this.translateService.instant(
            approve
              ? 'sick-leave.sickLeaveApproved'
              : 'sick-leave.sickLeaveRejected'
          );
          this.snack.open(translatedMessage, '', {
            panelClass: 'snackbar-success',
          });
        },
        error: (err) => {
          this.snack.open(
            this.translateService.instant('general.error') + err.error.message,
            '',
            {
              panelClass: 'snackbar-error',
            }
          );
        },
      });
  }

  private getSickLeaveForEmployee(isAdminUser: boolean) {
    if (isAdminUser) {
      this.sickLeaveService
        .getAllAdmin(this.activeonly)
        .subscribe((data: SickLeave[]) => {
          this.dataSource = new MatTableDataSource<SickLeave>(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.total = data.length;
        });
    } else {
      this.sickLeaveService.getAllForEmployee().subscribe((data: any) => {
        this.dataSource = new MatTableDataSource<SickLeave>(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.total = data.length;
      });
    }
  }
  exportSickLeaves() {
    this.dialog.open(ExportComponent, {
      data: {
        employees: this.employees,
        typeOfExport: 'SickLeave',
      },
      width: '500px',
      maxHeight: '500px',
    });
  }
}
