<div class="container">
  <div>
    <div class="container-header">
      <button
        mat-raised-button
        color="primary"
        class="table-button"
        (click)="addResourceAllocation()"
      >
        {{ "resource_allocation.add" | translate }}
      </button>
    </div>
    <mat-card>
      <mat-card-header class="mat-card-header">
        <mat-card-title>
          <h4>{{ "resource_allocation.title" | translate }}</h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="table-container">
          <table
            mat-table
            [dataSource]="this.dataSource"
            class="mat-elevation-z8"
            matSort
          >
            <ng-container matColumnDef="employeeFullName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "resource_allocation.name" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.employeeFullName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="clientName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "resource_allocation.client" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.clientName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="currentProjectNames">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "resource_allocation.current_project" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.currentProjectNames }}
              </td>
            </ng-container>

            <ng-container matColumnDef="startDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "resource_allocation.start_date" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.startDate | date : "dd.MM.yyyy" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="endDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "resource_allocation.end_date" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{
                  element.endDate != null
                    ? (element.endDate | date : "dd.MM.yyyy")
                    : "Unknown"
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="futureProjectName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "resource_allocation.future_project" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.futureProjectName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="betabitEntity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "resource_allocation.betabit_entity" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.betabitEntity }}
              </td>
            </ng-container>

            <ng-container matColumnDef="regionalManager">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "resource_allocation.regional_manager" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.regionalManager }}
              </td>
            </ng-container>

            <!-- Action column -->
            <ng-container matColumnDef="actions" stickyEnd>
              <th mat-header-cell *matHeaderCellDef aria-label="row actions">
                &nbsp;
              </th>
              <td mat-cell *matCellDef="let element">
                <button
                  mat-icon-button
                  (click)="editEntity(element.id)"
                  color="primary"
                  aria-label="edit row"
                >
                  <mat-icon>draw</mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="deleteEntity(element.id)"
                  color="primary"
                  aria-label="delete row"
                >
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">
                {{ "general.no_data" | translate }}
              </td>
            </tr>
          </table>
        </div>
        <mat-paginator
          [pageSizeOptions]="[10, 25]"
          [showFirstLastButtons]="true"
          aria-label="Select page of resource allocations table"
        ></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
