<div class="container">
  <div>
    <mat-card>
      <mat-card-header class="mat-card-header">
        <mat-card-title>
          <h4>{{ (this.id ? "dinner.edit" : "dinner.add") | translate }}</h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div>
          <form [formGroup]="dinnerForm">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ "dinner.title" | translate }}</mat-label>
                <input
                  matInput
                  formControlName="title"
                  placeholder="{{ 'dinner.title' | translate }}"
                  required
                />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>{{ "dinner.place" | translate }}</mat-label>
                <input
                  matInput
                  formControlName="placeName"
                  placeholder="{{ 'dinner.place' | translate }}"
                  required
                />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>{{ "dinner.place_address" | translate }}</mat-label>
                <input
                  matInput
                  formControlName="placeAddress"
                  placeholder="{{ 'dinner.place_address' | translate }}"
                  required
                />
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ "dinner.start_date" | translate }}</mat-label>
                <input
                  matInput
                  formControlName="startDate"
                  placeholder="{{ 'dinner.start_date' | translate }}"
                  [matDatepicker]="picker"
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ "dinner.end_date" | translate }}</mat-label>
                <input
                  matInput
                  formControlName="endDate"
                  placeholder="{{ 'dinner.end_date' | translate }}"
                  [matDatepicker]="picker2"
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{
                  "dinner.dead_line_check_in" | translate
                }}</mat-label>
                <input
                  matInput
                  formControlName="deadlineForCheckIn"
                  placeholder="{{ 'dinner.dead_line_check_in' | translate }}"
                  [matDatepicker]="picker3"
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker3"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
              </mat-form-field>

              <mat-form-field>
                <input
                  matInput
                  required
                  formControlName="partOfEmailText"
                  placeholder="{{ 'dinner.part_of_email_text' | translate }}"
                />
              </mat-form-field>

              <button
                mat-button
                [disabled]="this.dinnerForm.invalid"
                (click)="showDinnerEmailPreview(dinnerForm.value)"
              >
                {{ "dinner.email_preview" | translate }}
              </button>

              <div class="extension-panel">
                <mat-expansion-panel hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ "dinner.select_guests" | translate }}
                    </mat-panel-title>
                    <mat-panel-description>
                      {{ "dinner.select_guests_description" | translate }}
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <mat-selection-list formControlName="guests">
                    @for (employee of employees; track employee) {
                    <mat-list-option [value]="employee.id">{{
                      employee.fullName
                    }}</mat-list-option>
                    }
                  </mat-selection-list>
                </mat-expansion-panel>
              </div>
            </div>
          </form>
        </div>
        <div class="action-buttons">
          <button button mat-button (click)="navigateToDinnersList()">
            {{ "buttons.cancel" | translate }}
          </button>
          <button
            button
            mat-button
            [disabled]="dinnerForm.invalid"
            (click)="id ? updateData() : saveData()"
          >
            {{ "buttons.save" | translate }}
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
