import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Employee } from '../../employee-menagement/interfaces/employee';

@Injectable({
  providedIn: 'root',
})
export class EmployeesService {
  http = inject(HttpClient);
  url = environment.apiEofficeConfig.endpoint + '/Employees';

  getAll(activeOnly: boolean): Observable<any> {
    return this.http.get(this.url + '/GetAll?activeOnly=' + activeOnly);
  }

  delete(id: number) {
    return this.http.delete(this.url + '/' + id);
  }

  calculateFreeDays(employee: any) {
    return this.http.post(this.url + '/CalculateFreeDays/', employee);
  }
  addEmployee(model: Employee) {
    return this.http.post(this.url + '/', model);
  }
  getEmployee(id: string): Observable<Employee> {
    return this.http.get<Employee>(this.url + '/' + id);
  }
  updateEmployee(model: Employee) {
    return this.http.put(this.url + '/', model);
  }

  getEmployeesForSelect() {
    return this.http.get(this.url + '/GetEmployeesForSelect');
  }

  exportToExcel(activeOnly: boolean): Observable<Blob | null> {
    return this.http.get(this.url + '/ExportToExcel?activeOnly=' + activeOnly, {
      responseType: 'blob',
    });
  }
}
