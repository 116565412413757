import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatDivider } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Invoice } from '../interfaces/monthlyWorkingHoursItems';
import { TotalMonthlyWorkingHours } from '../interfaces/totalMonthlyWorkingHours';
import moment from 'moment';
import { InvoiceFinalService } from '../services/invoice-final.service';
import { CommonModule } from '@angular/common';
import { FinalInvoiceTotals } from '../interfaces/finalInvoiceTotals';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-final-table',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatDivider,
    MatIconModule,
    MatSelectModule,
    MatTableModule,
    MatCardModule,
    MatPaginatorModule,
    CommonModule,
    RouterModule,
    TranslateModule,
  ],
  templateUrl: './final-table.component.html',
  styleUrl: './final-table.component.scss',
})
export class FinalTableComponent implements OnInit {
  formBuilder = inject(FormBuilder);
  snackBar = inject(MatSnackBar);
  invoiceFinalService = inject(InvoiceFinalService);
  translateService = inject(TranslateService);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'nr',
    'project',
    'description',
    'seniority',
    'unit',
    'unitPrice',
    'hours',
    'qty',
    'price',
    'taxProc',
    'taxEur',
    'total',
    'vacationDays',
    'sicknessDays',
  ];
  dataSource = new MatTableDataSource<Invoice>();
  invoiceFinalData: Invoice;
  finalInvoiceTotals: FinalInvoiceTotals;

  tmwhForm = this.formBuilder.group<Partial<TotalMonthlyWorkingHours>>({
    yearMonth: moment(),
    clientId: 1,
  });

  ngOnInit() {
    this.getInvoicePredictionData(
      this.tmwhForm.value as TotalMonthlyWorkingHours
    );
  }

  setMonthAndYear(
    normalizedMonthAndYear: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const ctrlValue =
      moment(this.tmwhForm.controls.yearMonth?.value) ?? moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.tmwhForm.controls.yearMonth!.setValue(ctrlValue);
    datepicker.close();
  }

  sumInvoiceTotals(): void {
    this.finalInvoiceTotals = this.invoiceFinalData.finalItems.reduce(
      function (previousValue: any, currentValue: any) {
        return {
          totalHours: previousValue.totalHours + currentValue.hours,
          totalQty: previousValue.totalQty + currentValue.qty,
          totalPrice: previousValue.totalPrice + currentValue.price,
          totalTax: 0,
          totalTaxPercentage: 0,
          totalEur: previousValue.totalEur + currentValue.totalEur,
        };
      },
      {
        totalHours: 0,
        totalQty: 0,
        totalPrice: 0,
        totalTax: 0,
        totalTaxPercentage: 0,
        totalEur: 0,
      }
    );
  }

  getInvoicePredictionData(
    invoiceFinalForm: Partial<TotalMonthlyWorkingHours>
  ) {
    const searchParams = {
      clientId: invoiceFinalForm.clientId,
      month: invoiceFinalForm.yearMonth!.month() + 1,
      year: invoiceFinalForm.yearMonth!.year(),
    };

    this.invoiceFinalService
      .getInvoiceFinalData(searchParams)
      .subscribe((invoiceFinalDataFromServer: any) => {
        this.dataSource.data = invoiceFinalDataFromServer.finalItems;
        this.invoiceFinalData = invoiceFinalDataFromServer;
        console.log();

        if (
          invoiceFinalDataFromServer &&
          invoiceFinalDataFromServer.finalItems.length > 0
        ) {
          this.sumInvoiceTotals();
        }
      });
  }

  onSubmit() {
    this.getInvoicePredictionData(
      this.tmwhForm.value as TotalMonthlyWorkingHours
    );
  }
}
