import { Component, inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DinnersService } from '../dinners.service';
import { EmployeesService } from '../../employee-menagement/services/employees.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Employee } from '../../employee-menagement/interfaces/employee';
import { Dinner } from '../interfaces/dinner';
import { DinnersEmailPreviewComponent } from '../dinners-email-preview/dinners-email-preview.component';

@Component({
    selector: 'app-dinners-dialog',
    imports: [
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatFormFieldModule,
        FormsModule,
        TranslateModule,
        MatListModule,
        MatExpansionModule,
        MatCardModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatButtonModule,
    ],
    templateUrl: './dinners-dialog.component.html',
    styleUrl: './dinners-dialog.component.scss'
})
export class DinnersDialogComponent implements OnInit {
  //inject services
  dinnersService = inject(DinnersService);
  employeesService = inject(EmployeesService);
  snack = inject(MatSnackBar);
  translateService = inject(TranslateService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  dialog = inject(MatDialog);

  //define form
  dinnerForm = new FormGroup({
    title: new FormControl('', Validators.required),
    placeName: new FormControl('', Validators.required),
    placeAddress: new FormControl('', Validators.required),
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
    guests: new FormControl(),
    partOfEmailText: new FormControl('', Validators.required),
    deadlineForCheckIn: new FormControl('', Validators.required),
  });

  //define variables
  employees: Employee[] = [];
  id: number;

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.employeesService.getEmployeesForSelect().subscribe((data: any) => {
      this.employees = data;
    });

    if (this.id) {
      this.dinnersService.get(this.id).subscribe({
        next: (data: Partial<Dinner>) => {
          this.dinnerForm.patchValue(data);
        },
        error: () => {
          this.snack.open(this.translateService.instant('general.error'), '', {
            panelClass: 'snackbar-error',
          });
        },
      });
    }
  }

  navigateToDinnersList() {
    this.router.navigate(['/dinners']);
  }

  saveData(): void {
    let DinnerModel = {} as Dinner;
    if (this.dinnerForm.valid) {
      DinnerModel = this.dinnerForm.value as Dinner;
      DinnerModel.numberOfParticipants = DinnerModel?.employeesOnEvent?.length;
    }

    this.dinnersService.create(DinnerModel).subscribe({
      next: () => {
        this.snack.open(this.translateService.instant('dinner.created'), '', {
          panelClass: 'snackbar-success',
        });
        this.navigateToDinnersList();
      },
      error: () => {
        this.snack.open(
          this.translateService.instant('error_messages.INVALID_DATA'),
          '',
          { panelClass: ['snackbar-error'] }
        );
      },
    });
  }

  updateData(): void {
    let dinnerModel = {} as Dinner;
    if (this.dinnerForm.valid) {
      dinnerModel = this.dinnerForm.value as Dinner;
      dinnerModel.numberOfParticipants = dinnerModel?.employeesOnEvent?.length;
      dinnerModel.id = this.id;
    }

    this.dinnersService.update(dinnerModel).subscribe({
      next: () => {
        this.snack.open(this.translateService.instant('dinner.updated'), '', {
          panelClass: 'snackbar-success',
        });
        this.navigateToDinnersList();
      },
      error: () => {
        this.snack.open(
          this.translateService.instant('error_messages.INVALID_DATA'),
          '',
          { panelClass: 'snackbar-error' }
        );
      },
    });
  }

  showDinnerEmailPreview(dinner: any) {
    this.dialog.open(DinnersEmailPreviewComponent, {
      data: { dinner: dinner, id: this.id },
      maxWidth: '600px',
      maxHeight: '600px',
      disableClose: false,
    });
  }
}
