import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ResourceAllocationService } from './services/resource-allocation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { resourceAllocationListModel } from './interfaces/resourceAllocationListModel';
import { Router } from '@angular/router';
import { ConfrmationDialogComponent } from '../utils/confrmation-dialog/confrmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-resource-allocation',
    imports: [
        MatExpansionModule,
        MatButtonModule,
        MatCardModule,
        CommonModule,
        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        MatSortModule,
        TranslateModule,
    ],
    templateUrl: './resource-allocation.component.html',
    styleUrl: './resource-allocation.component.scss'
})
export class ResourceAllocationComponent implements OnInit {
  //inject services
  resourceAllocationService = inject(ResourceAllocationService);
  translateService = inject(TranslateService);
  sncakBar = inject(MatSnackBar);
  router = inject(Router);
  dialog = inject(MatDialog);

  //variables
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'employeeFullName',
    'clientName',
    'currentProjectNames',
    'startDate',
    'endDate',
    'futureProjectName',
    'betabitEntity',
    'regionalManager',
    'actions',
  ];
  dataSource = new MatTableDataSource<resourceAllocationListModel>();
  page: number = 1;
  total: number;
  pageSize: number = 5;

  ngOnInit(): void {
    this.getAllResourceAllocations();
  }

  addResourceAllocation() {
    this.router.navigate(['/resource-allocation-form', '']);
  }

  deleteEntity(id: number) {
    const dialogRef = this.dialog.open(ConfrmationDialogComponent);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.resourceAllocationService.delete(id).subscribe({
          next: () => {
            this.sncakBar.open(
              this.translateService.instant('resource_allocation.deleted')
            );
            this.getAllResourceAllocations();
          },
          error: () => {
            this.sncakBar.open(this.translateService.instant('general.error'));
          },
        });
      }
    });
  }

  editEntity(id: number) {
    this.router.navigate(['/resource-allocation-form', id]);
  }

  getAllResourceAllocations() {
    this.resourceAllocationService.getAll().subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
