import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Project } from '../interfaces/project';
import { ProjectService } from '../interfaces/project.service';
import { ProjectDialogComponent } from '../project-dialog/project-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfrmationDialogComponent } from '../../utils/confrmation-dialog/confrmation-dialog.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-project',
  standalone: true,
  imports: [
    MatExpansionModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    TranslateModule,
  ],
  templateUrl: './project.component.html',
  styleUrl: './project.component.scss',
})
export class ProjectComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['name', 'clientName', 'active', 'actions'];
  dataSource = new MatTableDataSource<Project>();
  page: number = 1;
  total: number;
  pageSize: number = 5;

  projectService = inject(ProjectService);
  dialog = inject(MatDialog);
  snackBar = inject(MatSnackBar);
  translateService = inject(TranslateService);

  ngOnInit() {
    this.getProjectList();
  }

  editEntity(model: any) {
    this.dialog
      .open(ProjectDialogComponent, {
        data: { model: model },
        width: '500px',
        maxHeight: '500px',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.getProjectList();
        }
      });
  }

  deleteEntity(model: any) {
    const dialogRef = this.dialog.open(ConfrmationDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.projectService.delete(model.id).subscribe((res) => {
          this.getProjectList();
        });
      }
    });
  }

  getProjectList() {
    this.projectService.getAll().subscribe((data: any) => {
      this.dataSource = new MatTableDataSource<Project>(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.total = data.length;
    });
  }

  addProject() {
    this.dialog
      .open(ProjectDialogComponent, {
        data: { model: { id: 0, name: '', clientId: 0, active: false } },
        width: '500px',
        maxHeight: '500px',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.snackBar.open('Project is added successfully');
          this.getProjectList();
        }
      });
  }

  applyFilter($event: Event) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
