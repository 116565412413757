import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Dinner } from '../interfaces/dinner';
import { groups } from '../../../environments/environment';
import { RoleService } from '../../utils/role.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DinnersService } from '../dinners.service';
import { ConfrmationDialogComponent } from '../../utils/confrmation-dialog/confrmation-dialog.component';
import { approveModel } from '../../shared/interfaces/approveModel';
import moment from 'moment';

@Component({
    selector: 'app-dinners-list',
    imports: [
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        CommonModule,
        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        MatDialogModule,
        MatSortModule,
        TranslateModule,
        FormsModule,
    ],
    templateUrl: './dinners-list.component.html',
    styleUrl: './dinners-list.component.scss'
})
export class DinnersListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'title',
    'place',
    'startDate',
    'endDate',
    'accept',
    'reject',
  ];
  dataSource = new MatTableDataSource<Dinner>();
  page: number = 1;
  total: number;
  pageSize: number = 5;
  isAdminUser: boolean;
  authGroups = groups;
  activeonly = true;

  roleService = inject(RoleService);
  dinnersService = inject(DinnersService);
  dialog = inject(MatDialog);
  snack = inject(MatSnackBar);
  translateService = inject(TranslateService);
  router = inject(Router);

  ngOnInit() {
    this.isAdminUser = this.roleService.checkIfUserIsInGroup(
      this.authGroups.EofficeAdmin
    );

    // Get data for table
    this.getDinners(this.isAdminUser);

    // Add action column if user is admin
    if (this.isAdminUser) {
      this.displayedColumns.push('action');
    }
  }

  applyFilter($event: Event): void {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  changeStatusOfDinnerRequest(element: Dinner, appoved: boolean): void {
    let dinnerApproveModel: approveModel = {
      entityId: element.id,
      employeeId: 0,
      approved: appoved,
    };

    this.dinnersService.changeState(dinnerApproveModel).subscribe(() => {
      this.getDinners(this.isAdminUser);
    });
  }

  isEventActive(element: Dinner): boolean {
    const currentDateTime = moment(new Date());
    if (element.deadlineForCheckIn) {
      const deadLine = moment(element.deadlineForCheckIn);
      return (
        currentDateTime <= deadLine && element.currentUserIsInvited === true
      );
    }
    if (element.startDate) {
      const starDate = moment(element.startDate);
      return (
        currentDateTime <= starDate && element.currentUserIsInvited === true
      );
    }
    return false;
  }

  getDinners(isAdminUser: boolean): void {
    if (isAdminUser) {
      this.dinnersService.getAllAdmin().subscribe({
        next: (data) => {
          this.dataSource.data = data;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.total = data.length;
        },
        error: () => {
          this.snack.open(
            this.translateService.instant('general.loading_data_error'),
            '',
            { panelClass: 'snackbar-error' }
          );
        },
      });
    } else {
      this.dinnersService.getAllForEmployee().subscribe({
        next: (data) => {
          this.dataSource.data = data;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.total = data.length;
        },
        error: () => {
          this.snack.open(
            this.translateService.instant('general.loading_data_error'),
            '',
            { panelClass: 'snackbar-error' }
          );
        },
      });
    }
  }

  deleteDinner(id: number): void {
    const dialogRef = this.dialog.open(ConfrmationDialogComponent);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.dinnersService.delete(id).subscribe(() => {
          this.getDinners(this.isAdminUser);
        });
      }
    });
  }

  editDinner(id: number): void {
    this.router.navigate(['/dinners-form', id]);
  }
  addDinner(): void {
    this.router.navigate(['/dinners-form', '']);
  }
}
