import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { EmployeeTableDto } from '../interfaces/employeeTableDto';
import { EmployeesService } from '../../employee-menagement/services/employees.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ConfrmationDialogComponent } from '../../utils/confrmation-dialog/confrmation-dialog.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-employee-deatils',
  standalone: true,
  imports: [
    MatExpansionModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    ReactiveFormsModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    MatCheckboxModule,
    FormsModule,
    TranslateModule,
  ],
  templateUrl: './employee-details.component.html',
  styleUrl: './employee-details.component.scss',
})
export class EmployeeDetailsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'email',
    'typeOfContract',
    'active',
    'actions',
  ];
  dataSource = new MatTableDataSource<EmployeeTableDto>();
  page: number = 1;
  total: number;
  pageSize: number = 5;
  activeOnly: boolean = true;
  isLoading: boolean = false;

  employeeService = inject(EmployeesService);
  dialog = inject(MatDialog);
  router = inject(Router);
  translateService = inject(TranslateService);
  snackBar = inject(MatSnackBar);

  ngOnInit(): void {
    this.getEmployeeList(this.activeOnly);
  }

  OnActiveOnlyChange(): void {
    this.getEmployeeList(this.activeOnly);
  }

  addEmployee() {
    this.router.navigate(['/employee-menagement-dialog', '']);
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  deleteEmployee(model: any) {
    const dialogRef = this.dialog.open(ConfrmationDialogComponent);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.employeeService.delete(model.id).subscribe((res) => {
          this.snackBar.open(
            this.translateService.instant('employee.deleted'),
            '',
            {
              panelClass: ['snackbar-success'],
            }
          );
          this.getEmployeeList(this.activeOnly);
        });
      }
    });
  }

  editEmployee(model: any) {
    this.router.navigate(['/employee-menagement-dialog', model.id]);
  }

  getEmployeeList(activeOnly: boolean) {
    this.employeeService.getAll(activeOnly).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource<EmployeeTableDto>(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.total = data.length;
    });
  }

  exportEmployees() {
    this.isLoading = true;
    this.employeeService.exportToExcel(this.activeOnly).subscribe({
      next: (data) => {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(data!);
        link.href = url;
        link.download = 'Employees_' + new Date().toISOString() + '.xlsx';
        link.click();
        window.URL.revokeObjectURL(url);
        this.isLoading = false;
      },
      error: (error) => {
        this.snackBar.open(this.translateService.instant('Error' + error), '', {
          panelClass: ['snackbar-error'],
        });
        this.isLoading = false;
      },
    });
  }
}
