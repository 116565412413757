import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DaysAtTheOfficeService {
  http = inject(HttpClient);
  url = environment.apiEofficeConfig.endpoint + '/DaysAtTheOffice/';

  getAll(month: number, year: number) {
    return this.http.get(this.url + 'GetAll?month=' + month + '&year=' + year);
  }

  export(month: number, year: number): Observable<Blob> {
    return this.http.get(
      this.url + 'ExportToExcel?month=' + month + '&year=' + year,
      { responseType: 'blob' }
    );
  }
}
