import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { FailedComponent } from './failed/failed.component';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { GroupGuard } from './group.guard';
import { NonWorkingDaysComponent } from './nonWorkingDays/non-working-days/non-working-days.component';
import { SickLeavesComponent } from './sickLeave/sick-leaves/sick-leaves.component';
import { WorkFromHomeComponent } from './workFromHome/work-from-home/work-from-home.component';
import { BookstoreComponent } from './bookstore/bookstore.component';
import { VacationsComponent } from './vacations/vacation-list/vacations.component';
import { SeniorityComponent } from './seniority/seniority/seniority.component';
import { ProjectComponent } from './projects/project/project.component';
import { EmployeeDetailsComponent } from './employee-menagement/employee-details/employee-details.component';
import { EmployeeMenagementDialogComponent } from './employee-menagement/employee-menagement-dialog/employee-menagement-dialog.component';
import { groups } from '../environments/environment';
import { DaysAtTheOfficeComponent } from './days-at-the-office/days-at-the-office.component';
import { ResourceAllocationComponent } from './resource-allocation/resource-allocation.component';
import { ResourceAllocationFormComponent } from './resource-allocation/resource-allocation-form/resource-allocation-form.component';
import { BusinessTripComponent } from './business-trips/business-trip/business-trip.component';
import { TotalMonthlyWorkingHoursComponent } from './invoices/total-monthly-working-hours/total-monthly-working-hours.component';
import { PredictionTableComponent } from './invoices/prediction-table/prediction-table.component';
import { HomeContentComponent } from './home-content/home-content.component';
import { ParkingComponent } from './parking/parking.component';
import { SpecialOccasionDayListComponent } from './special-occasion-day/special-occasion-day-list/special-occasion-day-list.component';
import { TeambuildingListComponent } from './teambuilding/teambuilding-list/teambuilding-list.component';
import { TeambuildingDialogComponent } from './teambuilding/teambuilding-dialog/teambuilding-dialog.component';
import { DinnersListComponent } from './dinners/dinners-list/dinners-list.component';
import { DinnersDialogComponent } from './dinners/dinners-dialog/dinners-dialog.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        component: HomeContentComponent,
        // canActivate: [GroupGuard],
        // data: {
        //   requiredGroups: [groups.EofficeEmployee],
        // },
      },
      {
        path: 'non-working-days',
        component: NonWorkingDaysComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeEmployee],
        },
      },
      {
        path: 'sick-leaves',
        component: SickLeavesComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeEmployee],
        },
      },
      {
        path: 'work-from-home',
        component: WorkFromHomeComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeEmployee],
        },
      },
      {
        path: 'bookstore',
        component: BookstoreComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeEmployee],
        },
      },
      {
        path: 'vacations',
        component: VacationsComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeEmployee],
        },
      },
      {
        path: 'seniority',
        component: SeniorityComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeAdmin],
        },
      },
      {
        path: 'projects',
        component: ProjectComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeAdmin],
        },
      },
      {
        path: 'employees',
        component: EmployeeDetailsComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeAdmin],
        },
      },
      {
        path: 'employee-menagement-dialog/:id',
        component: EmployeeMenagementDialogComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeAdmin],
        },
      },
      {
        path: 'days-at-the-office',
        component: DaysAtTheOfficeComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeAdmin],
        },
      },
      {
        path: 'resource-allocation',
        component: ResourceAllocationComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeAdmin],
        },
      },
      {
        path: 'resource-allocation-form/:id',
        component: ResourceAllocationFormComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeAdmin],
        },
      },
      {
        path: 'business-tips',
        component: BusinessTripComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeEmployee],
        },
      },
      {
        path: 'total-monthly-working-hours',
        component: TotalMonthlyWorkingHoursComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeAdmin],
        },
      },
      {
        path: 'invoice-list',
        component: PredictionTableComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeAdmin],
        },
      },
      {
        path: 'parking',
        component: ParkingComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeEmployee],
        },
      },
      {
        path: 'special-occasion-days',
        component: SpecialOccasionDayListComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeAdmin],
        },
      },
      {
        path: 'teambuilding',
        component: TeambuildingListComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeEmployee],
        },
      },
      {
        path: 'teambuilding-form',
        component: TeambuildingDialogComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeAdmin],
        },
      },
      {
        path: 'teambuilding-form/:id',
        component: TeambuildingDialogComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeAdmin],
        },
      },
      {
        path: 'dinners',
        component: DinnersListComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeEmployee],
        },
      },
      {
        path: 'dinners-form',
        component: DinnersDialogComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeAdmin],
        },
      },
      {
        path: 'dinners-form/:id',
        component: DinnersDialogComponent,
        canActivate: [GroupGuard],
        data: {
          requiredGroups: [groups.EofficeAdmin],
        },
      },
    ],
  },
  {
    path: 'login-failed',
    component: FailedComponent,
  },
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent,
  },
];
