<div class="modal-header">
  <h2 mat-dialog-title>
    {{ "vacation.export" | translate }}
  </h2>
  <mat-icon
    aria-hidden="false"
    aria-label="pending icon"
    fontIcon="houseboat"
  ></mat-icon>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <form [formGroup]="vacationExportForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <mat-form-field>
        <mat-label>{{ "vacation.enter_date_range" | translate }}</mat-label>
        <mat-date-range-input
          [dateFilter]="vacationDateFilter"
          [rangePicker]="picker"
        >
          <input
            matStartDate
            placeholder="{{ 'vacation.start_date_placeholder' | translate }}"
            formControlName="startDate"
          />
          <input
            matEndDate
            placeholder="{{ 'vacation.end_date_placeholder' | translate }}"
            formControlName="endDate"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "vacation.employee_name" | translate }}</mat-label>
        <input
          type="text"
          aria-label="employee"
          #inputName
          matInput
          [formControl]="this.vacationExportForm.controls.employee"
          [matAutocomplete]="auto"
          (input)="filter()"
          (focus)="filter()"
        />

        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          requireSelection
        >
          @for (option of filteredEmployees | async; track option) {
          <mat-option [value]="option">{{ option.fullName }}</mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      @if (this.vacationExportForm.controls.employee.invalid &&
      this.vacationExportForm.controls.employee.touched ||
      this.vacationExportForm.controls.employee.dirty) {
      @if(this.vacationExportForm.controls.employee.errors?.['required']) {
      <mat-error>{{ "vacation.employee_name_requered" | translate }}</mat-error>
      } }
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1" (click)="onClose()">
    {{ "buttons.cancel" | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!this.vacationExportForm.valid"
    type="submit"
    (click)="onSubmit()"
  >
    {{ "general.export" | translate }}
  </button>
</mat-dialog-actions>
