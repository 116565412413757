<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar
      ><img
        src="../../assets/nultien_logo.png"
        style="height: 220px; width: 220px"
    /></mat-toolbar>
    <mat-nav-list>
      <a mat-list-item href="/">
        <mat-icon mat-list-icon>home</mat-icon>
        <span matLine>{{ "menu.home" | translate }}</span>
      </a>
      <a mat-list-item [routerLink]="['/non-working-days']">
        <mat-icon mat-list-icon>calendar_month</mat-icon>
        <span>{{ "menu.non_working_days" | translate }}</span>
      </a>
      <a mat-list-item href="/vacations" class="sidebar-link">
        <mat-icon mat-list-icon>card_travel</mat-icon>
        <span>{{ "menu.vacations" | translate }}</span>
      </a>
      <a mat-list-item href="/sick-leaves">
        <mat-icon mat-list-icon>local_hospital</mat-icon>
        <span>{{ "menu.sick_leaves" | translate }}</span>
      </a>
      <a mat-list-item href="/work-from-home">
        <mat-icon mat-list-icon>home</mat-icon>
        <span>{{ "menu.work_from_home" | translate }}</span>
      </a>
      <a mat-list-item href="/bookstore">
        <mat-icon mat-list-icon>local_library</mat-icon>
        <span>{{ "menu.bookstore" | translate }}</span>
      </a>
      <a mat-list-item href="/business-tips">
        <mat-icon mat-list-icon>connecting_airports</mat-icon>
        <span>{{ "menu.business_trips" | translate }}</span>
      </a>
      <a mat-list-item href="/teambuilding">
        <mat-icon mat-list-icon>groups</mat-icon>
        <span>{{ "menu.teambuilding" | translate }}</span>
      </a>
      <a mat-list-item href="/dinners">
        <mat-icon mat-list-icon>restaurant</mat-icon>
        <span>{{ "menu.dinners" | translate }}</span>
      </a>
      <!-- <a mat-list-item href="/parking">
        <mat-icon mat-list-icon>directions_car</mat-icon>
        <span>Parking spots</span>
      </a> -->
      @if(this.isAdminUser){
      <hr class="hr-text" data-content="Admin" />
      <a mat-list-item href="/seniority">
        <mat-icon mat-list-icon>work</mat-icon>
        <span>{{ "menu.seniority" | translate }}</span>
      </a>
      <a mat-list-item href="/projects">
        <mat-icon mat-list-icon>building</mat-icon>
        <span>{{ "menu.projects" | translate }}</span>
      </a>
      <a mat-list-item href="/employees">
        <mat-icon mat-list-icon>people</mat-icon>
        <span>{{ "menu.employees" | translate }}</span>
      </a>
      <a mat-list-item href="/days-at-the-office">
        <mat-icon mat-list-icon>meeting_room</mat-icon>
        <span>{{ "menu.days_at_the_office" | translate }}</span>
      </a>
      <a mat-list-item href="/resource-allocation">
        <mat-icon mat-list-icon>sync_alt</mat-icon>
        <span>{{ "menu.resource_allocation" | translate }}</span>
      </a>
      <a mat-list-item href="/invoice-list">
        <mat-icon mat-list-icon>monitoring</mat-icon>
        <span>{{ "menu.invoices" | translate }}</span>
      </a>
      <a mat-list-item href="/total-monthly-working-hours">
        <mat-icon mat-list-icon>schedule</mat-icon>
        <span>{{ "menu.total_monthly_working_hours" | translate }}</span>
      </a>
      <a mat-list-item href="/special-occasion-days">
        <mat-icon mat-list-icon>event</mat-icon>
        <span>{{ "menu.special_occasion_days" | translate }}</span>
      </a>
      }
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar style="background-color: #b70d31">
      @if (isHandset$ | async) {
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      }
      <span></span>
      <span class="spacer"></span>
      <mat-icon
        style="
          color: white;
          margin-right: 15px;
          margin-bottom: 10px;
          font-weight: 200;
        "
        [matMenuTriggerFor]="languageMenu"
        >language</mat-icon
      >
      <mat-icon
        style="
          color: white;
          margin-right: 15px;
          margin-bottom: 10px;
          font-weight: 200;
        "
        (click)="onThemeChange()"
        >dark_mode</mat-icon
      >

      <div class="logOutWrapper" [matMenuTriggerFor]="profileMenu">
        <div class="profile-details">
          <span>{{ this.currentUserProfile.displayName }}</span>
          <span
            style="font-style: oblique; font-weight: 300; font-size: 0.7em"
            >{{ this.currentUserProfile.jobTitle }}</span
          >
        </div>
        <img
          [src]="this.profileImage"
          alt="profileImage"
          height="40"
          width="40"
          style="border-radius: 40%"
        />
      </div>

      <mat-menu #languageMenu="matMenu">
        <button mat-menu-item i18n (click)="onLanguageChange('en')">
          <span class="fi fi-gb"></span> EN
        </button>
        <hr />
        <button mat-menu-item i18n (click)="onLanguageChange('rs')">
          <span class="fi fi-rs"></span> SR-Latn
        </button>
        <hr />
        <button mat-menu-item i18n (click)="onLanguageChange('rs-cyrl')">
          <span class="fi fi-rs"></span> SR-Cyrl
        </button>
      </mat-menu>

      <mat-menu #profileMenu="matMenu">
        <button mat-menu-item i18n (click)="logOut()">
          <mat-icon>logout</mat-icon>{{ "menu.logout" | translate }}
        </button>
      </mat-menu>
    </mat-toolbar>
    <app-loading-bar [detectRouteTransitions]="true"></app-loading-bar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
