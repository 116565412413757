<div class="container">
  <div class="container-header">
    <div class="input-fileds">
      <mat-form-field appearance="outline">
        <mat-label>{{ "days_at_the_office.month" | translate }}</mat-label>
        <input
          matInput
          type="number"
          min="1"
          max="12"
          [(ngModel)]="this.month"
          required
        />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ "days_at_the_office.year" | translate }}</mat-label>
        <input
          matInput
          type="number"
          min="2000"
          max="2100"
          [(ngModel)]="this.year"
          required
        />
      </mat-form-field>
    </div>
    <span class="spacer"></span>
    <button
      mat-raised-button
      color="primary"
      style="margin-right: 5px"
      (click)="export()"
    >
      <mat-icon>download</mat-icon>
      {{ "general.export" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="getDaysAtTheOffice(this.month, this.year)"
    >
      {{ "days_at_the_office.get_days" | translate }}
    </button>
  </div>
  <div>
    <mat-card>
      <mat-card-header class="mat-card-header">
        <mat-card-title>
          <h4>{{ "days_at_the_office.title" | translate }}</h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="table-container">
          <table
            mat-table
            [dataSource]="this.dataSource"
            class="mat-elevation-z8"
            matSort
          >
            <ng-container matColumnDef="firstName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "days_at_the_office.first_name" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.firstName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="lastName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "days_at_the_office.last_name" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
            </ng-container>
            <ng-container matColumnDef="typeOfContract">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "days_at_the_office.contract_type" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.typeOfContract }}
              </td>
            </ng-container>
            <ng-container matColumnDef="dayMonthAtOffice">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "days_at_the_office.days_at_office" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.daysAtTheOffice }} / {{ element.workingDaysInMonth }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">
                {{ "general.no_data" | translate }}
              </td>
            </tr>
          </table>
        </div>
        <mat-paginator
          [pageSizeOptions]="[10, 25]"
          [showFirstLastButtons]="true"
          aria-label="Select page of days at the office table"
        ></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
