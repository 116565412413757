import { CommonModule, formatDate } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeesService } from '../../employee-menagement/services/employees.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SeniorityService } from '../../seniority/seniority.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MonthlyExpensesService } from '../monthly-expenses.service';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { Employee } from '../interfaces/employee';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-employee-menagement-dialog',
    imports: [
        MatExpansionModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        CommonModule,
        MatIconModule,
        MatDatepickerModule,
        MatDividerModule,
        MatSelectModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatTooltipModule,
        FormsModule,
        MatCheckboxModule,
        TranslateModule,
    ],
    templateUrl: './employee-menagement-dialog.component.html',
    styleUrl: './employee-menagement-dialog.component.scss'
})
export class EmployeeMenagementDialogComponent implements OnInit {
  //inject services
  formBuilder = inject(FormBuilder);
  route = inject(ActivatedRoute);
  router = inject(Router);
  employeeService = inject(EmployeesService);
  seniorityService = inject(SeniorityService);
  snackBar = inject(MatSnackBar);
  monthlyExpensesService = inject(MonthlyExpensesService);
  translateService = inject(TranslateService);

  //declare form and variables
  employeeForm = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    dateOfBirth: ['', [Validators.required]],
    jmbg: ['', [Validators.required]],
    email: ['', [Validators.required]],
    phoneNumber: ['', [Validators.required]],
    belgradeAddress: ['', [Validators.required]],
    address: ['', [Validators.required]],
    position: ['', [Validators.required]],
    dateOfEmployment: ['', [Validators.required]],
    yearsOfService: ['', [Validators.required]],
    seniorityId: ['', [Validators.required]],
    totalFreeDays: ['', [Validators.required]],
    totalFreeDaysLastYear: ['', [Validators.required]],
    levelOfEducation: ['', [Validators.required]],
    degree: ['', [Validators.required]],
    school: ['', [Validators.required]],
    lbo: ['', [Validators.required]],
    emergencyContacts: ['', [Validators.required]],
    contactPhone: ['', [Validators.required]],
    insuredFamilyMembers: [''],
    kids: ['', [Validators.required]],
    numberOfKids: ['', [Validators.required]],
    publicTransportationZone: ['', [Validators.required]],
    ticketPrice: ['', [Validators.required]],
    typeOfContract: ['', [Validators.required]],
    dateOfPassingToAgency: [
      formatDate(new Date(Date.now()), 'yyyy-MM-dd', 'en'),
    ],
    dateOfContractTermination: [],
    insurance: ['', [Validators.required]],
    planeTickets: ['', [Validators.required]],
    travellingAllowance: ['', [Validators.required]],
    other: ['', [Validators.required]],
    lunch: ['', [Validators.required]],
    image: [''],
    active: [false, [Validators.required]],
  });

  id: string;
  seniorities: any;
  model: any;
  monthlyExpenses = {
    insurance: 0,
    lunch: 0,
    other: 0,
    planeTickets: 0,
    travellingAllowance: 0,
  };
  selectedMonth: string;
  selectedYear: string;

  ngOnInit(): void {
    this.getSenoirities();
    this.id = this.route.snapshot.params['id'];
    this.selectedMonth = (new Date().getMonth() + 1).toString();
    this.selectedYear = new Date().getFullYear().toString();
    this.model = new Employee();

    if (this.id !== '') {
      this.employeeService.getEmployee(this.id).subscribe((res) => {
        this.model = res;
        this.employeeForm.patchValue(this.model);
        this.model.monthlyExpenses =
          res.monthlyExpenses ?? this.monthlyExpenses;
      });
    }
  }

  onClose() {
    this.router.navigate(['/employees']);
  }
  onSubmit() {
    this.getMonthlyExpensesFromForm(this.employeeForm);
    this.mapFormValuesToModel();
    if (this.id !== '') {
      this.employeeService.updateEmployee(this.model).subscribe({
        next: () => {
          this.snackBar.open(
            this.translateService.instant('employee.updated'),
            '',
            { panelClass: 'snackbar-success' }
          );
          this.router.navigate(['/employees']);
        },
        error: (e) => {
          this.snackBar.open(
            this.translateService.instant('error_messages.INVALID_DATA'),
            '',
            { panelClass: 'snackbar-error' }
          );
        },
      });
    } else {
      this.employeeService.addEmployee(this.model).subscribe({
        next: () => {
          this.snackBar.open(
            this.translateService.instant('employee.created'),
            '',
            { panelClass: 'snackbar-success' }
          );
          this.router.navigate(['/employees']);
        },
        error: (e) => {
          this.snackBar.open(
            this.translateService.instant('error_messages.INVALID_DATA'),
            '',
            { panelClass: 'snackbar-error' }
          );
        },
      });
    }
  }

  getMonthlyExpensesFromForm(form: any) {
    this.model.monthlyExpenses = this.monthlyExpenses;
    this.model.monthlyExpenses.insurance = form.insurance;
    this.model.monthlyExpenses.lunch = form.lunch;
    this.model.monthlyExpenses.other = form.other;
    this.model.monthlyExpenses.planeTickets = form.planeTickets;
    this.model.monthlyExpenses.travellingAllowance = form.travellingAllowance;
  }

  getMonthlyExpenses(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    this.monthlyExpensesService
      .getMonthlyExpensesForEmployee(
        normalizedMonthAndYear.month() + '',
        normalizedMonthAndYear.year() + '',
        this.id
      )
      .subscribe((monthlyExpenses) => {
        this.model.monthlyExpenses = monthlyExpenses;
      });
  }

  mapFormValuesToModel() {
    this.model.firstName = this.employeeForm.value.firstName ?? '';
    this.model.lastName = this.employeeForm.value.lastName ?? '';
    this.model.dateOfBirth = this.employeeForm.value.dateOfBirth ?? '';
    this.model.jmbg = this.employeeForm.value.jmbg ?? '';
    this.model.email = this.employeeForm.value.email ?? '';
    this.model.phoneNumber = this.employeeForm.value.phoneNumber ?? '';
    this.model.belgradeAddress = this.employeeForm.value.belgradeAddress ?? '';
    this.model.address = this.employeeForm.value.address ?? '';
    this.model.position = this.employeeForm.value.position ?? '';
    this.model.dateOfEmployment =
      this.employeeForm.value.dateOfEmployment ?? '';
    this.model.yearsOfService = this.employeeForm.value.yearsOfService ?? '';
    this.model.seniorityId = this.employeeForm.value.seniorityId ?? '';
    this.model.totalFreeDays = this.employeeForm.value.totalFreeDays ?? '';
    this.model.totalFreeDaysLastYear =
      this.employeeForm.value.totalFreeDaysLastYear ?? '';
    this.model.levelOfEducation =
      this.employeeForm.value.levelOfEducation ?? '';
    this.model.degree = this.employeeForm.value.degree ?? '';
    this.model.school = this.employeeForm.value.school ?? '';
    this.model.lbo = this.employeeForm.value.lbo ?? '';
    this.model.emergencyContacts =
      this.employeeForm.value.emergencyContacts ?? '';
    this.model.contactPhone = this.employeeForm.value.contactPhone ?? '';
    this.model.insuredFamilyMembers =
      this.employeeForm.value.insuredFamilyMembers ?? '';
    this.model.kids = this.employeeForm.value.kids ?? '';
    this.model.numberOfKids = this.employeeForm.value.numberOfKids ?? '';
    this.model.publicTransportationZone =
      this.employeeForm.value.publicTransportationZone ?? '';
    this.model.ticketPrice = this.employeeForm.value.ticketPrice ?? '';
    this.model.typeOfContract = this.employeeForm.value.typeOfContract ?? '';
    this.model.dateOfPassingToAgency =
      this.employeeForm.value.dateOfPassingToAgency ?? new Date(Date.now());
    this.model.dateOfContractTermination =
      this.employeeForm.value.dateOfContractTermination;
    this.model.insurance = this.employeeForm.value.insurance ?? '';
    this.model.planeTickets = this.employeeForm.value.planeTickets ?? '';
    this.model.travellingAllowance =
      this.employeeForm.value.travellingAllowance ?? '';
    this.model.other = this.employeeForm.value.other ?? '';
    this.model.lunch = this.employeeForm.value.lunch ?? '';
    this.model.image = this.employeeForm.value.image ?? '';
    this.model.active = this.employeeForm.value.active ?? false;
  }

  getFreeDays() {
    const employeeData = {
      dateOfEmployment: this.employeeForm.controls.dateOfEmployment.value,
      yearsOfService: this.employeeForm.controls.yearsOfService.value,
    };

    this.employeeService.calculateFreeDays(employeeData).subscribe({
      next: (v) => {
        this.employeeForm.controls.totalFreeDays.patchValue(v + '');
      },
      error: (e) => {
        this.snackBar.open(
          this.translateService.instant('error_messages.INVALID_DATA'),
          '',
          { panelClass: 'snackbar-error' }
        );
      },
    });
  }

  getSenoirities() {
    this.seniorityService.getAll().subscribe((res) => {
      this.seniorities = res;
    });
  }
}
