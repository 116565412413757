import { BrowserCacheLocation, Configuration, LogLevel } from "@azure/msal-browser";
import { environment } from "../environments/environment";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const msalConfig: Configuration = {
    auth: {
        clientId: environment.msalConfig.auth.clientId, // This is the ONLY mandatory field that you need to supply.
        authority: environment.msalConfig.auth.authority, // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: '/', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: '/', // Points to window.location.origin by default.
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
    },
    system: {
        /**
         * Below you can configure MSAL.js logs. For more information, visit:
         * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
         */
        allowNativeBroker: false,
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                console.log(message);
            },
            logLevel: LogLevel.Error,
            piiLoggingEnabled: false
        }
    }
}


export const protectedResources = {
    eOfficeApi: {
        endpoint: environment.apiEofficeConfig.endpoint ,
        scopes: environment.apiEofficeConfig.scopes
    },
    apiGraph: {
        endpoint: environment.apiConfig.endpoint,
        scopes: environment.apiConfig.scopes
    }
}

/**
 * IMPORTANT: In case of overage, group list is cached for 1 hr by default, and thus cached groups 
 * will miss any changes to a users group membership for this duration. For capturing real-time 
 * changes to a user's group membership, consider implementing Microsoft Graph change notifications. 
 * For more information, visit: https://learn.microsoft.com/graph/api/resources/webhooks
 */
export const CACHE_TTL_IN_MS = 60 * 60 * 1000; // 1 hour in milliseconds

export const loginRequest = {
    scopes: [protectedResources.eOfficeApi.scopes, protectedResources.apiGraph.scopes]
};

// export const groups = {
//     EofficeAdmin: "797ae674-d21e-4144-9b4b-458081b36c7d",
//     EofficeEmployee: "b72f5c4f-9729-42df-8ef1-d081c8c2935c",
//     EofficeHR: "daec1a1c-35f4-44c5-8bf9-240c38b2c6d5"
// }
