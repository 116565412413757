<div class="modal-header">
  <h2 mat-dialog-title>
    @if(this.model.id == 0){
    {{'special-occasion.add' | translate }}
  } @else
  {
    {{'special-occasion.edit' | translate}}
  }
 </h2>
  <mat-icon aria-hidden="false" aria-label="pending icon" fontIcon="folder_special"></mat-icon>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <form [formGroup]="specialOccasionDaysForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>{{'special-occasion.name' | translate }}</mat-label>
        <input matInput formControlName="description" placeholder="{{'special-occasion.name-placeholder' | translate}}">
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1" (click)="onClose()">{{ 'buttons.cancel' | translate}}</button>
  <button mat-raised-button color="primary" type="submit" [disabled]="!this.specialOccasionDaysForm.valid"
    (click)="onSubmit()">{{'buttons.save' | translate }}</button>
</mat-dialog-actions>