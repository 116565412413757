<div class="container">
  <div class="container-header">
    @if(this.isAdminUser){
    <button mat-raised-button color="primary" (click)="addBusinessTrip()">
      {{ "business_trip.add" | translate }}
    </button>
    }
    <span class="spacer"></span>
    <mat-form-field>
      <mat-label
        ><mat-icon>search</mat-icon
        >{{ "general.search" | translate }}</mat-label
      >
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="{{ 'general.search_placeholder' | translate }}"
        #input
      />
    </mat-form-field>
  </div>
  <div>
    <mat-card>
      <mat-card-header class="mat-card-header">
        <mat-card-title>
          <h4>{{ "business_trip.title" | translate }}</h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="table-container">
          <table
            mat-table
            [dataSource]="this.dataSource"
            class="mat-elevation-z8"
            matSort
          >
            <ng-container matColumnDef="employeeFullName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "business_trip.employee" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.employeeFullName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="startDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "business_trip.start_date" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.startDate | date }}
              </td>
            </ng-container>

            <ng-container matColumnDef="endDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "business_trip.end_date" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.endDate | date }}
              </td>
            </ng-container>

            <ng-container matColumnDef="travellingAllowance">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "business_trip.travellingAllowance" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <span>{{ element.travellingAllowance }}</span>
                <mat-icon matSuffix>euro_symbol</mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="clientName">
              <th mat-header-cell *matHeaderCellDef>
                {{ "business_trip.client" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.clientName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="projectName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "business_trip.project" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.projectName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="files">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "business_trip.files" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <i
                  [routerLink]="[
                    '/files/employee',
                    element?.employeeId,
                    'list',
                    'business'
                  ]"
                  class="material-icons pointer"
                  >folder_open</i
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="actions" *ngIf="this.isAdminUser">
              <th mat-header-cell *matHeaderCellDef>
                {{ "business_trip.actions" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <button
                  mat-button
                  color="primary"
                  (click)="editBusinessTrip(element)"
                  [disabled]="element.approved != null"
                >
                  <mat-icon>draw</mat-icon>
                </button>
                <button
                  mat-button
                  color="warn"
                  (click)="deleteBusinessTrip(element.id)"
                  [disabled]="element.approved != null"
                >
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">
                {{ "business_trip.no_data" | translate }} "{{ input.value }}"
              </td>
            </tr>
          </table>
        </div>
        <mat-paginator
          [pageSizeOptions]="[10, 25]"
          showFirstLastButtons="true"
          aria-label="Select page of business trips"
        ></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
