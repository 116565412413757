import { Injectable, inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountWithGroupClaims } from '../group.guard';
import { checkGroupsInStorage, getGroupsFromStorage } from './storage-utils';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private authService = inject(MsalService);
  constructor() { }

  
    checkIfUserIsInGroup(group: string) {
    let activeAccount = this.authService.instance.getActiveAccount() as AccountWithGroupClaims;

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
        activeAccount = this.authService.instance.getAllAccounts()[0] as AccountWithGroupClaims;
    }

    // check either the ID token or a non-expired storage entry for the groups membership claim
    if (!activeAccount?.idTokenClaims?.groups && !checkGroupsInStorage(activeAccount)) {

        if (activeAccount.idTokenClaims?._claim_names && activeAccount.idTokenClaims?._claim_names.groups) {
            window.alert('You have too many group memberships. The application will now query Microsoft Graph to check if you are a member of any of the groups required.');
            return false;
        }
          return false;
    }

    const hasRequiredGroup = activeAccount?.idTokenClaims?.groups?.includes(group) || getGroupsFromStorage(activeAccount)?.includes(group);

    if (!hasRequiredGroup) {
        return false;
    }

    return true
  }
}
