import { Component, inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import moment from 'moment';
import { EmployeesService } from '../../employee-menagement/services/employees.service';
import { BusinessTripService } from '../services/business-trip.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Employee } from '../../employee-menagement/interfaces/employee';
import { BusinessTrip } from '../interfaces/businessTrip';
import { Project } from '../../projects/interfaces/project';
import { ProjectService } from '../../projects/interfaces/project.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-business-trip-dialog',
    imports: [
        MatFormFieldModule,
        MatDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatDivider,
        MatIconModule,
        MatSelectModule,
        TranslateModule,
    ],
    templateUrl: './business-trip-dialog.component.html',
    styleUrl: './business-trip-dialog.component.scss'
})
export class BusinessTripDialogComponent implements OnInit {
  //inject services
  formBuilder = inject(FormBuilder);
  employeesService = inject(EmployeesService);
  businessTripService = inject(BusinessTripService);
  projectService = inject(ProjectService);
  dialogRef = inject<MatDialogRef<BusinessTripDialogComponent>>(
    MatDialogRef<BusinessTripDialogComponent>
  );
  snackBar = inject(MatSnackBar);
  data = inject(MAT_DIALOG_DATA);
  activatedRoute = inject(ActivatedRoute);

  isAdminUser: boolean = false;
  employeesForSelect: Employee[] = [];
  projects: Project[] = [];
  model: BusinessTrip;

  businessTripForm = this.formBuilder.group({
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    travellingAllowance: [0],
    clientId: [0],
    projectId: [0],
    employees: new FormControl<Employee[]>([]),
  });

  ngOnInit(): void {
    this.model = this.data.model;
    this.isAdminUser = this.data.isAdminUser;
    this.employeesForSelect = this.data.employees;

    if (this.model.id !== 0) {
      this.businessTripForm.patchValue({
        startDate: this.model.startDate,
        endDate: this.model.endDate,
        travellingAllowance: this.model.travellingAllowance,
        clientId: this.model.clientId,
        projectId: this.model.projectId,
        employees:
          this.employeesForSelect.filter(
            (employee) => employee.id === this.model.employeeId
          ) ?? [],
      });
      this.clientChanged(this.model.clientId);
      this.employeesForSelect = this.employeesForSelect.filter(
        (employee) => employee.id === this.model.employeeId
      );
    }
  }

  businessTripFilter = (d: Date | null): boolean => {
    const day = moment(d).day();
    return day !== 0 && day !== 6;
  };

  onSubmit() {
    this.mapFormValuesToModel();
    if (this.model.id === 0) {
      this.createBusinessTrip();
    } else {
      this.updateBusinessTrip();
    }
  }

  clientChanged(clientId: number) {
    this.projectService
      .getProjectsForSelect(clientId)
      .subscribe((data: any) => {
        this.projects = data;
      });
  }

  updateBusinessTrip(): void {
    this.businessTripService.update(this.model).subscribe({
      next: () => {
        this.snackBar.open('Business trip is modified', '', {
          panelClass: 'snackbar-success',
        });
        this.dialogRef.close(true);
      },
      error: () => {
        this.snackBar.open('Error', '', { panelClass: 'snackbar-error' });
      },
    });
  }

  createBusinessTrip(): void {
    this.businessTripService.create(this.model).subscribe({
      next: () => {
        this.snackBar.open('Business trip reqest is made!', '', {
          panelClass: 'snackbar-success',
        });
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.snackBar.open('Error' + err.message, '', {
          panelClass: 'snackbar-error',
        });
      },
    });
  }

  mapFormValuesToModel() {
    this.model.startDate = this.businessTripForm.value.startDate;
    this.model.endDate = this.businessTripForm.value.endDate;
    this.model.travellingAllowance =
      this.businessTripForm.value.travellingAllowance ?? -1;
    this.model.clientId = this.businessTripForm.value.clientId ?? -1;
    this.model.projectId = this.businessTripForm.value.projectId ?? -1;
    this.model.employeesOnEvent = (
      this.businessTripForm.controls.employees.value as Employee[]
    ).map((e) => e.id);
  }

  onClose() {
    this.dialogRef.close();
  }
}
