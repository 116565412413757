import {
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AsyncPipe, formatDate } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { Employee } from '../../employee-menagement/interfaces/employee';
import { Observable, of } from 'rxjs';
import moment from 'moment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { ExportService } from '../services/export.service';

@Component({
  selector: 'app-export',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatDivider,
    MatAutocompleteModule,
    AsyncPipe,
    MatIconModule,
    MatCheckboxModule,
    TranslateModule,
    MatSelectModule,
  ],
  templateUrl: './export.component.html',
  styleUrl: './export.component.scss',
})
export class ExportComponent {
  //Inject services
  exportService = inject(ExportService);
  dialogRef = inject(MatDialogRef<ExportComponent>);
  formBuilder = inject(FormBuilder);
  snackBar = inject(MatSnackBar);
  data = inject(MAT_DIALOG_DATA);
  translateService = inject(TranslateService);

  //variables
  @ViewChild('inputName') input: ElementRef<HTMLInputElement>;
  employees: Employee[] = [];
  filteredEmployees: Observable<Employee[]>;
  vacationExportForm = this.formBuilder.group({
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    employee: new FormControl<string | Employee>(''),
  });
  typeOfExport: string;

  filter(): void {
    const filterValue = this.input.nativeElement.value.toLowerCase();
    this.filteredEmployees = filterValue
      ? of(
          this.employees.filter((option) =>
            option.fullName.toLowerCase().includes(filterValue)
          )
        )
      : of(this.employees.slice());
  }

  displayFn(employee: Employee): string {
    return employee && employee.id ? employee.fullName : '';
  }

  ngOnInit(): void {
    this.employees = this.data.employees;
    this.typeOfExport = this.data.typeOfExport;
    console.log(this.typeOfExport);
  }

  vacationDateFilter = (d: Date | null): boolean => {
    const day = moment(d).day();
    return day !== 0 && day !== 6;
  };

  onSubmit() {
    const startDate = formatDate(
      this.vacationExportForm.controls.startDate.value!,
      'yyyy-MM-dd',
      'en-GB'
    );
    const endDate = formatDate(
      this.vacationExportForm.controls.endDate.value!,
      'yyyy-MM-dd',
      'en-GB'
    );
    let employeeId = -1;
    if (this.vacationExportForm.controls.employee.value) {
      employeeId = (this.vacationExportForm.controls.employee.value as Employee)
        .id;
    }
    this.exportService
      .export(startDate, endDate, employeeId, this.typeOfExport)
      .subscribe({
        next: (data) => {
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(data!);
          link.href = url;
          link.download =
            this.typeOfExport + '_' + new Date().toISOString() + '.xlsx';
          link.click();
          window.URL.revokeObjectURL(url);
        },
        error: (error) => {
          this.snackBar.open(
            this.translateService.instant('Error' + error),
            '',
            { panelClass: ['snackbar-error'] }
          );
        },
      });
  }

  onClose() {
    this.dialogRef.close(false);
  }
}
