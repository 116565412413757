<div class="modal-header">
  <h2 mat-dialog-title>
    {{ (this.model.id == 0 ? 'business_trip.add' : 'business_trip.edit') | translate }} 
  </h2>
  <mat-icon aria-hidden="false" aria-label="pending icon" fontIcon="airplane_ticket"></mat-icon>
</div>

<mat-divider></mat-divider>

<mat-dialog-content>
  <form [formGroup]="businessTripForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
          <mat-form-field>
              <mat-label>{{ 'business_trip.enter_date_range' | translate }}</mat-label>
              <mat-date-range-input [dateFilter]="businessTripFilter" [rangePicker]="picker">
                  <input matStartDate placeholder="{{ 'business_trip.start_date' | translate }}" formControlName="startDate">
                  <input matEndDate placeholder="{{ 'business_trip.end_date' | translate }}" formControlName="endDate">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>

          <mat-form-field>
            <input matInput trimOnBlur formControlName="travellingAllowance" placeholder="{{ 'business_trip.travelling_allowance' | translate }}" type="number">
            <mat-icon matSuffix>euro_symbol</mat-icon>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'business_trip.select_client' | translate }}</mat-label>
            <mat-select formControlName="clientId" placeholder="{{ 'business_trip.choose_client' | translate }}" (selectionChange)="clientChanged($event.value)">
              <mat-option [value]="1">BetaBit</mat-option>
              <mat-option [value]="2">Enigmatry</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-select placeholder="{{ 'business_trip.choose_project' | translate }}" formControlName="projectId">
              @for(currentProject of projects; track currentProject)
              {
                <mat-option [value]="currentProject.id">
                  {{ currentProject.name }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'business_trip.employees' | translate }}</mat-label>
            <mat-select formControlName="employees" multiple>
              @for (employee of employeesForSelect; track employee) {
                <mat-option [value]="employee">{{ employee.fullName }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
      </div>
  </form>
</mat-dialog-content>

<mat-divider></mat-divider>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1" (click)="onClose()">{{ 'buttons.cancel' | translate }}</button>
  <button mat-raised-button color="primary" type="submit" [disabled]="!this.businessTripForm.valid" (click)="onSubmit()">
    {{ 'buttons.save' | translate }}
  </button>
</mat-dialog-actions>
