import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { nonWorkingDays } from '../interfaces/nonWorkingDays';
import { MatButtonModule } from '@angular/material/button';
import { NonWorkingDaysService } from '../services/non-working-days.service';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { NonWorkingDay } from '../interfaces/nonWorkingDay';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { NonWorkingDaysDialogComponent } from '../non-working-days-dialog/non-working-days-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { PagingParamsAndSearch } from '../../utils/pagingParamsAndSearch';
import { RoleService } from '../../utils/role.service';
import { groups } from '../../../environments/environment';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ConfrmationDialogComponent } from '../../utils/confrmation-dialog/confrmation-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-non-working-days',
    imports: [
        MatTableModule,
        MatButtonModule,
        MatSelectModule,
        CommonModule,
        FormsModule,
        MatIconModule,
        MatPaginator,
        MatCardModule,
        MatSnackBarModule,
        TranslateModule,
    ],
    templateUrl: './non-working-days.component.html',
    styleUrl: './non-working-days.component.scss'
})
export class NonWorkingDaysComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  selectedYear: NonWorkingDay = {
    id: 0,
    startDate: '',
    endDate: '',
    title: '',
  };

  years: NonWorkingDay[];
  displayedColumns: string[] = ['title', 'startDate', 'endDate', 'actions'];
  authGroups = groups;

  IsAdminUser: boolean = true;
  dataSource = new MatTableDataSource<nonWorkingDays>();
  page: number = 1;
  total: number;
  pageSize: number = 10;
  searchParams = {
    year: null,
  };

  nonWorkingDaysService = inject(NonWorkingDaysService);
  dialog = inject(MatDialog);
  roleService = inject(RoleService);
  snackBar = inject(MatSnackBar);

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.IsAdminUser = this.roleService.checkIfUserIsInGroup(
      this.authGroups.EofficeAdmin
    );

    //remove actions column if user is not admin
    if (!this.IsAdminUser) {
      this.displayedColumns.pop();
    }

    this.getNonWorkingDays({
      pageNumber: this.page,
      pageSize: this.pageSize,
      searchParams: null,
    });
    this.nonWorkingDaysService.getYears().subscribe({
      next: (response) => {
        this.years = response;
        this.selectedYear = this.years.filter(
          (x) => x.endDate == new Date().getFullYear().toString()
        )[0];
      },
      error: (error) => {
        this.snackBar.open('Error fetching data ', '', {
          panelClass: 'snackbar-error',
        });
      },
    });
  }

  openDialog() {
    this.dialog.open(NonWorkingDaysDialogComponent, {
      data: { model: { id: 0, startDate: '', endDate: '', title: '' } },
      width: '500px',
      maxHeight: '500px',
    });
  }

  onYearChange($event: MatSelectChange) {
    this.searchParams.year = $event.value;
    this.getNonWorkingDays({
      pageNumber: this.page,
      pageSize: this.pageSize,
      searchParams: this.searchParams,
    });
  }

  pageChaged($event: any) {
    this.getNonWorkingDays({
      pageNumber: $event.pageIndex + 1,
      pageSize: $event.pageSize,
      searchParams: this.searchParams,
    });
  }

  deleteEntity(id: number) {
    const dialogRef = this.dialog.open(ConfrmationDialogComponent);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.nonWorkingDaysService.delete(id).subscribe((response) => {
          this.getNonWorkingDays({
            pageNumber: this.page,
            pageSize: this.pageSize,
            searchParams: this.searchParams,
          });
        });
      }
    });
  }
  editEntity(model: NonWorkingDay) {
    this.dialog.open(NonWorkingDaysDialogComponent, {
      data: { model: model },
      width: '500px',
      maxHeight: '500px',
    });
  }

  getNonWorkingDays(pagingAndSearchParams: PagingParamsAndSearch) {
    this.nonWorkingDaysService
      .getAllPagedWithSearch(pagingAndSearchParams)
      .subscribe((response) => {
        this.page = pagingAndSearchParams.pageNumber;
        this.total = response.totalCount;
        this.dataSource = response.data;
      });
  }
}
