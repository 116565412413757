<div class="container">
  <div class="container-header">
    <button mat-raised-button color="primary" (click)="addSickLeave()">
      {{ "sick-leave.request" | translate }}
    </button>
    @if (this.isAdminUser) {
    <button
      mat-raised-button
      color="primary"
      style="margin-left: 5px"
      (click)="exportSickLeaves()"
    >
      <mat-icon>download</mat-icon>
      {{ "general.export" | translate }}
    </button>
    }
    <span class="spacer"></span>
    @if(this.isAdminUser){
    <mat-checkbox
      [(ngModel)]="this.activeonly"
      (change)="this.OnActiveOnlyChange()"
      >{{ "general.active_employees_only" | translate }}</mat-checkbox
    >
    }
    <mat-form-field>
      <mat-label
        ><mat-icon>search</mat-icon
        >{{ "general.search" | translate }}</mat-label
      >
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="{{ 'general.search_placeholder' | translate }}"
        #input
      />
    </mat-form-field>
  </div>
  <div>
    <mat-card>
      <mat-card-header class="mat-card-header">
        <mat-card-title>
          <h4>{{ "sick-leave.container_header" | translate }}</h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="table-container">
          <table
            mat-table
            [dataSource]="this.dataSource"
            class="mat-elevation-z8"
            matSort
          >
            <ng-container matColumnDef="employeeFullName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "sick-leave.table-headers.employee" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.employeeFullName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="note">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "sick-leave.table-headers.note" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.note }}</td>
            </ng-container>
            <ng-container matColumnDef="startDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "sick-leave.table-headers.sick_leave_start" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.startDate | date : "dd.MM.YYYY" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="endDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "sick-leave.table-headers.sick_leave_end" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.endDate | date : "dd.MM.YYYY" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="numberOfDays">
              <th mat-header-cell *matHeaderCellDef>
                {{ "sick-leave.table-headers.days" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.numberOfDays }}
              </td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "sick-leave.table-headers.type" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.type }}</td>
            </ng-container>
            <ng-container matColumnDef="typeOfContract">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "sick-leave.table-headers.contract_type" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.typeOfContract }}
              </td>
            </ng-container>
            <ng-container matColumnDef="approve" *ngIf="this.isAdminUser">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "sick-leave.table-headers.approve" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <button
                  mat-button
                  color="primary"
                  (click)="changeStatusOfSickLeaveRequest(element, true)"
                  disabled="{{ element.approved != null && element.approved }}"
                >
                  <mat-icon>done_outline</mat-icon>
                </button>
                <button
                  mat-button
                  color="warn"
                  (click)="changeStatusOfSickLeaveRequest(element, false)"
                  disabled="{{ element.approved != null && !element.approved }}"
                >
                  <mat-icon>block</mat-icon>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="approved">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "sick-leave.table-headers.approved" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                @if(element.approved){
                <mat-icon
                  aria-hidden="false"
                  aria-label="check icon"
                  class="icon-check"
                  fontIcon="check_circle"
                ></mat-icon>
                } @else if (element.approved == null) {
                <mat-icon
                  aria-hidden="false"
                  aria-label="pending icon"
                  class="icon-pending"
                  fontIcon="pending"
                ></mat-icon>
                } @else{
                <mat-icon
                  aria-hidden="false"
                  aria-label="close icon"
                  class="icon-cancel"
                  fontIcon="cancel"
                ></mat-icon>
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>
                {{ "sick-leave.table-headers.actions" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                @if (!element.approved && this.isAdminUser) {
                <button
                  mat-button
                  color="primary"
                  (click)="editSickLeave(element)"
                >
                  <mat-icon>draw</mat-icon>
                </button>
                <button
                  mat-button
                  color="warn"
                  (click)="deleteSickLeave(element.id)"
                >
                  <mat-icon>delete_forever</mat-icon>
                </button>
                }
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">
                {{ "general.no_data" | translate }} "{{ input.value }}"
              </td>
            </tr>
          </table>
        </div>
        <mat-paginator
          [pageSizeOptions]="[10, 25]"
          showFirstLastButtons="true"
          aria-label="Select page of sick leaves"
        ></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
