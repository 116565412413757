import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { MonthlyExpenses } from './interfaces/monthlyExpenses';

@Injectable({
  providedIn: 'root'
})
export class MonthlyExpensesService {

  http = inject(HttpClient);
  url = environment.apiEofficeConfig.endpoint + '/Employees';

  getMonthlyExpenses(month: string, year: string): Observable<any> {
    return this.http.get(this.url + `?Month=${month}&Year=${year}`);
  }

  getMonthlyExpensesForEmployee(month: string, year: string, employeeId: string): Observable<any> {
    return this.http.get(this.url + '/GetForEmployee' + `?Month=${month}&Year=${year}&EmployeeId=${employeeId}`);
  }

  updateOrInsert(monthlyExpenses: MonthlyExpenses, employeeId: string): Observable<any> {
      return this.http.post(this.url + `?EmployeeId=${employeeId}`, monthlyExpenses);
  }
}
