<div class="container">
  <div class="container-header">
    @if (this.IsAdminUser) {
    <button
      mat-raised-button
      color="primary"
      class="table-button"
      (click)="openDialog()"
    >
      {{ "non_working_day.add" | translate }}
    </button>
    }
    <span class="spacer"></span>
    <mat-form-field>
      <mat-label>{{ "general.select_year" | translate }}</mat-label>
      <mat-select
        [(ngModel)]="selectedYear"
        (selectionChange)="onYearChange($event)"
      >
        @for (year of years; track $index;) {
        <mat-option [value]="year">{{ year }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <mat-card>
    <mat-card-header class="mat-card-header">
      <h4>{{ "non_working_day.container_header" | translate }}</h4>
    </mat-card-header>

    <mat-card-content>
      <div class="table-container">
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z8"
          matSor
        >
          <ng-conteainer matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ "non_working_day.table-headers.title" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.title }}</td>
          </ng-conteainer>

          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef matSort>
              {{ "non_working_day.table-headers.start_date" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.startDate | date : "mediumDate" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef matSort>
              {{ "non_working_day.table-headers.end_date" | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.endDate | date : "mediumDate" }}
            </td>
          </ng-container>

          <!-- Action column -->
          @if (IsAdminUser) {
          <ng-container matColumnDef="actions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">
              &nbsp;
            </th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-icon-button
                (click)="editEntity(element)"
                color="primary"
                aria-label="edit row"
              >
                <mat-icon>draw</mat-icon>
              </button>
              <button
                mat-icon-button
                (click)="deleteEntity(element.id)"
                color="primary"
                aria-label="delete row"
              >
                <mat-icon>delete_forever</mat-icon>
              </button>
            </td>
          </ng-container>
          }

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <mat-paginator
        [pageSizeOptions]="[10, 25]"
        [pageSize]="pageSize"
        (page)="pageChaged($event)"
        showFirstLastButtons
        [length]="total"
        aria-label="Select page of non working day"
      >
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
