import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatDivider } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Invoice } from '../interfaces/monthlyWorkingHoursItems';
import { TotalMonthlyWorkingHours } from '../interfaces/totalMonthlyWorkingHours';
import moment from 'moment';
import { InvoicePredictionServiceService } from '../services/invoice-prediction.service';
import { FinalTableComponent } from '../final-table/final-table.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-prediction-table',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatDivider,
    MatIconModule,
    MatSelectModule,
    MatTableModule,
    MatCardModule,
    MatPaginatorModule,
    FinalTableComponent,
    TranslateModule,
  ],
  templateUrl: './prediction-table.component.html',
  styleUrl: './prediction-table.component.scss',
})
export class PredictionTableComponent implements OnInit {
  formBuilder = inject(FormBuilder);
  snackBar = inject(MatSnackBar);
  invoicePredictionService = inject(InvoicePredictionServiceService);
  translateService = inject(TranslateService);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'nr',
    'project',
    'description',
    'seniority',
    'unit',
    'unitPrice',
    'hours',
    'qty',
    'price',
    'taxProc',
    'taxEur',
    'total',
    'vacationDays',
    'sicknessDays',
  ];
  dataSource = new MatTableDataSource<Invoice>();
  invoicePredictionData: Invoice;

  tmwhForm = this.formBuilder.group<Partial<TotalMonthlyWorkingHours>>({
    yearMonth: moment(),
    clientId: 1,
  });

  ngOnInit() {
    this.getInvoicePredictionData(
      this.tmwhForm.value as TotalMonthlyWorkingHours
    );
  }

  setMonthAndYear(
    normalizedMonthAndYear: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const ctrlValue =
      moment(this.tmwhForm.controls.yearMonth?.value) ?? moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.tmwhForm.controls.yearMonth!.setValue(ctrlValue);
    datepicker.close();
  }

  getInvoicePredictionData(
    invoicePredictionForm: Partial<TotalMonthlyWorkingHours>
  ) {
    const searchParams = {
      clientId: invoicePredictionForm.clientId,
      month: invoicePredictionForm.yearMonth!.month() + 1,
      year: invoicePredictionForm.yearMonth!.year(),
    };

    this.invoicePredictionService
      .getInvoicePredictionData(searchParams)
      .subscribe((invoicePredictionDataFromServer: any) => {
        this.dataSource.data = invoicePredictionDataFromServer.predictionItems;
        this.invoicePredictionData = invoicePredictionDataFromServer;
      });
  }

  onSubmit() {
    this.getInvoicePredictionData(
      this.tmwhForm.value as TotalMonthlyWorkingHours
    );
  }

  savePredictionItems() {
    this.invoicePredictionService
      .create(this.invoicePredictionData)
      .subscribe();
  }

  updatePredictionItems() {
    this.invoicePredictionService
      .update(this.invoicePredictionData)
      .subscribe();
  }
}
