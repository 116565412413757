import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { Dinner } from '../interfaces/dinner';

@Component({
    selector: 'app-dinners-email-preview',
    imports: [MatCardModule, MatDialogModule, CommonModule, TranslateModule],
    templateUrl: './dinners-email-preview.component.html',
    styleUrl: './dinners-email-preview.component.scss'
})
export class DinnersEmailPreviewComponent implements OnInit {
  id: number;
  dinner: Dinner;
  dinnersLink: string;

  data = inject(MAT_DIALOG_DATA);

  ngOnInit(): void {
    this.dinner = this.data.dinner;
    this.id = this.dinner.id;
    this.dinnersLink = window.location.origin + '/dinner/';
  }

  showMailPreview(): boolean {
    return this.id !== undefined;
  }
}
