import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { resourceAllocationModel } from '../interfaces/resourceAllocationModel';

@Injectable({
  providedIn: 'root'
})
export class ResourceAllocationService {

  http = inject(HttpClient);
  url = environment.apiEofficeConfig.endpoint + '/ResourceAllocation/';

  public getAll() {
    return this.http.get(this.url);
  }

  public get(id: number) {
    return this.http.get(this.url + id);
  }

  public add(resourceAllocation: resourceAllocationModel) {
    return this.http.post(this.url, resourceAllocation);
  }

  public update(resourceAllocation: resourceAllocationModel) {
    return this.http.put(this.url, resourceAllocation);
  }

  public delete(id: number) {
    return this.http.delete(this.url + id);
  }

}
