import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TeamBuilding } from '../interfaces/teamBuilding';
import { groups } from '../../../environments/environment';
import { RoleService } from '../../utils/role.service';
import { TeambuildingService } from '../teambuilding.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { approveModel } from '../../shared/interfaces/approveModel';
import { ConfrmationDialogComponent } from '../../utils/confrmation-dialog/confrmation-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-teambuilding-list',
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    TranslateModule,
    FormsModule,
  ],
  templateUrl: './teambuilding-list.component.html',
  styleUrl: './teambuilding-list.component.scss',
})
export class TeambuildingListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'title',
    'place',
    'startDate',
    'endDate',
    'accept',
    'reject',
  ];
  dataSource = new MatTableDataSource<TeamBuilding>();
  page: number = 1;
  total: number;
  pageSize: number = 5;
  isAdminUser: boolean;
  authGroups = groups;
  activeonly = true;

  roleService = inject(RoleService);
  teamBuildingService = inject(TeambuildingService);
  dialog = inject(MatDialog);
  snack = inject(MatSnackBar);
  translateService = inject(TranslateService);
  router = inject(Router);

  ngOnInit() {
    // Check if user is admin
    this.isAdminUser = this.roleService.checkIfUserIsInGroup(
      this.authGroups.EofficeAdmin
    );

    // Get data for table
    this.getTeamBuildings(this.isAdminUser);

    // Add action column if user is admin
    if (this.isAdminUser) {
      this.displayedColumns.push('action');
    }
  }

  applyFilter($event: Event) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  changeStatusOfTeamBuildingRequest(
    element: TeamBuilding,
    appoved: boolean
  ): void {
    let teamBuildingApproveModel: approveModel = {
      entityId: element.id,
      employeeId: 0,
      approved: appoved,
    };

    this.teamBuildingService
      .changeState(teamBuildingApproveModel)
      .subscribe(() => {
        this.getTeamBuildings(this.isAdminUser);
      });
  }

  isEventActive(element: TeamBuilding): boolean {
    const currentDateTime = moment(new Date());
    if (element.deadlineForCheckIn) {
      const deadLine = moment(element.deadlineForCheckIn);
      return (
        currentDateTime <= deadLine && element.currentUserIsInvited === true
      );
    }
    if (element.startDate) {
      const starDate = moment(element.startDate);
      return (
        currentDateTime <= starDate && element.currentUserIsInvited === true
      );
    }
    return false;
  }

  getTeamBuildings(isAdminUser: boolean): void {
    if (isAdminUser) {
      this.teamBuildingService.getAllAdmin().subscribe((data) => {
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.total = data.length;
      });
    } else {
      this.teamBuildingService.getAllForEmployee().subscribe((data) => {
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.total = data.length;
      });
    }
  }

  deleteTeamBuilding(id: number): void {
    const dialogRef = this.dialog.open(ConfrmationDialogComponent);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.teamBuildingService.delete(id).subscribe(() => {
          this.getTeamBuildings(this.isAdminUser);
        });
      }
    });
  }

  editTeamBuilding(id: number): void {
    this.router.navigate(['/teambuilding-form', id]);
  }
  addTeamBuilding(): void {
    this.router.navigate(['/teambuilding-form', '']);
  }
}
