import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SpecialOccasionDayModel } from './interfaces/specialOccasionDayModel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpecialOccasionDayService {

  http = inject(HttpClient);
  url = environment.apiEofficeConfig.endpoint + '/SpecialOccasionDay';

  delete(id: number) {
    return this.http.delete(this.url + '/' + id);
  }

  getAll() : Observable<SpecialOccasionDayModel[]> {
    return this.http.get<SpecialOccasionDayModel[]>(this.url);
  }

  update(model: SpecialOccasionDayModel) {
    return this.http.put(this.url, model);
  }

  create(model: SpecialOccasionDayModel) {
    return this.http.post(this.url, model);
  }
}
