import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LibraryItemDto } from '../interfaces/libraryItemDto';
import { BookAndEmployee } from '../interfaces/bookAndEmployee';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  url = environment.apiEofficeConfig.endpoint + '/Libraries/';
  http = inject(HttpClient);

  libraryItemUpdate(model: LibraryItemDto) {
    return this.http.post(this.url + 'LibraryItemUpdate', model);
  }

  updateReservations(model: LibraryItemDto) {
    return this.http.post(this.url + 'UpdateReservations', model);
  }

  canThisUserTakeABook(bookId: number, employeeId: number) {
    return this.http.get(this.url + 'CanThisUserTakeABook/' + bookId + '/' + employeeId);
  }

  getAll() {
    return this.http.get(this.url + 'GetAll');
  }

  delete(id: number) {
    return this.http.delete(this.url + 'Delete/' + id);
  }

  reserveBookForCurrentUser(bookId: number) {
    return this.http.post(this.url + 'ReserveBookForCurrentUser/', bookId);
  }
  
  cancelReservation(bookId: number) {
    return this.http.post(this.url + 'CancelReservation/', bookId);
  }

  returnBackBook(model: BookAndEmployee) {
    return this.http.post(this.url + 'ReturnBackBook/', model);
  }

  takeABook(model: BookAndEmployee) {
    return this.http.post(this.url + 'TakeABook/', model);
  }

  getAllExceptReturned() {
    return this.http.get(this.url + 'GetAllExceptReturned');
  }

}
