<div class="modal-header">
  <h2 mat-dialog-title>
    @if(this.model.id == 0){
    {{ "vacation.add" | translate }}
    }@else {
    {{ "vacation.edit" | translate }}
    }
  </h2>
  <mat-icon
    aria-hidden="false"
    aria-label="pending icon"
    fontIcon="houseboat"
  ></mat-icon>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <form [formGroup]="vacationForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <mat-form-field>
        <mat-label>{{ "vacation.enter_date_range" | translate }}</mat-label>
        <mat-date-range-input
          [dateFilter]="vacationDateFilter"
          [rangePicker]="picker"
        >
          <input
            matStartDate
            placeholder="{{ 'vacation.start_date_placeholder' | translate }}"
            formControlName="startDate"
          />
          <input
            matEndDate
            placeholder="{{ 'vacation.end_date_placeholder' | translate }}"
            formControlName="endDate"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      @if(this.data.isAdminUser) {
      <mat-form-field>
        <mat-label>{{ "vacation.employee_name" | translate }}</mat-label>
        <input
          type="text"
          aria-label="employee"
          #inputName
          matInput
          [formControl]="this.vacationForm.controls.employee"
          [matAutocomplete]="auto"
          (input)="filter()"
          (focus)="filter()"
        />

        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          requireSelection
        >
          @for (option of filteredEmployees | async; track option) {
          <mat-option [value]="option">{{ option.fullName }}</mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      @if (this.vacationForm.controls.employee.invalid &&
      this.vacationForm.controls.employee.touched ||
      this.vacationForm.controls.employee.dirty) {
      @if(this.vacationForm.controls.employee.errors?.['required']) {
      <mat-error>{{ "vacation.employee_name_requered" | translate }}</mat-error>
      } } }

      <div>
        <mat-checkbox formControlName="isSpecial">{{
          "vacation.is_special_occasion" | translate
        }}</mat-checkbox>
      </div>

      @if (this.vacationForm.controls.isSpecial.value) {
      <mat-form-field>
        <mat-label>{{
          "vacation.special_occasion_reason" | translate
        }}</mat-label>
        <mat-select formControlName="specialOccasionId">
          @for (specialOccasionDay of specialOccasionDays; track $index;) {
          <mat-option [value]="specialOccasionDay.id">{{
            specialOccasionDay.description
          }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      }
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1" (click)="onClose()">
    {{ "buttons.cancel" | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!this.vacationForm.valid"
    type="submit"
    (click)="onSubmit()"
  >
    {{ "buttons.save" | translate }}
  </button>
</mat-dialog-actions>
