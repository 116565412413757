import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatDivider } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TotalMonthlyWorkingHours } from '../interfaces/totalMonthlyWorkingHours';
import { Invoice } from '../interfaces/monthlyWorkingHoursItems';
import moment from 'moment';
import { TotalMonthlyWorkingHoursServiceService } from '../services/total-monthly-working-hours.service';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-total-monthly-working-hours',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatDivider,
    MatIconModule,
    MatSelectModule,
    MatTableModule,
    MatCardModule,
    MatPaginatorModule,
    RouterModule,
    TranslateModule,
  ],
  templateUrl: './total-monthly-working-hours.component.html',
  styleUrl: './total-monthly-working-hours.component.scss',
})
export class TotalMonthlyWorkingHoursComponent implements OnInit {
  formBuilder = inject(FormBuilder);
  snackBar = inject(MatSnackBar);
  totalMonthlyWorkingHoursService = inject(
    TotalMonthlyWorkingHoursServiceService
  );
  translateService = inject(TranslateService);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'description',
    'project',
    'workingHours',
    'calc',
  ];
  total: number;
  dataSource = new MatTableDataSource<Invoice>();

  tmwhForm = this.formBuilder.group<Partial<TotalMonthlyWorkingHours>>({
    yearMonth: moment(),
    clientId: 1,
  });

  ngOnInit(): void {
    this.getTotalMonthlyWorkHoursTable(
      this.tmwhForm.value as TotalMonthlyWorkingHours
    );
  }

  getTotalMonthlyWorkHoursTable(
    totalMonthlyWorkHoursForm: Partial<TotalMonthlyWorkingHours>
  ) {
    this.totalMonthlyWorkingHoursService
      .getTotalMonthlyWorkingHoursData({
        clientId: totalMonthlyWorkHoursForm.clientId,
        month: moment(totalMonthlyWorkHoursForm.yearMonth).month() + 1,
        year: moment(totalMonthlyWorkHoursForm.yearMonth).year(),
      })
      .subscribe((monthlyWorkingHoursData: any) => {
        this.dataSource.data = monthlyWorkingHoursData.finalItems;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.total = monthlyWorkingHoursData.finalItems.length;
      });
  }

  setMonthAndYear(
    normalizedMonthAndYear: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const ctrlValue =
      moment(this.tmwhForm.controls.yearMonth?.value) ?? moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.tmwhForm.controls.yearMonth!.setValue(ctrlValue);
    datepicker.close();
  }

  onSubmit() {
    this.getTotalMonthlyWorkHoursTable(
      this.tmwhForm.value as TotalMonthlyWorkingHours
    );
  }
}
