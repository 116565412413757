import { Component } from '@angular/core';

@Component({
    selector: 'app-failed',
    imports: [],
    templateUrl: './failed.component.html',
    styleUrl: './failed.component.scss'
})
export class FailedComponent {

}
