import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Vacation } from '../interfaces/vacation';
import { VacationService } from '../services/vacation.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ShowIfInGroupDirective } from '../../directives/show-if-in-group.directive';
import { VacationsDialogComponent } from '../vacations-dialog/vacations-dialog.component';
import { ApproveDto } from '../interfaces/approveDto';
import { RoleService } from '../../utils/role.service';
import { groups } from '../../../environments/environment';
import { Employee } from '../../employee-menagement/interfaces/employee';
import { EmployeesService } from '../../employee-menagement/services/employees.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { ConfrmationDialogComponent } from '../../utils/confrmation-dialog/confrmation-dialog.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SpecialOccasionDayService } from '../../special-occasion-day/special-occasion-day.service';
import { SpecialOccasionDayModel } from '../../special-occasion-day/interfaces/specialOccasionDayModel';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FindSpecOccDayPipe } from '../spec-occasion-pipe';
import { HomeTileComponent } from '../../home-tile/home-tile.component';
import { RemainingVacationEmployeeDays } from '../interfaces/remainingVacationEmployeeDays';
import { ExportComponent } from '../../shared/export/export.component';

@Component({
  selector: 'app-vacations',
  standalone: true,
  imports: [
    MatExpansionModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    ShowIfInGroupDirective,
    MatCheckboxModule,
    FormsModule,
    TranslateModule,
    MatTooltipModule,
    FindSpecOccDayPipe,
    HomeTileComponent,
  ],
  templateUrl: './vacations.component.html',
  styleUrl: './vacations.component.scss',
})
export class VacationsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'employeeFullName',
    'isSpecial',
    'startDate',
    'endDate',
    'numberOfDays',
    'typeOfContract',
    'approved',
    'actions',
    'approve',
  ];
  dataSource = new MatTableDataSource<Vacation>();
  employees: Employee[] = [];
  specialOccasionDays: SpecialOccasionDayModel[] = [];
  remainingVacationDays: string;
  lastYearVacationDays: string;

  //inject services
  authGroups = groups;
  roleService = inject(RoleService);
  vacationService = inject(VacationService);
  employeesService = inject(EmployeesService);
  specialOccasionService = inject(SpecialOccasionDayService);
  snackBar = inject(MatSnackBar);
  dialog = inject(MatDialog);
  isAdminUser: boolean;
  activeonly = true;
  translateService = inject(TranslateService);

  ngOnInit(): void {
    this.isAdminUser = this.roleService.checkIfUserIsInGroup(
      this.authGroups.EofficeAdmin
    );
    this.getVacations(this.isAdminUser);
    this.getNumberOfFreeDays();

    if (!this.isAdminUser) {
      this.displayedColumns.pop();
    }

    if (this.isAdminUser) {
      this.getListOfEmployees();
    }
  }

  exportVacation() {
    this.dialog.open(ExportComponent, {
      data: {
        employees: this.employees,
        typeOfExport: 'Vacation',
      },
      width: '500px',
      maxHeight: '500px',
    });
  }

  OnActiveOnlyChange() {
    this.getVacations(this.isAdminUser);
  }

  getListOfEmployees() {
    this.employeesService.getEmployeesForSelect().subscribe((data: any) => {
      this.employees = data;
    });
  }

  editVacationRequest(model: Vacation) {
    this.dialog
      .open(VacationsDialogComponent, {
        data: {
          model: model,
          employees: this.employees,
          isAdminUser: this.isAdminUser,
          specialOccasionDays: this.specialOccasionDays,
        },
        width: '500px',
        maxHeight: '500px',
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.getVacations(this.isAdminUser);
        }
      });
  }

  applyFilter($event: Event) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addVacation() {
    this.dialog
      .open(VacationsDialogComponent, {
        data: {
          model: {
            id: 0,
            startDate: '',
            endDate: '',
            isSpecial: false,
            specialOccasionId: null,
          },
          employees: this.employees,
          isAdminUser: this.isAdminUser,
          specialOccasionDays: this.specialOccasionDays,
        },
        width: '500px',
        maxHeight: '500px',
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.getVacations(this.isAdminUser);
        }
      });
  }

  private getVacations(isAdminUser: boolean) {
    this.specialOccasionService.getAll().subscribe((data: any) => {
      this.specialOccasionDays = data;
    });

    if (isAdminUser) {
      this.vacationService
        .getAllAdmin(this.activeonly)
        .subscribe((result: any) => {
          this.dataSource = new MatTableDataSource<Vacation>(result);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
    } else {
      this.vacationService.getAllForEmployee().subscribe((result: any) => {
        this.dataSource = new MatTableDataSource<Vacation>(result);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    }
  }

  deleteVacationRequest(vacationId: number, employeeId: number): void {
    const dialogRef = this.dialog.open(ConfrmationDialogComponent);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.vacationService.delete(vacationId, employeeId).subscribe(() => {
          this.getVacations(this.isAdminUser);
        });
      }
    });
  }

  changeStatusOfVacationRequest(element: any, approved: boolean): void {
    const model: ApproveDto = {
      employeeId: element.employeeId,
      entityId: element.id,
      approved: approved,
    };
    this.vacationService.changeState(model).subscribe({
      next: () => {
        this.getVacations(this.isAdminUser);
        if (approved) {
          this.getNumberOfFreeDays();
          this.snackBar.open(
            this.translateService.instant('general.request_approved'),
            '',
            { panelClass: 'snackbar-success' }
          );
        } else {
          this.snackBar.open(
            this.translateService.instant('general.request_rejected'),
            '',
            { panelClass: 'snackbar-error' }
          );
        }
      },
      error: () => {
        this.snackBar.open(this.translateService.instant('general.error'), '', {
          panelClass: 'snackbar-error',
        });
      },
    });
  }

  getNumberOfFreeDays(): void {
    this.vacationService.getVacationDaysForEmployee().subscribe({
      next: (data: RemainingVacationEmployeeDays) => {
        this.remainingVacationDays = data.remainingVacationDays.toString();
        this.lastYearVacationDays = data.lastYearVacationDays.toString();
      },
      error: () => {
        this.snackBar.open(this.translateService.instant('general.error'), '', {
          panelClass: 'snackbar-error',
        });
      },
    });
  }
}
