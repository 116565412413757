import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NonWorkingDaySearch } from "../interfaces/nonWorkingDaySearch";
import { Observable } from "rxjs";
import { nonWorkingDays } from "../interfaces/nonWorkingDays";
import { NonWorkingDay } from "../interfaces/nonWorkingDay";
import { PagingParamsAndSearch } from "../../utils/pagingParamsAndSearch";
import { environment } from "../../../environments/environment";

@Injectable()
export class NonWorkingDaysService {
 
    
    
    url = environment.apiEofficeConfig.endpoint + '/NotWorkingDays';

    constructor(private http:HttpClient) { }


    getAllForExport(searchParams: NonWorkingDaySearch): Observable<any> {
        return this.http.post(this.url + 'getAll', searchParams);
      }
    getYears(): Observable<any> {
    return this.http.get(this.url + '/GetYears');
    }
    getByYear(year: nonWorkingDays):  Observable<any> {
    return this.http.get(this.url + '/GetByYear/' + year);
    }

    getAllPagedWithSearch(pagingAndSearchParams: PagingParamsAndSearch) : Observable<any> {
        return this.http.post(this.url + '/GetAllPaged', pagingAndSearchParams);
      }

      delete(id: number) {
        return this.http.delete(this.url + '/' + id);
      }   

      update(model: NonWorkingDay): Observable<any> {
        console.log(model);
        
        return this.http.put(this.url ,model);
      }
      create(model: NonWorkingDay) {
        console.log(model);
        return this.http.post(this.url +'/' ,model);
      }
}