import { Component } from '@angular/core';

@Component({
  selector: 'app-failed',
  standalone: true,
  imports: [],
  templateUrl: './failed.component.html',
  styleUrl: './failed.component.scss'
})
export class FailedComponent {

}
