import { Inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Book } from './interfaces/book';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BookstoreService {

  url = environment.apiEofficeConfig.endpoint + '/Books';
  http = Inject(HttpClient);
  constructor() {
   }

  getAllBooks() {
    return this.http.get(this.url + '/GetAll/');
  }

  // getAllAdmin(){
  //   return this.http.get(this.url + '/GetAllAdmin/');
  // }

  delete(id: number) {
    return this.http.delete(this.url + '/' + id);
  }

  update(model: Book) {
    return this.http.put(this.url, model);
  }

  create(model: Book) {
    return this.http.post(this.url, model);
  }

  getBook(id: number) {
    return this.http.get(this.url + '/' + id);
  }
}


