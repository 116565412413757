import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DaysAtTheOfficeService } from './services/days-at-the-office.service';
import { DaysAtTheOffice } from './interfaces/daysAtTheOffice';
import { FormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-days-at-the-office',
  standalone: true,
  imports: [
    MatExpansionModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    TranslateModule,
  ],
  templateUrl: './days-at-the-office.component.html',
  styleUrl: './days-at-the-office.component.scss',
})
export class DaysAtTheOfficeComponent implements OnInit {
  //service injection
  daysAtTheOfficrService = inject(DaysAtTheOfficeService);
  snackBar = inject(MatSnackBar);
  translateService = inject(TranslateService);

  //variables
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'typeOfContract',
    'dayMonthAtOffice',
  ];
  dataSource = new MatTableDataSource<DaysAtTheOffice>();
  page: number = 1;
  total: number;
  pageSize: number = 5;
  month: number;
  year: number;

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.month = new Date().getMonth() + 1;

    this.getDaysAtTheOffice(this.month, this.year);
  }

  public getDaysAtTheOffice(month: number, year: number) {
    if (month < 1 || month > 12 || year < 2000 || year > 2100) {
      this.snackBar.open(
        this.translateService.instant(
          'days_at_the_office.validation.value_range_error'
        ),
        '',
        {
          panelClass: ['snackbar-error'],
        }
      );
      return;
    }

    this.daysAtTheOfficrService.getAll(month, year).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource<DaysAtTheOffice>(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.total = data.length;
    });
  }

  public export() {
    this.daysAtTheOfficrService.export(this.month, this.year).subscribe({
      next: (data) => {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(data);
        link.href = url;
        link.download = 'DaysAtTheOffice_' + new Date().toISOString() + '.xlsx';
        link.click();
        window.URL.revokeObjectURL(url);
      },
      error: (error) => {
        console.log(error);

        this.snackBar.open(
          this.translateService.instant('days_at_the_office.export.error'),
          '',
          {
            panelClass: ['snackbar-error'],
          }
        );
      },
    });
  }
}
