<div class="modal-header">
    <h2 mat-dialog-title>@if(this.model.id == 0){
        {{'work_from_home.add' | translate}}
        }@else {
        {{'work_from_home.edit' | translate}}
        }</h2>
    <mat-icon aria-hidden="false" aria-label="pending icon" fontIcon="add_home"></mat-icon>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
    <form [formGroup]="workFromHomeForm" (ngSubmit)="onSubmit()">
        <div class="form-group">   
            <mat-form-field>
                <mat-label> {{'work_from_home.enter_date_range' | translate}} </mat-label>
                <mat-date-range-input [dateFilter]="WorkFromHomeDateFilter" [rangePicker]="picker">
                    <input matStartDate placeholder="{{'work_from_home.start_date_placeholder' | translate}}" formControlName="startDate">
                    <input matEndDate placeholder="{{'work_from_home.end_date_placeholder' | translate}}" formControlName="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            @if(this.data.isAdminUser){
                <mat-form-field>
                    <mat-label> {{'work_from_home.employee_name' | translate}}</mat-label>
                    <input type="text" aria-label="employee" #input matInput [formControl]="this.workFromHomeForm.controls.employee" [matAutocomplete]="auto"  (input)="filter()"
                    (focus)="filter()">
                   
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" requireSelection>
                        @for (option of filteredEmployees | async; track option) {
                        <mat-option [value]="option">{{option.fullName}}</mat-option>
                        }
                    </mat-autocomplete>
                  
                </mat-form-field>
                @if (this.workFromHomeForm.controls.employee.invalid && this.workFromHomeForm.controls.employee.touched || this.workFromHomeForm.controls.employee.dirty) {
                    @if(this.workFromHomeForm.controls.employee.errors?.['required'])
                    {
                        <mat-error> {{'work_from_home.employee_name_requered' | translate}}</mat-error>
                    }
                }
            }
        </div>
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-label> {{'work_from_home.description' | translate}}</mat-label>
                <input matInput formControlName="description" placeholder="{{'work_from_home.description' | translate}}">
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close tabindex="-1" (click)="onClose()"> {{'buttons.cancel' | translate}}</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="!this.workFromHomeForm.valid" (click)="onSubmit()"> {{'buttons.save' | translate}}</button>
</mat-dialog-actions>