import { Component, Inject, inject } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ProjectService } from '../interfaces/project.service';
import { Project } from '../interfaces/project';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-project-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatDivider,
    MatOptionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    TranslateModule,
  ],
  templateUrl: './project-dialog.component.html',
  styleUrl: './project-dialog.component.scss',
})
export class ProjectDialogComponent {
  projectService = inject(ProjectService);
  dialogRef = inject(MatDialogRef<ProjectDialogComponent>);
  formBuilder = inject(FormBuilder);
  snackBar = inject(MatSnackBar);
  translateService = inject(TranslateService);

  model: Project;
  projectForm = this.formBuilder.group({
    name: ['', Validators.required],
    clientId: [1, Validators.required],
    active: [false, [Validators.required]],
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: { model: Project }) {
    this.model = data.model;

    if (this.model.id !== 0) {
      this.projectForm.patchValue({
        name: this.model.name,
        clientId: this.model.clientId,
        active: this.model.active,
      });
    }
  }

  onSubmit() {
    this.mapFormValuesToModel();
    if (this.model.id === 0) {
      this.createProject();
    } else {
      this.updateProject();
    }
  }

  updateProject(): void {
    this.projectService.edit(this.model).subscribe({
      next: () => {
        this.snackBar.open('Porject is modified');
        this.dialogRef.close(true);
      },
      error: () => {
        this.snackBar.open('Error');
      },
    });
  }

  createProject(): void {
    this.projectService.add(this.model).subscribe({
      next: () => {
        this.snackBar.open('Project is created');
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.snackBar.open('Error');
      },
    });
  }

  mapFormValuesToModel() {
    this.model.name = this.projectForm.value.name ?? '';
    this.model.clientId = this.projectForm.value.clientId ?? 1;
    this.model.active = this.projectForm.value.active ?? false;
  }

  onClose() {
    this.dialogRef.close();
  }
}
