import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SickLeave } from './interfaces/sickLeave';
import { approveModel } from '../shared/interfaces/approveModel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SickLeavesService {
  url = environment.apiEofficeConfig.endpoint + '/SickLeaves';
  http = inject(HttpClient);

  getAllForEmployee() {
    return this.http.get(this.url + '/GetAllForEmployee/');
  }

  getAllAdmin(activeOnly: boolean): Observable<SickLeave[]> {
    return this.http.get<SickLeave[]>(
      this.url + '/GetAllAdmin?activeOnly=' + activeOnly
    );
  }

  delete(id: number) {
    return this.http.delete(this.url + '/' + id);
  }

  update(model: SickLeave) {
    return this.http.put(this.url, model);
  }

  create(model: SickLeave) {
    return this.http.post(this.url, model);
  }

  getSickLeave(id: number) {
    return this.http.get(this.url + '/' + id);
  }

  changeStatusOfSickLeaveRequest(model: approveModel) {
    return this.http.post(this.url + '/ChangeState/', model);
  }

  export(
    startDate: string,
    endDate: string,
    employeeId: number
  ): Observable<Blob> {
    return this.http.get(
      this.url +
        '/ExportToExcel?startDate=' +
        startDate +
        '&endDate=' +
        endDate +
        '&employeeId=' +
        employeeId,
      { responseType: 'blob' }
    );
  }
}
