<div class="container">
    <div class="container-header">
        <button mat-raised-button color="primary" class="table-button" (click)="openDialog()">{{'special-occasion.add' | translate }}</button>
    </div>

    <mat-card>
        <mat-card-header class="mat-card-header">
            <h4>{{ 'special-occasion.container-header' | translate }}</h4>
        </mat-card-header>

        <mat-card-content>
            <div class="table-container">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSor>

                    <ng-conteainer matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'special-occasion.table-headers.title' | translate  }} </th>
                        <td mat-cell *matCellDef="let element"> {{ element.description }} </td>
                    </ng-conteainer>

                    <!-- Action column -->
                    <ng-container matColumnDef="actions" stickyEnd>
                        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button (click)="editEntity(element)" color="primary" aria-label="edit row">
                                <mat-icon>draw</mat-icon></button>
                            <button mat-icon-button (click)="deleteEntity(element.id)" color="primary"
                                aria-label="delete row">
                                <mat-icon>delete_forever</mat-icon></button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 25]" [pageSize]="pageSize" showFirstLastButtons [length]="total"
                aria-label="Select page of special occasion days">
            </mat-paginator>
        </mat-card-content>
    </mat-card>
</div>