export const environment = {
    production: false,
    msalConfig: {
        auth: {
            clientId: '5c2aac4a-a08a-4a48-9871-2bdd792b6430',
            authority: 'https://login.microsoftonline.com/bd82c850-31e3-468e-a170-886174a9e22d/',
            redirectUri: 'https://gentle-bay-0e29ad003.5.azurestaticapps.net/auth'
        }
    },
    apiConfig: {
        scopes: [ 'User.Read', 'GroupMember.Read.All' ],
        endpoint: 'https://graph.microsoft.com/v1.0/me'
    },
    apiEofficeConfig: {
        endpoint: 'https://eoffice-dev-api.azurewebsites.net/api',
        scopes: {
            read: ["api://760f94fa-f74a-47e5-a6ea-b9c154722e18/Eoffice.Read"],
            write: ["api://760f94fa-f74a-47e5-a6ea-b9c154722e18/EOffice.ReadWrite"]
        }
    },
};


export const groups = {
    EofficeAdmin: "3c45de3c-ecf8-4e2a-a8ac-f806f9f9e7dd",
    EofficeEmployee: "c7f1a6fb-75cc-46fe-ad46-cd24ad6385b0",
    EofficeHR: "7ee36ee8-82fc-4985-b273-53afa05deb42"
}
