import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { Seniority } from './interfaces/seniority';

@Injectable({
  providedIn: 'root'
})
export class SeniorityService {

  http = inject(HttpClient);
  url = environment.apiEofficeConfig.endpoint + '/Seniority';

  add(entity: Seniority) {
    return this.http.post(this.url , entity);
  }

  getAll() {
    return this.http.get(this.url + '/GetAll/');
  }

  delete(id: number) {
    return this.http.delete(this.url + '/' + id);
  }

  update(entity: Seniority) {
    return this.http.put(this.url, entity);
  }
}
 
