<div class="container">
    <div class="container-header">
        <button mat-raised-button color="primary" (click)="addNewBook()">Reserve book</button>
        <span class="spacer"></span>
        <mat-form-field>
            <mat-label><mat-icon>search</mat-icon>Search</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Value from table" #input>
        </mat-form-field>
    </div>
    <div>
        <mat-card>
            <mat-card-header class="mat-card-header">
                <mat-card-title>
                    <h4>Bookstore</h4>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>

                <div class="table-container">
                    <table mat-table [dataSource]="this.dataSource" class="mat-elevation-z8" matSort>
                        @if (isAdminUser) {
                        <ng-container matColumnDef="takenBy">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Taken by </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-form-field>
                                <mat-select
                                [id]="element.book.name"
                                placeholder="Employees"
                                [value]="element.employeesIds"
                                multiple
                                (selectionChange)="updateLibrary($event.value, element.book)"
                                >
                                <mat-option
                                    *ngFor="let employee of this.employees"
                                    [value]="employee.id"
                                >
                                    {{ employee.fullName }}</mat-option
                                >
                                </mat-select>
                            </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="reservedBy">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Reserved by </th>
                            <td mat-cell *matCellDef="let element">
                                <mat-form-field>
                                    <mat-select
                                    placeholder="People who reserved"
                                    [value]="element.employeesWhoReservedIds"
                                    multiple
                                    (selectionChange)="
                                        updateReservationsData($event.value, element)
                                    "
                                    >
                                    <mat-option
                                        *ngFor="let employee of this.employees"
                                        [value]="employee.id"
                                    >
                                        {{ employee.fullName }}</mat-option
                                    >
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        }
                        <ng-container matColumnDef="title">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                            <td mat-cell *matCellDef="let element"> {{element.book.name}} </td>
                        </ng-container>
                        <ng-container matColumnDef="authors">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Authors </th>
                            <td mat-cell *matCellDef="let element"> {{element.book.authors}} </td>
                        </ng-container>
                        <ng-container matColumnDef="isbn">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> ISBN </th>
                            <td mat-cell *matCellDef="let element"> {{element.book.Isbn}} </td>
                        </ng-container>
                        <ng-container matColumnDef="quantity">
                            <th mat-header-cell *matHeaderCellDef> Quantity </th>
                            <td mat-cell *matCellDef="let element"> {{element.book.count}} </td>
                        </ng-container>
                        <ng-container matColumnDef="inLibrary">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> In Library </th>
                            <td mat-cell *matCellDef="let element"> {{getNumberInLibrary(element) }}</td>
                        </ng-container>
                        <ng-container matColumnDef="reserved">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Reserved </th>
                            <td mat-cell *matCellDef="let element"> {{getNumberOFreservations(element)}} </td>
                        </ng-container>
                        <ng-container matColumnDef="options">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Options </th>
                            <td mat-cell *matCellDef="let element">
                                @if (getNumberInLibrary(element) > 0) {
                                @if (showReservation(element)) {
                                <button type="button" mat-flat-button class="btn btn-success"
                                    (click)="reserveBook(element.book)">
                                    Reserve
                                </button>
                                }
                                @if (showCaneclReservation(element)) {
                                <button type="button" mat-flat-button class="btn btn-info"
                                    (click)="cancelReservation(element.book.id)">
                                    Cancel reservation
                                </button>
                                }
                                }
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef> Actions </th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-button color="primary" (click)="editBook(element.book.id)"
                                    disabled="{{element.approved != null}}"><mat-icon>draw</mat-icon></button>
                                <button mat-button color="warn" (click)="deleteBookForever(element.book.id)"
                                    disabled="{{element.approved != null}}"><mat-icon>delete_forever</mat-icon></button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                        </tr>
                    </table>
                </div>
                <mat-paginator [pageSizeOptions]="[10, 25]"
                    aria-label="Select page of bookstore" showFirstLastButtons="true"></mat-paginator>
            </mat-card-content>
        </mat-card>
    </div>
</div>