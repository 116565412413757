import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-confrmation-dialog',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, TranslateModule],
  templateUrl: './confrmation-dialog.component.html',
  styleUrl: './confrmation-dialog.component.scss',
})
export class ConfrmationDialogComponent {
  readonly dialogRef = inject(MatDialogRef<ConfrmationDialogComponent>);

  ok(): any {
    this.dialogRef.close(true);
  }
  cancel() {
    this.dialogRef.close();
  }
}
