<mat-dialog-content>
  <div class="container">
    <mat-card class="card-profile">
      <mat-card-content class="card-body">
        <h4 class="heading">{{  'teambuilding.email_preview_header' | translate }}</h4>

        <!-- Show email edit text -->
        <div *ngIf="showEmailEditText()" class="email-section">
          <p> Ćao svima, </p>
          <p>{{ teamBuilding.emailTextForEditedRecord }}</p>
          <p>Vaše prisustvo možete označiti na 
            <a [href]="teamBuildingsLink" class="email-link">linku</a>.
          </p>
          <p> Hvala. </p>
          <p> Pozdrav </p>
        </div>

        <!-- Team building email type -->
        <div *ngIf="teamBuilding?.emailType === 'teambuilding'" class="email-section">
          <p> Ćao svima, </p>
          <p>
            {{ teamBuilding.startDate | date: 'dd.MM.yyyy' }} u {{ teamBuilding.startDate | date: 'HH:mm' }}h imamo organizovan team building,
            {{ teamBuilding.placeName }} u/na {{ teamBuilding.placeAddress }}.
          </p>
          <p>
            Molimo vas da do {{ teamBuilding.deadlineForCheckIn | date: 'dd.MM.yyyy HH:mm' }} označite vaše prisustvo na
            <a [href]="teamBuildingsLink" class="email-link">linku</a>.
          </p>
          <p> Hvala. </p>
          <p> Pozdrav </p>
        </div>

        <!-- Trip email type -->
        <div *ngIf="teamBuilding?.emailType === 'trip'" class="email-section">
          <p> Ćao svima, </p>
          <p>
            od {{ teamBuilding.startDate | date: 'dd.MM.yyyy' }} do {{ teamBuilding.endDate | date: 'dd.MM.yyyy' }} imamo organizovano
            putovanje u {{ teamBuilding.placeName }} {{ teamBuilding.placeAddress }}.
          </p>
          <p>
            Molimo vas da do {{ teamBuilding.deadlineForCheckIn | date: 'dd.MM.yyyy HH:mm' }} označite vaše prisustvo na
            <a [href]="teamBuildingsLink" class="email-link">linku</a>.
          </p>
          <p> Hvala. </p>
          <p> Pozdrav </p>
        </div>

      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>
