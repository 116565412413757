import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Dinner } from './interfaces/dinner';
import { Observable } from 'rxjs';
import { approveModel } from '../shared/interfaces/approveModel';

@Injectable({
  providedIn: 'root',
})
export class DinnersService {
  url = environment.apiEofficeConfig.endpoint + '/Dinners';
  http = inject(HttpClient);

  getAllForEmployee(): Observable<Dinner[]> {
    return this.http.get<Dinner[]>(this.url + '/GetAllForEmployee/');
  }

  getAllAdmin(): Observable<Dinner[]> {
    return this.http.get<Dinner[]>(this.url + '/GetAllAdmin');
  }

  delete(id: number) {
    return this.http.delete(this.url + '/' + id);
  }

  update(model: Dinner) {
    return this.http.put(this.url, model);
  }

  create(model: Dinner) {
    return this.http.post(this.url, model);
  }

  get(id: number): Observable<Dinner> {
    return this.http.get<Dinner>(this.url + '/' + id);
  }

  getDetails(id: number): Observable<Dinner> {
    return this.http.get<Dinner>(
      this.url + '/GetEmployeesStatusForDinner/' + id
    );
  }

  changeState(accepted: approveModel) {
    return this.http.post(this.url + '/ChangeState', accepted);
  }
}
