<mat-card>
  <mat-toolbar color="primary">Parking Reservation for Next 5 Days</mat-toolbar>
  <mat-divider></mat-divider>
  <table mat-table [dataSource]="parkingSlots" class="mat-elevation-z8">
    <!-- Monday Column -->
    <ng-container matColumnDef="monday">
      <th mat-header-cell *matHeaderCellDef>Monday</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-raised-button
          [disabled]="!isAvailable(element.id, 0)"
          color="primary"
          *ngIf="isAvailable(element.id, 0)"
          (click)="bookSpot(element.id, 0)"
        >
          Book
        </button>
        <span *ngIf="!isAvailable(element.id, 0)" class="unavailable"
          >Unavailable</span
        >
      </td>
    </ng-container>

    <!-- Tuesday Column -->
    <ng-container matColumnDef="tuesday">
      <th mat-header-cell *matHeaderCellDef>Tuesday</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-raised-button
          [disabled]="!isAvailable(element.id, 1)"
          color="primary"
          *ngIf="isAvailable(element.id, 1)"
          (click)="bookSpot(element.id, 1)"
        >
          Book
        </button>
        <span *ngIf="!isAvailable(element.id, 1)" class="unavailable"
          >Unavailable</span
        >
      </td>
    </ng-container>

    <!-- Wednesday Column -->
    <ng-container matColumnDef="wednesday">
      <th mat-header-cell *matHeaderCellDef>Wednesday</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-raised-button
          [disabled]="!isAvailable(element.id, 2)"
          color="primary"
          *ngIf="isAvailable(element.id, 2)"
          (click)="bookSpot(element.id, 2)"
        >
          Book
        </button>
        <span *ngIf="!isAvailable(element.id, 2)" class="unavailable"
          >Unavailable</span
        >
      </td>
    </ng-container>

    <!-- Thursday Column -->
    <ng-container matColumnDef="thursday">
      <th mat-header-cell *matHeaderCellDef>Thursday</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-raised-button
          [disabled]="!isAvailable(element.id, 3)"
          color="primary"
          *ngIf="isAvailable(element.id, 3)"
          (click)="bookSpot(element.id, 3)"
        >
          Book
        </button>
        <span *ngIf="!isAvailable(element.id, 3)" class="unavailable"
          >Unavailable</span
        >
      </td>
    </ng-container>

    <!-- Friday Column -->
    <ng-container matColumnDef="friday">
      <th mat-header-cell *matHeaderCellDef>Friday</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-raised-button
          [disabled]="!isAvailable(element.id, 4)"
          color="primary"
          *ngIf="isAvailable(element.id, 4)"
          (click)="bookSpot(element.id, 4)"
        >
          Book
        </button>
        <span *ngIf="!isAvailable(element.id, 4)" class="unavailable"
          >Unavailable</span
        >
      </td>
    </ng-container>

    <!-- Header and Row Declarations -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-card>
