import { Component, Inject, inject } from '@angular/core';
import { Seniority } from '../interfaces/seniority';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { SeniorityService } from '../seniority.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-seniority-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatDivider,
    MatCheckboxModule,
    MatIconModule,
    TranslateModule,
  ],
  templateUrl: './seniority-dialog.component.html',
  styleUrl: './seniority-dialog.component.scss',
})
export class SeniorityDialogComponent {
  seniorityService = inject(SeniorityService);
  snackBar = inject(MatSnackBar);
  formBuilder = inject(FormBuilder);
  translateService = inject(TranslateService);

  model: Seniority;
  seniorityForm = this.formBuilder.group({
    title: ['', Validators.required],
    unitPrice: [0, Validators.required],
    description: ['', [Validators.required, Validators.maxLength(50)]],
    active: [false, Validators.required],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { model: Seniority },
    public dialogRef: MatDialogRef<SeniorityDialogComponent>
  ) {
    this.model = data.model;

    if (this.model.id !== 0) {
      this.seniorityForm.patchValue({
        title: this.model.title,
        unitPrice: this.model.unitPrice,
        description: this.model.description,
        active: this.model.active,
      });
    }
  }

  onSubmit() {
    this.mapFormValuesToModel();
    if (this.model.id === 0) {
      this.createSeniority();
    } else {
      this.updateSeniority();
    }
  }

  updateSeniority(): void {
    this.seniorityService.update(this.model).subscribe({
      next: () => {
        this.snackBar.open('Seniority is modified');
        this.dialogRef.close(true);
      },
      error: () => {
        this.snackBar.open('Error');
      },
    });
  }

  createSeniority(): void {
    this.seniorityService.add(this.model).subscribe({
      next: () => {
        this.snackBar.open('Seniority created');
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.snackBar.open('Error');
      },
    });
  }

  mapFormValuesToModel() {
    this.model.title = this.seniorityForm.value.title ?? '';
    this.model.unitPrice = this.seniorityForm.value.unitPrice ?? 0;
    this.model.description = this.seniorityForm.value.description ?? '';
    this.model.active = this.seniorityForm.value.active ?? false;
  }

  onClose() {
    this.dialogRef.close();
  }
}
