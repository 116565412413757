import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import { environment } from '../environments/environment';
import {
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { GroupGuard } from './group.guard';
import { msalConfig, protectedResources } from './auth-config';

import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { NonWorkingDaysService } from './nonWorkingDays/services/non-working-days.service';
import { LoadingInterceptor } from './utils/intraceptors/loadingIntraceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: msalConfig.auth,
    cache: msalConfig.cache,
    system: msalConfig.system,
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<
    string,
    Array<string | ProtectedResourceScopes> | null
  >();
  protectedResourceMap.set(protectedResources.eOfficeApi.endpoint, [
    {
      httpMethod: 'GET',
      scopes: [...protectedResources.eOfficeApi.scopes.read],
    },
    {
      httpMethod: 'POST',
      scopes: [...protectedResources.eOfficeApi.scopes.write],
    },
    {
      httpMethod: 'PUT',
      scopes: [...protectedResources.eOfficeApi.scopes.write],
    },
    {
      httpMethod: 'DELETE',
      scopes: [...protectedResources.eOfficeApi.scopes.write],
    },
  ]);
  protectedResourceMap.set(protectedResources.apiGraph.endpoint, [
    { httpMethod: 'GET', scopes: [...protectedResources.apiGraph.scopes] },
    { httpMethod: 'POST', scopes: [...protectedResources.apiGraph.scopes] },
    { httpMethod: 'PUT', scopes: [...protectedResources.apiGraph.scopes] },
    { httpMethod: 'DELETE', scopes: [...protectedResources.apiGraph.scopes] },
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes],
    },
    loginFailedRoute: '/login-failed',
  };
}

export const en_gb_date_Formats = {
  parse: {
    dateInput: 'YYYY-MM-DDTHH:mm:ss',
  },
  display: {
    dateInput: 'DD.MM.yyyy',
    monthYearLabel: 'YYYY.MM',
    dateA11yLabel: 'DD.MM.yyyy',
    monthYearA11yLabel: 'YYYY.MM',
  },
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2500,
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
      },
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    GroupGuard,
    NonWorkingDaysService,
    provideMomentDateAdapter(en_gb_date_Formats),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
  ],
};
