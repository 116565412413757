import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, ObservedValueOf } from 'rxjs';
import { TeamBuilding } from './interfaces/teamBuilding';
import { approveModel } from '../shared/interfaces/approveModel';

@Injectable({
  providedIn: 'root'
})
export class TeambuildingService {

  
  url = environment.apiEofficeConfig.endpoint + '/TeamBuilding';
  http = inject(HttpClient);


  getAllForEmployee() : Observable<TeamBuilding[]> {
    return this.http.get<TeamBuilding[]>(this.url + '/GetAllForEmployee/');
  }

  getAllAdmin() : Observable<TeamBuilding[]> {
    return this.http.get<TeamBuilding[]>(this.url + '/GetAllAdmin');
  }

  delete(id: number) {
    return this.http.delete(this.url + '/' + id);
  }

  update(model: TeamBuilding) {
    return this.http.put(this.url, model);
  }

  create(model: TeamBuilding) {
    return this.http.post(this.url, model);
  }

  get(id: number) : Observable<TeamBuilding> {
    return this.http.get<TeamBuilding>(this.url + '/' + id);
  }

  getDetails(id: number): Observable<TeamBuilding> {
    return this.http.get<TeamBuilding>(this.url + '/getEmployeesStatusForTeamBuilding/' + id);
  }

  changeState(accepted: approveModel) {
    return this.http.post(this.url + '/ChangeState', accepted);
  }
}
