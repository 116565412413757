import { Component, inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NonWorkingDaysService } from '../services/non-working-days.service';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { NonWorkingDay } from '../interfaces/nonWorkingDay';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-non-working-days-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatDivider,
    MatIconModule,
    TranslateModule,
  ],
  templateUrl: './non-working-days-dialog.component.html',
  styleUrl: './non-working-days-dialog.component.scss',
})
export class NonWorkingDaysDialogComponent implements OnInit {
  //services
  nonWorkingDaysService = inject(NonWorkingDaysService);
  dialogRef = inject<MatDialogRef<NonWorkingDaysDialogComponent>>(
    MatDialogRef<NonWorkingDaysDialogComponent>
  );
  formBuilder = inject(FormBuilder);
  snackBar = inject(MatSnackBar);
  data = inject(MAT_DIALOG_DATA);
  translateService = inject(TranslateService);

  //variables
  model: NonWorkingDay;
  nonWorkingDayForm = this.formBuilder.group({
    startDate: [new Date(), Validators.required],
    endDate: ['', Validators.required],
    title: ['', [Validators.required, Validators.maxLength(50)]],
  });

  ngOnInit(): void {
    this.model = this.data.model;

    if (this.model.id !== 0) {
      this.nonWorkingDayForm.patchValue({
        startDate: this.model.startDate,
        endDate: this.model.endDate,
        title: this.model.title,
      });
    }
  }

  onSubmit() {
    this.mapFormValuesToModel();
    if (this.model.id === 0) {
      this.createNonWorkingDay();
    } else {
      this.updateNonWorkingDay();
    }
  }

  updateNonWorkingDay(): void {
    this.nonWorkingDaysService.update(this.model).subscribe({
      next: () => {
        this.snackBar.open(
          this.translateService.instant('non_working_day.updated'),
          '',
          {
            panelClass: 'snackbar-success',
          }
        );
        this.dialogRef.close();
      },
      error: () => {
        this.snackBar.open(this.translateService.instant('general.error'), '', {
          panelClass: 'snackbar-error',
        });
      },
    });
  }

  createNonWorkingDay(): void {
    this.nonWorkingDaysService.create(this.model).subscribe({
      next: () => {
        this.snackBar.open(
          this.translateService.instant('non_working_day.created'),
          '',
          {
            panelClass: 'snackbar-success',
          }
        );
        this.dialogRef.close();
      },
      error: (err) => {
        this.snackBar.open(this.translateService.instant('general.error'), '', {
          panelClass: 'snackbar-error',
        });
      },
    });
  }

  mapFormValuesToModel() {
    this.model.startDate = this.nonWorkingDayForm.value.startDate!;
    this.model.endDate = this.nonWorkingDayForm.value.endDate;
    this.model.title = this.nonWorkingDayForm.value.title;
  }

  onClose() {
    this.dialogRef.close();
  }
}
