import { MonthlyExpenses } from './monthlyExpenses';

export class Employee {
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    levelOfEducation: string;
    degree: string;
    school: string;
    jmbg: string;
    lbo: string;
    contactPhone: string;
    emergencyContacts: string;
    kids: string;
    numberOfKids: number;
    insuredFamilyMembers: string;
    publicTransportationZone: string;
    ticketPrice: number;
    typeOfContract: string;
    dateOfContractTermination: any;
    dateOfPassingToAgency: any;
    address: string;
    belgradeAddress: string;
    position: string;
    active: boolean;
    phoneNumber: string;
    image: string;
    dateOfEmployment: any;
    dateOfBirth: any;
    yearsOfService: number;
    seniorityId: number;
    totalFreeDays: number;
    freeDaysUpdateTime: any;
    hoursForNegotiatedAmount: number;
    hoursDifference: number;
    defaultInsuranceAmount: number;
    monthlyExpenses: MonthlyExpenses;

    constructor() {
      this.monthlyExpenses = new MonthlyExpenses();
    }
  }
