import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { WorkFromHome } from '../interfaces/workfromhome';
import { WorkFromHomeService } from '../work-from-home.service';
import { WorkFromHomeDialogComponent } from '../work-from-home-dialog/work-from-home-dialog.component';
import { RoleService } from '../../utils/role.service';
import { groups } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { approveModel } from '../../shared/interfaces/approveModel';
import { Employee } from '../../employee-menagement/interfaces/employee';
import { EmployeesService } from '../../employee-menagement/services/employees.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { ConfrmationDialogComponent } from '../../utils/confrmation-dialog/confrmation-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { ExportComponent } from '../../shared/export/export.component';

@Component({
  selector: 'app-work-from-home',
  standalone: true,
  imports: [
    MatExpansionModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    MatCheckboxModule,
    FormsModule,
    TranslateModule,
  ],
  templateUrl: './work-from-home.component.html',
  styleUrl: './work-from-home.component.scss',
})
export class WorkFromHomeComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'employeeFullName',
    'description',
    'startDate',
    'endDate',
    'numberOfDays',
    'typeOfContract',
    'approved',
    'actions',
  ];
  dataSource = new MatTableDataSource<WorkFromHome>();
  page: number = 1;
  total: number;
  isAdminUser: boolean = false;
  authGroups = groups;

  roleService = inject(RoleService);
  employeesService = inject(EmployeesService);
  workFromHomeService = inject(WorkFromHomeService);
  dialog = inject(MatDialog);
  snackBar = inject(MatSnackBar);
  employees: Employee[] = [];
  activeonly = true;

  ngOnInit(): void {
    this.isAdminUser = this.roleService.checkIfUserIsInGroup(
      this.authGroups.EofficeAdmin
    );

    this.getWorkFromHomeForEmployee(this.isAdminUser);
    if (this.isAdminUser) {
      this.getListOfEmployees();
      this.displayedColumns.push('adminActions');
    }
  }
  getListOfEmployees() {
    this.employeesService.getEmployeesForSelect().subscribe((data: any) => {
      this.employees = data;
    });
  }

  OnActiveOnlyChange() {
    this.getWorkFromHomeForEmployee(this.isAdminUser);
  }

  applyFilter($event: Event) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addWorkFromHome() {
    this.dialog
      .open(WorkFromHomeDialogComponent, {
        data: {
          model: { id: 0, startDate: '', endDate: '', decription: '' },
          isAdminUser: this.isAdminUser,
          employees: this.employees,
        },
        width: '500px',
        maxHeight: '500px',
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getWorkFromHomeForEmployee(this.isAdminUser);
        }
      });
  }

  editWorkFromHome(model: WorkFromHome) {
    this.dialog
      .open(WorkFromHomeDialogComponent, {
        data: {
          model: model,
          isAdminUser: this.isAdminUser,
          employees: this.employees,
        },
        width: '500px',
        maxHeight: '500px',
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getWorkFromHomeForEmployee(this.isAdminUser);
        }
      });
  }

  deleteWorkFromHome(id: number) {
    const dialogRef = this.dialog.open(ConfrmationDialogComponent);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.workFromHomeService.delete(id).subscribe(() => {
          this.getWorkFromHomeForEmployee(this.isAdminUser);
        });
      }
    });
  }

  private getWorkFromHomeForEmployee(isAdminUser: boolean) {
    if (isAdminUser) {
      this.workFromHomeService
        .getAllAdmin(this.activeonly)
        .subscribe((data: any) => {
          this.dataSource = new MatTableDataSource<WorkFromHome>(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.total = data.length;
        });
    } else {
      this.workFromHomeService.getAllForEmployee().subscribe((data: any) => {
        this.dataSource = new MatTableDataSource<WorkFromHome>(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.total = data.length;
      });
    }
  }

  changeStateWorkFromHome(request: WorkFromHome, approve: boolean) {
    let model: approveModel = {
      entityId: request.id,
      employeeId: request.employeeId,
      approved: approve,
    };
    this.workFromHomeService.changeStateOfWorkFromHomeRequest(model).subscribe({
      next: () => {
        this.getWorkFromHomeForEmployee(this.isAdminUser);
        if (approve) {
          this.snackBar.open('Request approved ', 'Close', {
            duration: 2000,
            panelClass: 'snackbar-success',
          });
        } else {
          this.snackBar.open('Request rejected!', 'Close', {
            duration: 2000,
            panelClass: ['snackbar-error'],
          });
        }
      },
      error: () => {
        this.snackBar.open(
          'Error for chage state of work from home request',
          'Close',
          {
            duration: 2000,
            panelClass: ['snackbar-error'],
          }
        );
      },
    });
  }

  exportWorkFromHome() {
    this.dialog.open(ExportComponent, {
      data: {
        employees: this.employees,
        typeOfExport: 'WorkFromHome',
      },
      width: '500px',
      maxHeight: '500px',
    });
  }
}
