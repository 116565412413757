import { Component, inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SickLeave } from '../interfaces/sickLeave';
import { SickLeavesService } from '../sick-leaves.service';
import { MatSelectModule } from '@angular/material/select';
import { EmployeesService } from '../../employee-menagement/services/employees.service';
import { Employee } from '../../employee-menagement/interfaces/employee';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-sick-leaves-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatDivider,
    MatSelectModule,
    MatIconModule,
    TranslateModule,
  ],
  templateUrl: './sick-leaves-dialog.component.html',
  styleUrl: './sick-leaves-dialog.component.scss',
})
export class SickLeavesDialogComponent implements OnInit {
  employeesService = inject(EmployeesService);
  sickLeavesService = inject(SickLeavesService);
  dialogRef = inject<MatDialogRef<SickLeavesDialogComponent>>(
    MatDialogRef<SickLeavesDialogComponent>
  );
  formBuilder = inject(FormBuilder);
  snackBar = inject(MatSnackBar);
  data = inject(MAT_DIALOG_DATA);
  translateService = inject(TranslateService);

  isAdminUser: boolean = false;
  employees: Employee[] = [];
  model: SickLeave;
  sickLeaveForm = this.formBuilder.group({
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    note: [''],
    type: ['', Validators.required],
    employeeId: [0, Validators.required],
  });

  ngOnInit(): void {
    this.model = this.data.model;
    this.isAdminUser = this.data.isAdminUser;

    if (this.isAdminUser) {
      this.employeesService.getAll(true).subscribe({
        next: (employees) => {
          this.employees = employees;
        },
        error: (err) => {
          this.snackBar.open(
            this.translateService.instant('general.error') + err.error.message,
            '',
            { panelClass: 'snackbar-error' }
          );
        },
      });
    }

    if (this.model.id !== 0) {
      this.sickLeaveForm.patchValue({
        startDate: this.model.startDate,
        endDate: this.model.endDate,
        note: this.model.note,
        type: this.model.type,
        employeeId: this.model.employeeId,
      });
    }
  }

  onSubmit() {
    this.mapFormValuesToModel();
    if (this.model.id === 0) {
      this.createSickLeave();
    } else {
      this.updateSickLeave();
    }
  }

  updateSickLeave(): void {
    this.sickLeavesService.update(this.model).subscribe({
      next: () => {
        this.snackBar.open(
          this.translateService.instant('sick-leave.updated'),
          '',
          { panelClass: 'snackbar-success' }
        );
        this.dialogRef.close(true);
      },
      error: (err) => {
        const translatedMessage = this.translateService.instant(
          'error_messages.' + err.error.message
        );
        this.snackBar.open(
          this.translateService.instant('general.error') + translatedMessage,
          '',
          { panelClass: 'snackbar-error' }
        );
      },
    });
  }

  createSickLeave(): void {
    this.sickLeavesService.create(this.model).subscribe({
      next: () => {
        this.snackBar.open(
          this.translateService.instant('sick-leave.created'),
          '',
          { panelClass: 'snackbar-success' }
        );
        this.dialogRef.close(true);
      },
      error: (err) => {
        const translatedMessage = this.translateService.instant(
          'error_messages.' + err.error.message
        );
        this.snackBar.open(
          this.translateService.instant('general.error') + translatedMessage,
          '',
          { panelClass: 'snackbar-error' }
        );
      },
    });
  }

  mapFormValuesToModel() {
    this.model.startDate = formatDate(
      this.sickLeaveForm.value.startDate!,
      'yyyy-MM-dd',
      'en-GB'
    );
    this.model.endDate = this.sickLeaveForm.value.endDate
      ? formatDate(this.sickLeaveForm.value.endDate, 'yyyy-MM-dd', 'en-GB')
      : null;
    this.model.note = this.sickLeaveForm.value.note ?? '';
    this.model.type = this.sickLeaveForm.value.type ?? '';
    this.model.employeeId = this.sickLeaveForm.value.employeeId! ?? -1;
  }

  onClose() {
    this.dialogRef.close();
  }
}
