import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  http = inject(HttpClient);
  url = environment.apiEofficeConfig.endpoint;

  export(
    startDate: string,
    endDate: string,
    employeeId: number,
    typeOfExport: string
  ): Observable<Blob | null> {
    switch (typeOfExport) {
      case 'Vacation':
        return this.http.get(
          this.url +
            '/Vacations/ExportToExcel?startDate=' +
            startDate +
            '&endDate=' +
            endDate +
            '&employeeId=' +
            employeeId,
          { responseType: 'blob' }
        );
      case 'SickLeave':
        return this.http.get(
          this.url +
            '/SickLeaves/ExportToExcel?startDate=' +
            startDate +
            '&endDate=' +
            endDate +
            '&employeeId=' +
            employeeId,
          { responseType: 'blob' }
        );
      case 'WorkFromHome':
        return this.http.get(
          this.url +
            '/WorkFromHome/ExportToExcel?startDate=' +
            startDate +
            '&endDate=' +
            endDate +
            '&employeeId=' +
            employeeId,
          { responseType: 'blob' }
        );
      default:
        return of(null);
    }
  }
}
