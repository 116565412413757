<mat-divider></mat-divider>
<div class="container">
  <h2>{{ "resource_allocation.title" | translate }}</h2>
  <form [formGroup]="resourceAllocationForm" class="form-container">
    <!-- <hr
      class="hr-text"
      data-content="{{ 'resource_allocation.general_information' | translate }}"
    /> -->
    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>{{
          "resource_allocation.select_employee" | translate
        }}</mat-label>
        <mat-select
          required
          disableOptionCentering="true"
          placeholder="{{ 'resource_allocation.select_employee' | translate }}"
          [formControl]="this.resourceAllocationForm.controls.employeeId"
          name="employeeId"
        >
          @for (employee of employees; track employee) {
          <mat-option [value]="employee.id">{{ employee.fullName }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          required
          (selectionChange)="clientChanged($event.value)"
          [formControl]="this.resourceAllocationForm.controls.clientId"
          placeholder="{{ 'resource_allocation.choose_client' | translate }}"
        >
          <mat-option [value]="1">Betabit</mat-option>
          <mat-option [value]="2">Enigmatry</mat-option>
        </mat-select>
      </mat-form-field>

      @if (this.resourceAllocationForm.controls.clientId.value == 1) {
      <mat-form-field>
        <input
          required
          trimOnBlur
          matInput
          formControlName="projectContractNumber"
          placeholder="{{
            'resource_allocation.project_contract_number' | translate
          }}"
          name="projectContractNumber"
        />
      </mat-form-field>
      }

      <mat-form-field>
        <mat-select
          required
          disableOptionCentering="true"
          placeholder="{{ 'resource_allocation.current_project' | translate }}"
          [formControl]="this.resourceAllocationForm.controls.currentProjectIds"
          name="currentProjectIds"
          multiple
        >
          @for (currentProject of projects; track currentProject) {
          <mat-option [value]="currentProject.id">{{
            currentProject.name
          }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          (focus)="startDatePicker.open()"
          required
          formControlName="startDate"
          [matDatepicker]="startDatePicker"
          placeholder="{{
            'resource_allocation.choose_start_date' | translate
          }}"
          name="startDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          (focus)="endDatePicker.open()"
          formControlName="endDate"
          [matDatepicker]="endDatePicker"
          placeholder="{{ 'resource_allocation.choose_end_date' | translate }}"
          name="endDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="endDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-select
          disableOptionCentering="true"
          placeholder="{{
            'resource_allocation.select_future_project' | translate
          }}"
          [formControl]="this.resourceAllocationForm.controls.futureProjectId"
          name="futureProjectId"
        >
          @for (futureProject of projects; track futureProject) {
          <mat-option [value]="futureProject.id">{{
            futureProject.name
          }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      @if (this.resourceAllocationForm.controls.clientId.value == 1) {
      <mat-form-field>
        <input
          matInput
          trimOnBlur
          formControlName="betabitEntity"
          placeholder="{{ 'resource_allocation.betabit_entity' | translate }}"
          name="betabitEntity"
        />
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          trimOnBlur
          formControlName="regionalManager"
          placeholder="{{ 'resource_allocation.regional_manager' | translate }}"
          name="regionalManager"
        />
      </mat-form-field>
      }

      <hr class="hr-text" />
    </div>
    <div class="action-buttons">
      <button mat-raised-button tabindex="-1" (click)="onClose()">
        {{ "buttons.cancel" | translate }}
      </button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        (click)="onSubmit()"
      >
        {{ "buttons.save" | translate }}
      </button>
    </div>
  </form>
</div>
