import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { BusinessTrip } from '../interfaces/businessTrip';
import { groups } from '../../../environments/environment';
import { Employee } from '../../employee-menagement/interfaces/employee';
import { RoleService } from '../../utils/role.service';
import { EmployeesService } from '../../employee-menagement/services/employees.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { BusinessTripDialogComponent } from '../business-trip-dialog/business-trip-dialog.component';
import { BusinessTripService } from '../services/business-trip.service';
import { RouterModule } from '@angular/router';
import { ConfrmationDialogComponent } from '../../utils/confrmation-dialog/confrmation-dialog.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-business-trip',
    imports: [
        MatExpansionModule,
        MatButtonModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        CommonModule,
        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        MatDialogModule,
        MatSortModule,
        RouterModule,
        TranslateModule,
    ],
    templateUrl: './business-trip.component.html',
    styleUrl: './business-trip.component.scss'
})
export class BusinessTripComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'employeeFullName',
    'startDate',
    'endDate',
    'travellingAllowance',
    'clientName',
    'projectName',
    'files',
  ];
  dataSource = new MatTableDataSource<BusinessTrip>();
  page: number = 1;
  total: number;
  isAdminUser: boolean = false;
  authGroups = groups;

  roleService = inject(RoleService);
  employeesService = inject(EmployeesService);
  businessTripService = inject(BusinessTripService);
  translateService = inject(TranslateService);
  dialog = inject(MatDialog);
  snackBar = inject(MatSnackBar);
  employees: Employee[] = [];

  ngOnInit(): void {
    // Check if user is in admin group
    this.isAdminUser = this.roleService.checkIfUserIsInGroup(
      this.authGroups.EofficeAdmin
    );

    //Get data for table
    this.getBusinessTripsForEmployee(this.isAdminUser);

    //Get List of employees and add actions column
    if (this.isAdminUser) {
      this.getListOfEmployees();
      this.displayedColumns.push('actions');
    }
  }

  getListOfEmployees(): void {
    this.employeesService.getEmployeesForSelect().subscribe((data: any) => {
      this.employees = data;
    });
  }

  addBusinessTrip(): void {
    this.dialog
      .open(BusinessTripDialogComponent, {
        data: {
          model: { id: 0, startDate: '', endDate: '', decription: '' },
          isAdminUser: this.isAdminUser,
          employees: this.employees,
        },
        width: '500px',
        maxHeight: '500px',
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.snackBar.open(
            this.translateService.instant('business_trip.created'),
            '',
            { panelClass: 'snackbar-success' }
          );
          this.getBusinessTripsForEmployee(this.isAdminUser);
        }
      });
  }

  editBusinessTrip(model: BusinessTrip): void {
    this.dialog
      .open(BusinessTripDialogComponent, {
        data: {
          model: model,
          isAdminUser: this.isAdminUser,
          employees: this.employees,
        },
        width: '500px',
        maxHeight: '500px',
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.snackBar.open(
            this.translateService.instant('business_trip.updated'),
            '',
            { panelClass: 'snackbar-success' }
          );
          this.getBusinessTripsForEmployee(this.isAdminUser);
        }
      });
  }

  deleteBusinessTrip(id: number): void {
    const dialogRef = this.dialog.open(ConfrmationDialogComponent);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.snackBar.open(
          this.translateService.instant('business_trip.deleted'),
          '',
          { panelClass: 'snackbar-success' }
        );
        this.businessTripService.delete(id).subscribe(() => {
          this.getBusinessTripsForEmployee(this.isAdminUser);
        });
      }
    });
  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private getBusinessTripsForEmployee(isAdminUser: boolean): void {
    if (isAdminUser) {
      this.businessTripService.getAllAdmin().subscribe((data: any) => {
        this.dataSource = new MatTableDataSource<BusinessTrip>(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.total = data.length;
      });
    } else {
      this.businessTripService.getAllForEmployee().subscribe((data: any) => {
        this.dataSource = new MatTableDataSource<BusinessTrip>(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.total = data.length;
      });
    }
  }
}
