import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TotalMonthlyWorkingHoursServiceService {

  http = inject(HttpClient);
  url = environment.apiEofficeConfig.endpoint + '/TotalMonthlyWorkingHours';

  getTotalMonthlyWorkingHoursData(totalMonthlyWorkingHours: any) {
    return this.http.post(this.url + '/GetAll', totalMonthlyWorkingHours);
  }
}
