import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'findSpecialOccasionDay',
})
export class FindSpecOccDayPipe implements PipeTransform {
  transform(specialOccasionDays: { id: number, description: string }[], id: any) {
    return specialOccasionDays.find(day => id && day.id === id)?.description;
  }
}