import { Component, inject, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpecialOccasionDayModel } from '../interfaces/specialOccasionDayModel';
import { SpecialOccasionDayService } from '../special-occasion-day.service';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-special-occasion-day-dialog',
    imports: [
        MatFormFieldModule,
        MatCardModule,
        MatIconModule,
        FormsModule,
        MatDividerModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatInputModule,
        TranslateModule,
    ],
    templateUrl: './special-occasion-day-dialog.component.html',
    styleUrl: './special-occasion-day-dialog.component.scss'
})
export class SpecialOccasionDayDialogComponent implements OnInit {
  dialogRef = inject(MatDialogRef<SpecialOccasionDayDialogComponent>);
  specialOccasionDayService = inject(SpecialOccasionDayService);
  translateService = inject(TranslateService);
  formBuilder = inject(FormBuilder);
  snackBar = inject(MatSnackBar);
  data = inject(MAT_DIALOG_DATA);

  model: SpecialOccasionDayModel;
  specialOccasionDaysForm = this.formBuilder.group({
    description: ['', Validators.required],
  });

  ngOnInit(): void {
    this.model = this.data.model;

    if (this.model.id !== 0) {
      this.specialOccasionDaysForm.patchValue({
        description: this.model.description,
      });
    }
  }

  onSubmit() {
    this.mapFormValuesToModel();
    if (this.model.id === 0) {
      this.createSpecialOccasionDay();
    } else {
      this.updateSpecialOccasionDay();
    }
  }

  updateSpecialOccasionDay(): void {
    this.specialOccasionDayService.update(this.model).subscribe({
      next: () => {
        this.snackBar.open(
          this.translateService.instant('special-occasion.updated'),
          '',
          {
            panelClass: 'snackbar-success',
          }
        );
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.snackBar.open(
          this.translateService.instant('general.error') + error,
          '',
          {
            panelClass: 'snackbar-error',
          }
        );
      },
    });
  }

  createSpecialOccasionDay(): void {
    this.specialOccasionDayService.create(this.model).subscribe({
      next: () => {
        this.snackBar.open(
          this.translateService.instant('special-occasion.created'),
          '',
          {
            panelClass: 'snackbar-success',
          }
        );
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.snackBar.open(
          this.translateService.instant('general.error') + error,
          '',
          {
            panelClass: 'snackbar-error',
          }
        );
      },
    });
  }

  mapFormValuesToModel() {
    this.model.description =
      this.specialOccasionDaysForm.value.description ?? '';
  }

  onClose() {
    this.dialogRef.close();
  }
}
