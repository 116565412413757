import { Component } from '@angular/core';

@Component({
    selector: 'app-bookstore-dialog',
    imports: [],
    templateUrl: './bookstore-dialog.component.html',
    styleUrl: './bookstore-dialog.component.scss'
})
export class BookstoreDialogComponent {

}
