<div class="container">
  <div class="container-header">
    <button
      mat-raised-button
      color="primary"
      class="table-button"
      (click)="addSeniority()"
    >
      {{ "seniority.add" | translate }}
    </button>
    <mat-form-field>
      <mat-label
        ><mat-icon>search</mat-icon
        >{{ "general.search" | translate }}</mat-label
      >
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="{{ 'general.search_placeholder' | translate }}"
        #input
      />
    </mat-form-field>
  </div>
  <div>
    <mat-card>
      <mat-card-header class="mat-card-header">
        <mat-card-title>{{ "seniority.title" | translate }}</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <div class="table-container">
          <table
            mat-table
            [dataSource]="dataSource"
            class="mat-elevation-z8"
            matSort
          >
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef>
                {{ "seniority.table.title" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.title }}</td>
            </ng-container>

            <ng-container matColumnDef="unitPrice">
              <th mat-header-cell *matHeaderCellDef>
                {{ "seniority.table.unitPrice" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.unitPrice }}
              </td>
            </ng-container>

            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>
                {{ "seniority.table.description" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.description }}
              </td>
            </ng-container>

            <ng-container matColumnDef="active">
              <th mat-header-cell *matHeaderCellDef>
                {{ "seniority.table.active" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                @if (element.active) {
                <mat-icon
                  class="icon-check"
                  aria-hidden="false"
                  aria-label="check icon"
                  fontIcon="check_circle"
                />
                } @else {
                <mat-icon
                  class="icon-cancel"
                  aria-hidden="false"
                  aria-label="cancel icon"
                  fontIcon="cancel"
                />
                }
              </td>
            </ng-container>

            <!-- Action column -->
            <ng-container matColumnDef="actions" stickyEnd>
              <th mat-header-cell *matHeaderCellDef aria-label="row actions">
                &nbsp;
              </th>
              <td mat-cell *matCellDef="let element">
                <button
                  mat-icon-button
                  (click)="editEntity(element)"
                  color="primary"
                  aria-label="edit row"
                >
                  <mat-icon>draw</mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="deleteEntity(element.id)"
                  color="primary"
                  aria-label="delete row"
                >
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">
                {{ "seniority.no_data" | translate }} "{{ input.value }}"
              </td>
            </tr>
          </table>
        </div>
        <mat-paginator
          [pageSizeOptions]="[10, 25]"
          showFirstLastButtons="true"
          aria-label="Select page of seniority"
        ></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
