import { Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { RoleService } from '../utils/role.service';

@Directive({
  selector: '[appShowIfInGroup]',
  standalone: true
})
export class ShowIfInGroupDirective {

  private hasView = false;
  //roleService = Inject(RoleService);

  // constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {

  //  }

  //  @Input() set appShowIfInGroup(group: string) {
  //   const condition = this.roleService.chekIfUserIsInGroup(group);
  //       if (condition && !this.hasView) {
  //         this.viewContainer.createEmbeddedView(this.templateRef);
  //         this.hasView = true;
  //       } else if (!condition && this.hasView){
  //         this.viewContainer.clear();
  //         this.hasView = false;
  //       }
  //     }

}
