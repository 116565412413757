<div class="modal-header">
    <h2 mat-dialog-title> @if(this.model.id == 0){
        {{'sick-leave.request' | translate}}
        }@else {
        {{'sick-leave.edit' | translate}}
        }</h2>
    <mat-icon aria-hidden="false" aria-label="pending icon" fontIcon="medication_liquid"></mat-icon>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
    <form [formGroup]="sickLeaveForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-label>{{ 'sick-leave.note' | translate}}</mat-label>
                <input matInput formControlName="note" placeholder="{{ 'sick-leave.note' | translate}}" required>
            </mat-form-field>

            <!-- Admin user can make request for other users -->
            @if (this.isAdminUser) {
            <mat-form-field>
                <mat-label>{{'sick-leave.employee_name' | translate}}</mat-label>
                <mat-select formControlName="employeeId" placeholder="{{'sick-leave.employee_name' | translate}}"
                    required>
                    @for(employee of employees; track employee.id){
                    <mat-option [value]="employee.id">
                        {{employee.fullName}}
                    </mat-option>
                    }
                </mat-select>
            </mat-form-field>
            }

            <mat-form-field>
                <mat-label>{{'sick-leave.start_date_placeholder' | translate}}</mat-label>
                <input matInput formControlName="startDate"
                    placeholder="{{'sick-leave.start_date_placeholder' | translate}}" [matDatepicker]="picker" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>


            <mat-form-field>
                <mat-label>{{'sick-leave.end_date_placeholder' | translate}}</mat-label>
                <input matInput formControlName="endDate"
                    placeholder="{{'sick-leave.end_date_placeholder' | translate}}" [matDatepicker]="picker2" required>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'sick-leave.select_type_of_leave' | translate}}</mat-label>
                <mat-select formControlName="type" placeholder="{{'sick-leave.select_type_of_leave' | translate}}"
                    required>
                    <mat-option value="Standard">
                        {{ 'sick-leave.standard_type' | translate}}
                    </mat-option>
                    <mat-option value="Pregnancy">
                        {{ 'sick-leave.pregnancy_type' | translate}}
                    </mat-option>
                    <mat-option value="Maternity">
                        {{ 'sick-leave.maternity_type' | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close tabindex="-1" (click)="onClose()">{{ 'buttons.cancel' | translate}}</button>
    <button mat-raised-button color="primary" type="submit" (click)="onSubmit()">{{'buttons.save' | translate}}</button>
</mat-dialog-actions>