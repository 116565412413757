
<div class="modal-header">
    <h2 mat-dialog-title> @if(this.model.id == 0){
        {{'non_working_day.add' | translate}}
        }@else {
        {{'non_working_day.edit' | translate}}
        }</h2>
    <mat-icon aria-hidden="false" aria-label="event icon" fontIcon="event"></mat-icon>
  </div>
<mat-divider></mat-divider>
<mat-dialog-content>
    <form [formGroup]="nonWorkingDayForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
        <mat-form-field appearance="outline">
            <mat-label>  {{'non_working_day.title' | translate}}</mat-label>
            <input matInput formControlName="title" placeholder="{{'non_working_day.title' | translate}}" required>
        </mat-form-field>

        <mat-form-field>
            <mat-label>  {{'non_working_day.start_date' | translate}}</mat-label>
            <input matInput formControlName="startDate" placeholder="{{'non_working_day.start_date_placeholder' | translate}}" [matDatepicker]="picker" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
           <mat-label>  {{'non_working_day.end_date' | translate}}</mat-label>
            <input matInput formControlName="endDate" placeholder="{{'non_working_day.end_date_placeholder' | translate}}" [matDatepicker]="picker2" required>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end"> 
    <button mat-button mat-dialog-close tabindex="-1" (click)="onClose()">  {{'buttons.cancel' | translate}}</button>
    <button mat-raised-button color="primary" type="submit" (click)="onSubmit()">  {{'buttons.save' | translate}}</button>
</mat-dialog-actions>