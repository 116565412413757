<mat-dialog-content>
  <div class="dinner-preview">
    <mat-card class="mat-elevation-z3">
      <mat-card-content>
        <h4>Dinner email preview</h4>
        <p>{{ dinner.partOfEmailText }}</p>
        <p>
          {{ dinner.startDate | date : "dd.MM.yyyy" }} ćemo organizovati večeru
          u restoranu {{ dinner.placeName }} u
          {{ dinner.startDate | date : "shortTime" }}.
        </p>
        <p>
          Molimo vas da do
          {{ dinner.deadlineForCheckIn | date : "dd.MM.yyyy HH:mm" }} označite
          vaše prisustvo na <a href="{{ dinnersLink }}">linku</a>.
        </p>
        <p>Hvala.</p>
        <p>Pozdrav</p>
      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>
