<mat-divider></mat-divider>
<div class="container">
  <h2>{{ "employee.details" | translate }}</h2>
  <form
    [formGroup]="employeeForm"
    (ngSubmit)="onSubmit()"
    class="form-container"
  >
    <div class="line-container">
      <span class="line-text">{{
        "employee.general_information" | translate
      }}</span>
    </div>

    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.firstName" | translate }}</mat-label>
        <input
          matInput
          formControlName="firstName"
          placeholder="{{ 'employee.name' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.lastName" | translate }}</mat-label>
        <input
          matInput
          formControlName="lastName"
          placeholder="{{ 'employee.name' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "employee.date_of_birth" | translate }}</mat-label>
        <input
          matInput
          formControlName="dateOfBirth"
          placeholder="{{ 'employee.choose_date_of_birth' | translate }}"
          [matDatepicker]="picker1"
          required
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.jmbg" | translate }}</mat-label>
        <input
          matInput
          minlength="13"
          mask="0000000000000"
          formControlName="jmbg"
          placeholder="{{ 'employee.jmbg' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.email" | translate }}</mat-label>
        <input
          type="email"
          matInput
          formControlName="email"
          placeholder="{{ 'employee.email' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.phone_number" | translate }}</mat-label>
        <input
          matInput
          formControlName="phoneNumber"
          placeholder="{{ 'employee.phone_number' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.belgrade_address" | translate }}</mat-label>
        <input
          matInput
          formControlName="belgradeAddress"
          placeholder="{{ 'employee.belgrade_address' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.address" | translate }}</mat-label>
        <input
          matInput
          formControlName="address"
          placeholder="{{ 'employee.address' | translate }}"
          required
        />
      </mat-form-field>
    </div>

    <div class="line-container">
      <span class="line-text">{{ "employee.employment" | translate }}</span>
    </div>

    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.position" | translate }}</mat-label>
        <input
          matInput
          formControlName="position"
          placeholder="{{ 'employee.position' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "employee.date_of_employment" | translate }}</mat-label>
        <input
          matInput
          formControlName="dateOfEmployment"
          placeholder="{{ 'employee.choose_date_of_employment' | translate }}"
          [matDatepicker]="picker"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.years_of_service" | translate }}</mat-label>
        <input
          matInput
          formControlName="yearsOfService"
          placeholder="{{ 'employee.years_of_service' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.seniority" | translate }}</mat-label>
        <mat-select
          formControlName="seniorityId"
          placeholder="{{ 'employee.select_seniority' | translate }}"
          required
        >
          @for(seniority of seniorities; track seniority){
          <mat-option [value]="seniority.id">{{ seniority.title }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <div class="vacation-block">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "employee.total_vacation_days" | translate
          }}</mat-label>
          <input
            matInput
            formControlName="totalFreeDays"
            placeholder="{{ 'employee.total_vacation_days' | translate }}"
            required
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{
            "employee.total_vacation_days_last_year" | translate
          }}</mat-label>
          <input
            matInput
            formControlName="totalFreeDaysLastYear"
            placeholder="{{
              'employee.total_vacation_days_last_year' | translate
            }}"
            required
          />
        </mat-form-field>

        <button
          mat-raised-button
          color="primary"
          matTooltip="{{ 'employee.calculate_days_tooltip' | translate }}"
          (click)="getFreeDays()"
        >
          {{ "employee.calculate_days" | translate }}
        </button>
      </div>
    </div>

    <div class="line-container">
      <span class="line-text">{{ "employee.education" | translate }}</span>
    </div>

    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.level_of_education" | translate }}</mat-label>
        <input
          matInput
          formControlName="levelOfEducation"
          placeholder="{{ 'employee.level_of_education' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.degree" | translate }}</mat-label>
        <input
          matInput
          formControlName="degree"
          placeholder="{{ 'employee.degree' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.school" | translate }}</mat-label>
        <input
          matInput
          formControlName="school"
          placeholder="{{ 'employee.school' | translate }}"
          required
        />
      </mat-form-field>
    </div>

    <div class="line-container">
      <span class="line-text">{{ "employee.insurance" | translate }}</span>
    </div>

    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.lbo" | translate }}</mat-label>
        <input
          matInput
          formControlName="lbo"
          placeholder="{{ 'employee.lbo' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.emergency_contacts" | translate }}</mat-label>
        <input
          matInput
          formControlName="emergencyContacts"
          placeholder="{{ 'employee.emergency_contacts' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.contact_phone" | translate }}</mat-label>
        <input
          matInput
          formControlName="contactPhone"
          placeholder="{{ 'employee.contact_phone' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{
          "employee.insured_family_members" | translate
        }}</mat-label>
        <input
          matInput
          formControlName="insuredFamilyMembers"
          placeholder="{{ 'employee.insured_family_members' | translate }}"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.children_names" | translate }}</mat-label>
        <input
          matInput
          formControlName="kids"
          placeholder="{{ 'employee.children_names' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.number_of_children" | translate }}</mat-label>
        <input
          matInput
          formControlName="numberOfKids"
          placeholder="{{ 'employee.number_of_children' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{
          "employee.public_transportation_zone" | translate
        }}</mat-label>
        <input
          matInput
          formControlName="publicTransportationZone"
          placeholder="{{ 'employee.public_transportation_zone' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.ticket_price" | translate }}</mat-label>
        <input
          matInput
          type="text"
          mask="0*.00"
          formControlName="ticketPrice"
          placeholder="{{ 'employee.ticket_price' | translate }}"
          required
        />
      </mat-form-field>
    </div>

    <div class="line-container">
      <span class="line-text">{{
        "employee.contract_information" | translate
      }}</span>
    </div>

    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>{{ "employee.select_contract_type" | translate }}</mat-label>
        <mat-select
          formControlName="typeOfContract"
          #typeOfContract
          placeholder="{{ 'employee.contract_type' | translate }}"
          required
        >
          <mat-option value="Agency">{{
            "employee.agency" | translate
          }}</mat-option>
          <mat-option value="Permanent">{{
            "employee.permanent" | translate
          }}</mat-option>
          <mat-option value="Temporary">{{
            "employee.temporary" | translate
          }}</mat-option>
          <mat-option value="Intern">{{
            "employee.intern" | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-checkbox formControlName="active" name="active" required>{{
        "employee.is_active" | translate
      }}</mat-checkbox>
    </div>

    <div class="form-group" *ngIf="typeOfContract.value === 'Agency'">
      <mat-form-field>
        <input
          matInput
          formControlName="dateOfPassingToAgency"
          placeholder="{{ 'employee.date_of_passing_to_agency' | translate }}"
          [matDatepicker]="picker2"
          required
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>
    </div>
    <mat-form-field>
      <input
        matInput
        formControlName="dateOfContractTermination"
        placeholder="{{ 'employee.date_of_contract_termination' | translate }}"
        [matDatepicker]="picker3"
        required
      />
      <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
      <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>

    <div class="line-container">
      <span class="line-text">{{ "employee.agency_refunds" | translate }}</span>
    </div>

    <div *ngIf="typeOfContract.value === 'Agency'">
      <mat-label>{{ "employee.month_and_year" | translate }}</mat-label>
      <mat-hint>{{ "employee.month_year_format" | translate }}</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker
        #dp
        startView="multi-year"
        (monthSelected)="getMonthlyExpenses($event, dp)"
        panelClass="month-picker"
      ></mat-datepicker>

      <mat-form-field appearance="outline">
        <input
          matInput
          type="text"
          [(ngModel)]="model.monthlyExpenses.insurance"
          [ngModelOptions]="{ standalone: true }"
          placeholder="{{ 'employee.insurance' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <input
          matInput
          type="number"
          [(ngModel)]="model.monthlyExpenses.planeTickets"
          [ngModelOptions]="{ standalone: true }"
          placeholder="{{ 'employee.plane_tickets' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <input
          matInput
          type="number"
          [(ngModel)]="model.monthlyExpenses.travellingAllowance"
          [ngModelOptions]="{ standalone: true }"
          placeholder="{{ 'employee.travelling_allowance' | translate }}"
          required
        />
        <mat-icon matSuffix>euro_symbol</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <input
          matInput
          type="number"
          [(ngModel)]="model.monthlyExpenses.other"
          [ngModelOptions]="{ standalone: true }"
          placeholder="{{ 'employee.other' | translate }}"
          required
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <input
          matInput
          type="number"
          [(ngModel)]="model.monthlyExpenses.lunch"
          [ngModelOptions]="{ standalone: true }"
          placeholder="{{ 'employee.lunch' | translate }}"
          required
        />
      </mat-form-field>
    </div>
  </form>
</div>

<div class="action-buttons">
  <button mat-raised-button tabindex="-1" (click)="onClose()">
    {{ "buttons.cancel" | translate }}
  </button>
  <button mat-raised-button color="primary" type="submit" (click)="onSubmit()">
    {{ "buttons.save" | translate }}
  </button>
</div>
