import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Vacation } from '../interfaces/vacation';
import { ApproveDto } from '../interfaces/approveDto';
import { RemainingVacationEmployeeDays } from '../interfaces/remainingVacationEmployeeDays';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VacationService {
  http = inject(HttpClient);
  url = environment.apiEofficeConfig.endpoint + '/Vacations';

  constructor() {}

  getAllForEmployee() {
    return this.http.get(this.url + '/GetAllForEmployee/');
  }

  getAllAdmin(activeOnly: boolean) {
    return this.http.get(this.url + '/GetAll?activeOnly=' + activeOnly);
  }

  delete(id: number, employeeId: number) {
    return this.http.delete(this.url + '/' + id + '/' + employeeId);
  }

  update(model: Vacation) {
    return this.http.put(this.url, model);
  }

  create(model: Vacation) {
    return this.http.post(this.url, model);
  }

  changeState(model: ApproveDto) {
    return this.http.post(this.url + '/ChangeState', model);
  }

  getVacationDaysForEmployee(): Observable<RemainingVacationEmployeeDays> {
    return this.http.get<RemainingVacationEmployeeDays>(
      this.url + '/GetVacationDaysForEmployee'
    );
  }

  export(
    startDate: string,
    endDate: string,
    employeeId: number
  ): Observable<Blob> {
    return this.http.get(
      this.url +
        '/ExportToExcel?startDate=' +
        startDate +
        '&endDate=' +
        endDate +
        '&employeeId=' +
        employeeId,
      { responseType: 'blob' }
    );
  }
}
