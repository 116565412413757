<div class="container">
  <div class="container-header">
    <button mat-raised-button color="primary" (click)="addProject()">
      {{ "project.add" | translate }}
    </button>
    <span class="spacer"></span>
    <mat-form-field>
      <mat-label
        ><mat-icon>search</mat-icon
        >{{ "general.search" | translate }}</mat-label
      >
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="{{ 'general.search_placeholder' | translate }}"
        #input
      />
    </mat-form-field>
  </div>
  <div>
    <mat-card>
      <mat-card-header class="mat-card-header">
        <mat-card-title>
          <h4>{{ "project.list" | translate }}</h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="table-container">
          <table
            mat-table
            [dataSource]="this.dataSource"
            class="mat-elevation-z8"
            matSort
          >
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "project.name" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>

            <ng-container matColumnDef="clientName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "project.client" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.clientName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="active">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "project.active" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                @if(element.active) {
                <mat-icon
                  class="icon-check"
                  aria-hidden="false"
                  aria-label="check icon"
                  fontIcon="check_circle"
                ></mat-icon>
                } @else {
                <mat-icon
                  class="icon-cancel"
                  aria-hidden="false"
                  aria-label="close icon"
                  fontIcon="cancel"
                ></mat-icon>
                }
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>
                {{ "project.actions" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <button
                  mat-button
                  color="primary"
                  (click)="editEntity(element)"
                  disabled="{{ element.approved != null }}"
                >
                  <mat-icon>draw</mat-icon>
                </button>
                <button
                  mat-button
                  color="warn"
                  (click)="deleteEntity(element.id)"
                  disabled="{{ element.approved != null }}"
                >
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">
                {{ "project.no_data" | translate }} "{{ input.value }}"
              </td>
            </tr>
          </table>
        </div>
        <mat-paginator
          [pageSizeOptions]="[10, 25]"
          aria-label="Select page of projects"
          showFirstLastButtons="true"
        ></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
