import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { WorkFromHome } from './interfaces/workfromhome';
import { approveModel } from '../shared/interfaces/approveModel';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class WorkFromHomeService {
  url = environment.apiEofficeConfig.endpoint + '/WorkFromHome';

  constructor(private http: HttpClient) {}

  getAllForEmployee() {
    return this.http.get(this.url + '/GetAllForEmployee/');
  }

  getAllAdmin(activeOnly: boolean) {
    return this.http.get(this.url + '/GetAll?activeOnly=' + activeOnly);
  }

  delete(id: number) {
    return this.http.delete(this.url + '/' + id);
  }

  update(model: WorkFromHome) {
    return this.http.put(this.url, model);
  }

  create(model: WorkFromHome) {
    return this.http.post(this.url, model);
  }

  getSickLeave(id: number) {
    return this.http.get(this.url + '/' + id);
  }

  changeStateOfWorkFromHomeRequest(request: approveModel) {
    return this.http.post(this.url + '/ChangeState', request);
  }

  export(
    startDate: string,
    endDate: string,
    employeeId: number
  ): Observable<Blob> {
    return this.http.get(
      this.url +
        '/ExportToExcel?startDate=' +
        startDate +
        '&endDate=' +
        endDate +
        '&employeeId=' +
        employeeId,
      { responseType: 'blob' }
    );
  }
}
