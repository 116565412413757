<div class="modal-header">
  <h2 mat-dialog-title>
    @if (this.model.id == 0) {
    {{ "project.add" | translate }}
    } @else {
    {{ "project.edit" | translate }}
    }
  </h2>
  <mat-icon
    aria-hidden="false"
    aria-label="pending icon"
    fontIcon="add_business"
  ></mat-icon>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <form [formGroup]="projectForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>{{ "project.enter_name" | translate }}</mat-label>
        <input
          matInput
          formControlName="name"
          placeholder="{{ 'project.name_placeholder' | translate }}"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "project.select_client" | translate }}</mat-label>
        <mat-select
          formControlName="clientId"
          placeholder="{{ 'project.choose_client' | translate }}"
        >
          <mat-option [value]="1">BetaBit</mat-option>
          <mat-option [value]="2">Enigmatry</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-checkbox formControlName="active">{{
        "project.active" | translate
      }}</mat-checkbox>
    </div>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close tabindex="-1" (click)="onClose()">
    {{ "buttons.cancel" | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    type="submit"
    [disabled]="!this.projectForm.valid"
    (click)="onSubmit()"
  >
    {{ "buttons.save" | translate }}
  </button>
</mat-dialog-actions>
