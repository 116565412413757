import { Component, inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TeambuildingService } from '../teambuilding.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Employee } from '../../employee-menagement/interfaces/employee';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeesService } from '../../employee-menagement/services/employees.service';
import { TeamBuilding } from '../interfaces/teamBuilding';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { TeambuildingEmailPreviewComponent } from '../teambuilding-email-preview/teambuilding-email-preview.component';

@Component({
    selector: 'app-teambuilding-dialog',
    imports: [
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatFormFieldModule,
        FormsModule,
        TranslateModule,
        MatListModule,
        MatExpansionModule,
        MatCardModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatButtonModule,
    ],
    templateUrl: './teambuilding-dialog.component.html',
    styleUrl: './teambuilding-dialog.component.scss'
})
export class TeambuildingDialogComponent implements OnInit {
  //inject services
  teamBuildingService = inject(TeambuildingService);
  employeesService = inject(EmployeesService);
  snack = inject(MatSnackBar);
  translateService = inject(TranslateService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  dialog = inject(MatDialog);

  //define form
  teambuildingForm = new FormGroup({
    title: new FormControl('', Validators.required),
    placeName: new FormControl('', Validators.required),
    placeAddress: new FormControl('', Validators.required),
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
    employeesOnEvent: new FormControl(),
    emailType: new FormControl('', Validators.required),
    deadlineForCheckIn: new FormControl('', Validators.required),
  });

  //define variables
  employees: Employee[] = [];
  id: number;

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.employeesService.getEmployeesForSelect().subscribe((data: any) => {
      this.employees = data;
    });

    if (this.id) {
      this.teamBuildingService.get(this.id).subscribe((data: any) => {
        this.teambuildingForm.patchValue(data);
      });
    }
  }

  navigateToTeamBuidlingList() {
    this.router.navigate(['/teambuilding']);
  }

  saveData(): void {
    let teamBuildingModel = {} as TeamBuilding;
    if (this.teambuildingForm.valid) {
      teamBuildingModel = this.teambuildingForm.value as TeamBuilding;
      teamBuildingModel.numberOfParticipants =
        teamBuildingModel?.employeesOnEvent?.length;
    }

    this.teamBuildingService.create(teamBuildingModel).subscribe({
      next: () => {
        this.snack.open(
          this.translateService.instant('teambuilding.created'),
          '',
          { panelClass: 'snackbar-success' }
        );
        this.navigateToTeamBuidlingList();
      },
      error: () => {
        this.snack.open(
          this.translateService.instant('error_messages.INVALID_DATA'),
          '',
          { panelClass: ['snackbar-error'] }
        );
      },
    });
  }

  updateData(): void {
    let teamBuildingModel = {} as TeamBuilding;
    if (this.teambuildingForm.valid) {
      teamBuildingModel = this.teambuildingForm.value as TeamBuilding;
      teamBuildingModel.numberOfParticipants =
        teamBuildingModel?.employeesOnEvent?.length;
      teamBuildingModel.id = this.id;
    }

    this.teamBuildingService.update(teamBuildingModel).subscribe({
      next: () => {
        this.snack.open(
          this.translateService.instant('teambuilding.updated'),
          '',
          { panelClass: 'snackbar-success' }
        );
        this.navigateToTeamBuidlingList();
      },
      error: () => {
        this.snack.open(
          this.translateService.instant('error_messages.INVALID_DATA'),
          '',
          { panelClass: 'snackbar-error' }
        );
      },
    });
  }

  showTeamBuildingEmailPreview(teamBuilding: any) {
    this.dialog.open(TeambuildingEmailPreviewComponent, {
      data: { teamBuilding: teamBuilding, id: this.id },
      maxWidth: '600px',
      maxHeight: '600px',
      disableClose: false,
    });
  }
}
