<div class="container">
  <div class="container-header">
    <form [formGroup]="tmwhForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "total_mm_working_hh.select_client" | translate
          }}</mat-label>
          <mat-select
            formControlName="clientId"
            placeholder="{{ 'total_mm_working_hh.choose_client' | translate }}"
          >
            <mat-option [value]="1">BetaBit</mat-option>
            <mat-option [value]="2">Enigmatry</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{
            "total_mm_working_hh.month_year" | translate
          }}</mat-label>
          <input matInput [matDatepicker]="dp" formControlName="yearMonth" />
          <mat-hint>{{
            "total_mm_working_hh.month_year_hint" | translate
          }}</mat-hint>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="dp"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #dp
            startView="multi-year"
            (monthSelected)="setMonthAndYear($event, dp)"
            panelClass="example-month-picker"
          >
          </mat-datepicker>
        </mat-form-field>
      </div>
    </form>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      (click)="onSubmit()"
    >
      {{ "total_mm_working_hh.search" | translate }}
    </button>
    <span class="spacer"></span>
  </div>

  <div>
    <mat-card>
      <mat-card-header class="mat-card-header">
        <mat-card-title>
          <h4>{{ "total_mm_working_hh.total_list" | translate }}</h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="table-container">
          <table
            mat-table
            [dataSource]="this.dataSource"
            class="mat-elevation-z8"
            matSort
          >
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "total_mm_working_hh.employee" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.description }}
              </td>
            </ng-container>

            <ng-container matColumnDef="project">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "total_mm_working_hh.project" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.project }}</td>
            </ng-container>

            <ng-container matColumnDef="workingHours">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "total_mm_working_hh.working_hours" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.month }} {{ element.year }}
              </td>
            </ng-container>

            <ng-container matColumnDef="calc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "total_mm_working_hh.working_hours" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-form-field>
                  <input
                    type="text"
                    mask="0*.00"
                    matInput
                    [(ngModel)]="element.hours"
                    placeholder="{{
                      'total_mm_working_hh.hours_placeholder' | translate
                    }}"
                    #hours="ngModel"
                    name="hours"
                  />
                </mat-form-field>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <mat-paginator
          [pageSizeOptions]="[10, 25]"
          showFirstLastButtons="true "
          aria-label="Select page of work from home rquests"
        ></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
