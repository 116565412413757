import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-home-tile',
    imports: [
        MatCardModule,
        MatIconModule,
        TranslateModule
    ],
    templateUrl: './home-tile.component.html',
    styleUrl: './home-tile.component.scss'
})
export class HomeTileComponent {

  @Input() dataForDisplay = '';
  @Input() tileIcon = '';
  @Input() tielHeaderText = '';
  

}
