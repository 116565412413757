import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private renderer: Renderer2;
  private currentTheme = 'light-theme';

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    const savedTheme = localStorage.getItem('theme') || 'light-theme';
    this.setTheme(savedTheme);
  }

  toggleTheme() {
    const newTheme =
      this.currentTheme === 'light-theme' ? 'dark-theme' : 'light-theme';
    this.setTheme(newTheme);
  }

  setTheme(theme: string) {
    if (this.currentTheme) {
      this.renderer.removeClass(document.documentElement, this.currentTheme);
    }
    this.renderer.addClass(document.documentElement, theme);
    localStorage.setItem('theme', theme);
    this.currentTheme = theme;
  }

  getCurrentTheme() {
    return this.currentTheme;
  }
}
