import { Component, ContentChild, inject, Input } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { LoadingService } from '../loading.service';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-loading-bar',
    imports: [
        MatProgressBarModule,
        CommonModule
    ],
    templateUrl: './loading-bar.component.html',
    styleUrl: './loading-bar.component.scss'
})
export class LoadingBarComponent {
  loading$: Observable<boolean>;

  @Input()
  detectRouteTransitions = false;
  loadingService =inject(LoadingService);
  router = inject(Router);

  ngOnInit() {

    this.loading$ = this.loadingService.loading$;

    if (this.detectRouteTransitions) {
      this.router.events
        .pipe(
          tap((event) => {
            if (event instanceof RouteConfigLoadStart) {
              this.loadingService.loadingOn();
            } else if (event instanceof RouteConfigLoadEnd) {
              this.loadingService.loadingOff();
            }
          })
        )
        .subscribe();
    }
  }
}

