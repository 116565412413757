<div class="content">
  <app-home-tile class="home-tile-item" [dataForDisplay]="landingPageData.numberOfVacationDayRequests+''"
    [tileIcon]="'card_travel'" [tielHeaderText]="'tiles-content.vacation_day_requests'" [routerLink]="['/vacations']"></app-home-tile>
  <app-home-tile class="home-tile-item" [dataForDisplay]="landingPageData.pendingEventInvites+''"
    [tileIcon]="'content_copy'" [tielHeaderText]="'tiles-content.event_invites'"></app-home-tile>
  <app-home-tile class="home-tile-item" [dataForDisplay]="landingPageData.pendingWorkFromHomeRequests+''"
    [tileIcon]="'home'" [tielHeaderText]="'tiles-content.work_from_home_requests'" [routerLink]="['/work-from-home']"></app-home-tile>
  <app-home-tile class="home-tile-item" [dataForDisplay]="landingPageData.pendingSickLeaveRequests+''"
    [tileIcon]="'local_hospital'" [tielHeaderText]="'tiles-content.sick_leave_requests'" [routerLink]="['/sick-leaves']"></app-home-tile>
  <app-home-tile class="home-tile-item"
    dataForDisplay="{{landingPageData.numberOfDaysInTheOffice}}/{{landingPageData.numberOfWorkingDaysInTheMonth}}"
    [tileIcon]="'beenhere'" [tielHeaderText]="'tiles-content.days_at_the_office'"></app-home-tile>
</div>