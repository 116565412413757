import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TeamBuilding } from '../interfaces/teamBuilding';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-teambuilding-email-preview',
    imports: [
        MatCardModule,
        MatDialogModule,
        CommonModule,
        TranslateModule
    ],
    templateUrl: './teambuilding-email-preview.component.html',
    styleUrl: './teambuilding-email-preview.component.scss'
})
export class TeambuildingEmailPreviewComponent implements OnInit {


  id: number;
  teamBuilding: TeamBuilding;
  teamBuildingsLink: string;
  
  data = inject(MAT_DIALOG_DATA)
  

  ngOnInit(): void {

    this.teamBuilding = this.data.teamBuilding;
    this.id = this.teamBuilding.id;
    this.teamBuildingsLink = window.location.origin + '/teambuilding/'
  }

  showEmailEditText(): boolean {
    return this.id !== undefined;
  }
}
