<div class="container">
  <div class="container-header">
    <form [formGroup]="tmwhForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <mat-form-field appearance="outline">
          <mat-label>{{
            "invoice.final_table.select_client" | translate
          }}</mat-label>
          <mat-select
            formControlName="clientId"
            placeholder="{{ 'invoice.final_table.choose_client' | translate }}"
          >
            <mat-option [value]="1">BetaBit</mat-option>
            <mat-option [value]="2">Enigmatry</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{
            "invoice.final_table.month_year" | translate
          }}</mat-label>
          <input matInput [matDatepicker]="dp" formControlName="yearMonth" />
          <mat-hint>{{
            "invoice.final_table.month_year_hint" | translate
          }}</mat-hint>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="dp"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #dp
            startView="multi-year"
            (monthSelected)="setMonthAndYear($event, dp)"
            panelClass="example-month-picker"
          >
          </mat-datepicker>
        </mat-form-field>
      </div>
    </form>
    <span class="spacer"></span>

    <button
      mat-raised-button
      color="primary"
      type="submit"
      (click)="onSubmit()"
    >
      {{ "invoice.final_table.search" | translate }}
    </button>
  </div>

  <div>
    <mat-card>
      <mat-card-header class="mat-card-header">
        <mat-card-title>
          <h4>{{ "invoice.final_table.final_list" | translate }}</h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="table-container">
          <table
            mat-table
            [dataSource]="this.dataSource"
            class="mat-elevation-z8"
            multiTemplateDataRows
          >
            <ng-container matColumnDef="nr">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "invoice.final_table.nr" | translate }}
              </th>
              <td mat-cell *matCellDef="let element; let i = index">
                {{ i + 1 }}
              </td>
            </ng-container>

            <ng-container matColumnDef="project">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "invoice.final_table.project" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.project }}</td>
            </ng-container>

            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "invoice.final_table.description" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.description }}
              </td>
            </ng-container>

            <ng-container matColumnDef="seniority">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "invoice.final_table.seniority" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.seniority }}
              </td>
            </ng-container>

            <ng-container matColumnDef="unit">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "invoice.final_table.unit" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.unit }}</td>
            </ng-container>

            <ng-container matColumnDef="unitPrice">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "invoice.final_table.unit_price" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.unitPrice }}
              </td>
            </ng-container>

            <ng-container matColumnDef="hours">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "invoice.final_table.hours" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.hours }}</td>
            </ng-container>

            <ng-container matColumnDef="qty">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "invoice.final_table.qty" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.hours }}</td>
            </ng-container>

            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "invoice.final_table.price" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.hours * element.unitPrice }}
              </td>
            </ng-container>

            <ng-container matColumnDef="taxProc">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "invoice.final_table.tax_percentage" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.taxInPercentage }}
              </td>
            </ng-container>

            <ng-container matColumnDef="taxEur">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "invoice.final_table.tax_eur" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.taxEur }}</td>
            </ng-container>

            <ng-container matColumnDef="total">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "invoice.final_table.total" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.hours * element.unitPrice }}
              </td>
            </ng-container>

            <ng-container matColumnDef="vacationDays">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "invoice.final_table.vacation_days" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.plannedVacationDays }}
              </td>
            </ng-container>

            <ng-container matColumnDef="sicknessDays">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "invoice.final_table.sickness_days" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.sicknessLeaveDays }}
              </td>
            </ng-container>

            <!-- Duplicated row with total -->
            @if ( invoiceFinalData && invoiceFinalData.finalItems &&
            invoiceFinalData.finalItems.length != 0) {

            <ng-container matColumnDef="description1">
              <td mat-cell *matCellDef="let element">
                {{ "invoice.final_table.total_label" | translate }}
              </td>
            </ng-container>

            <ng-container matColumnDef="hours1">
              <td mat-cell *matCellDef="let element">
                {{ finalInvoiceTotals.totalHours | number : "1.2-2" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="qty1">
              <td mat-cell *matCellDef="let element">
                {{ finalInvoiceTotals.totalQty | number : "1.2-2" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="price1">
              <td mat-cell *matCellDef="let element">
                {{ finalInvoiceTotals.totalPrice | number : "1.2-2" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="taxProc1">
              <td mat-cell *matCellDef="let element">
                {{ finalInvoiceTotals.totalTax }}
              </td>
            </ng-container>

            <ng-container matColumnDef="taxEur1">
              <td mat-cell *matCellDef="let element">
                {{ finalInvoiceTotals.totalTaxPercentage }}
              </td>
            </ng-container>

            <ng-container matColumnDef="total1">
              <td mat-cell *matCellDef="let element">
                {{ finalInvoiceTotals.totalEur | number : "1.2-2" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="vacationDays1">
              <td mat-cell *matCellDef="let element">
                {{ element.totalVacationDays }}
              </td>
            </ng-container>

            <ng-container matColumnDef="sicknessDays1">
              <td
                mat-cell
                *matCellDef="let element"
                [attr.colspan]="displayedColumns.length"
              >
                {{ element.totalSickLeaveDays }}
              </td>
            </ng-container>

            <tr
              mat-row
              *matRowDef="
                let row;
                columns: [
                  'description1',
                  'hours1',
                  'qty1',
                  'price1',
                  'taxProc1',
                  'taxEur1',
                  'total1',
                  'vacationDays1',
                  'sicknessDays1'
                ]
              "
            ></tr>
            }

            <!-- If there is no final items -->
            @if (invoiceFinalData && (!invoiceFinalData.finalItems ||
            invoiceFinalData.finalItems.length == 0)) {
            <ng-container matColumnDef="secondary">
              <td
                mat-cell
                [attr.colspan]="displayedColumns.length"
                *matCellDef="let element"
              >
                <p>
                  {{ "invoice.final_table.no_data" | translate }}
                  <a [routerLink]="['/total-monthly-working-hours']">{{
                    "invoice.final_table.enter_hours_link" | translate
                  }}</a>
                  {{ "invoice.final_table.see_final_data" | translate }}
                </p>
              </td>
            </ng-container>

            <tr mat-row *matRowDef="let row; columns: ['secondary']"></tr>
            }

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <mat-paginator
          [pageSizeOptions]="[10, 25]"
          showFirstLastButtons="true "
          aria-label="Select page of work from home rquests"
        ></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
