import { Component, inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ResourceAllocationService } from '../services/resource-allocation.service';
import { ProjectService } from '../../projects/interfaces/project.service';
import { EmployeesService } from '../../employee-menagement/services/employees.service';
import { Project } from '../../projects/interfaces/project';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmployeeForSelect } from '../../employee-menagement/interfaces/employeeForSelect';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { resourceAllocationModel } from '../interfaces/resourceAllocationModel';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-resource-allocation-form',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatDivider,
    MatIconModule,
    MatSelectModule,
    TranslateModule,
  ],
  templateUrl: './resource-allocation-form.component.html',
  styleUrl: './resource-allocation-form.component.scss',
})
export class ResourceAllocationFormComponent implements OnInit {
  //inject services
  resourceAllocationService = inject(ResourceAllocationService);
  projectService = inject(ProjectService);
  employeeService = inject(EmployeesService);
  snackBar = inject(MatSnackBar);
  router = inject(Router);
  route = inject(ActivatedRoute);
  formBuilder = inject(FormBuilder);
  translateService = inject(TranslateService);

  //variables
  projects: Project[];
  employees: EmployeeForSelect[];
  model: resourceAllocationModel = {} as resourceAllocationModel;
  resourceAllocationId: string;
  resourceAllocationForm = this.formBuilder.group({
    employeeId: [0, Validators.required],
    clientId: [0, Validators.required],
    projectId: ['', Validators.required],
    startDate: [new Date(), Validators.required],
    endDate: [new Date(), Validators.required],
    projectContractNumber: ['', Validators.required],
    currentProjectIds: [[0], Validators.required],
    futureProjectId: [0, Validators.required],
    betabitEntity: [''],
    regionalManager: [''],
  });

  ngOnInit(): void {
    this.resourceAllocationId = this.route.snapshot.params['id'];
    this.getEmployeesForSelect();

    if (this.resourceAllocationId !== '') {
      this.resourceAllocationService.get(+this.resourceAllocationId).subscribe({
        next: (res: any) => {
          this.model = res;
          this.resourceAllocationForm.patchValue({
            employeeId: this.model.employeeId,
            clientId: this.model.clientId,
            projectId: this.model.projectContractNumber,
            startDate: this.model.startDate,
            endDate: this.model.endDate,
            projectContractNumber: this.model.projectContractNumber,
            currentProjectIds: this.model.currentProjectIds ?? [],
            futureProjectId: this.model.futureProjectId,
            betabitEntity: this.model.betabitEntity,
            regionalManager: this.model.regionalManager,
          });
          this.getProjectsForSelectedClient(this.model.clientId);
        },
        error: () => {
          this.snackBar.open(
            this.translateService.instant('general.error_loading_data')
          );
        },
      });
    }
  }

  clientChanged(clientId: number) {
    if (clientId) {
      this.getProjectsForSelectedClient(clientId);
    }
  }

  getProjectsForSelectedClient(clientId: number) {
    return this.projectService.getProjectsForSelect(clientId).subscribe({
      next: (projects: any) => {
        this.projects = projects;
      },
      error: (error: any) => {
        this.snackBar.open(
          this.translateService.instant('general.error_loading_data')
        );
      },
    });
  }

  getEmployeesForSelect() {
    this.employeeService.getEmployeesForSelect().subscribe({
      next: (employees: any) => {
        this.employees = employees;
      },
      error: (error: any) => {
        this.snackBar.open(
          this.translateService.instant('general.error_loading_data')
        );
      },
    });
  }

  onSubmit() {
    this.mapFormValuesToModel();
    if (this.resourceAllocationId !== '') {
      this.resourceAllocationService.update(this.model).subscribe({
        next: () => {
          this.snackBar.open(
            this.translateService.instant('resource_allocation.updated')
          );
          this.router.navigate(['/resource-allocation']);
        },
        error: () => {
          this.snackBar.open(
            this.translateService.instant('error_messages.INVALID_DATA')
          );
        },
      });
    } else {
      this.resourceAllocationService.add(this.model).subscribe({
        next: () => {
          this.snackBar.open(
            this.translateService.instant('resource_allocation.created')
          );
          this.router.navigate(['/resource-allocation']);
        },
        error: () => {
          this.snackBar.open(
            this.translateService.instant('error_messages.INVALID_DATA')
          );
        },
      });
    }
  }

  onClose() {
    this.router.navigate(['/resource-allocation']);
  }

  private mapFormValuesToModel() {
    this.model.employeeId = this.resourceAllocationForm.value.employeeId! ?? -1;
    this.model.clientId = this.resourceAllocationForm.value.clientId! ?? -1;
    this.model.projectContractNumber =
      this.resourceAllocationForm.value.projectId ?? '';
    this.model.startDate =
      new Date(this.resourceAllocationForm.value.startDate!) ?? new Date();
    this.model.endDate =
      new Date(this.resourceAllocationForm.value.endDate!) ?? new Date();
    this.model.projectContractNumber =
      this.resourceAllocationForm.value.projectContractNumber ?? '';
    this.model.currentProjectIds =
      this.resourceAllocationForm.value.currentProjectIds ?? [];
    this.model.futureProjectId =
      this.resourceAllocationForm.value.futureProjectId ?? -1;
    this.model.betabitEntity =
      this.resourceAllocationForm.value.betabitEntity ?? '';
    this.model.regionalManager =
      this.resourceAllocationForm.value.regionalManager ?? '';
  }
}
