import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BusinessTrip } from '../interfaces/businessTrip';

@Injectable({
  providedIn: 'root'
})
export class BusinessTripService {

  url = environment.apiEofficeConfig.endpoint + '/BusinessTrip';
  http = inject(HttpClient);
  
  getAllForEmployee() {
    return this.http.get(this.url + '/GetAllForEmployee/');
  }

  getAllAdmin(){
    return this.http.get(this.url + '/GetAll');
  }

  delete(id: number) {
    return this.http.delete(this.url + '/' + id);
  }

  update(model: BusinessTrip) {
    return this.http.put(this.url, model);
  }

  create(model: BusinessTrip) {
    return this.http.post(this.url, model);
  }

  getSickLeave(id: number) {
    return this.http.get(this.url + '/' + id);
  }
}
