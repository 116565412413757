import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  http = inject(HttpClient);
  url = environment.apiEofficeConfig.endpoint + '/home';


  getUserProfilePicture() : Observable<Blob> {
    return this.http.get('https://graph.microsoft.com/v1.0/me/photo/$value', { responseType: 'blob', headers: { 'Content-Type': 'image/jpeg' }});
  }

  getUserProfile() {
    return this.http.get('https://graph.microsoft.com/v1.0/me', { headers: { 'Content-Type': 'application/json', }});
  }

  getlandingPageData(month: string) {
    return this.http.get(this.url + '?month=' + month);
  }
}
