import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TotalMonthlyWorkingHours } from '../interfaces/totalMonthlyWorkingHours';
import { Invoice } from '../interfaces/monthlyWorkingHoursItems';

@Injectable({
  providedIn: 'root'
})
export class InvoicePredictionServiceService {

  http = inject(HttpClient);
  url = environment.apiEofficeConfig.endpoint + '/invoicePrediction';
  
  update(invoicePredictionData: Invoice) {
    return this.http.put(this.url, invoicePredictionData);
  }
  create(invoicePredictionData: Invoice) {
    return this.http.post(this.url, invoicePredictionData);
  }
  getInvoicePredictionData(searchParams: any) {
    return this.http.post(this.url + '/GetAll', searchParams);
  }

  constructor() { }
}
