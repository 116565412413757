import {
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Vacation } from '../interfaces/vacation';
import { VacationService } from '../services/vacation.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AsyncPipe, formatDate } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { Employee } from '../../employee-menagement/interfaces/employee';
import { Observable, of } from 'rxjs';
import moment from 'moment';
import { SpecialOccasionDayModel } from '../../special-occasion-day/interfaces/specialOccasionDayModel';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-vacations-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatDivider,
    MatAutocompleteModule,
    AsyncPipe,
    MatIconModule,
    MatCheckboxModule,
    TranslateModule,
    MatSelectModule,
  ],
  templateUrl: './vacations-dialog.component.html',
  styleUrl: './vacations-dialog.component.scss',
})
export class VacationsDialogComponent implements OnInit {
  //Inject services
  vacationService = inject(VacationService);
  dialogRef = inject(MatDialogRef<VacationsDialogComponent>);
  formBuilder = inject(FormBuilder);
  snackBar = inject(MatSnackBar);
  data = inject(MAT_DIALOG_DATA);
  activatedRoute = inject(ActivatedRoute);
  translateService = inject(TranslateService);

  @ViewChild('inputName') input: ElementRef<HTMLInputElement>;
  isAdminUser: boolean = false;
  employees: Employee[] = [];
  specialOccasionDays: SpecialOccasionDayModel[] = [];
  filteredEmployees: Observable<Employee[]>;
  model: Vacation;
  vacationForm = this.formBuilder.group(
    {
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      isSpecial: [false],
      specialOccasionId: new FormControl<number | null>(null),
      employee: new FormControl<string | Employee>(''),
    },
    { validators: specialOccasionIdValidator() }
  );

  ngOnInit(): void {
    this.model = this.data.model;
    this.isAdminUser = this.data.isAdminUser;
    this.employees = this.data.employees;
    this.specialOccasionDays = this.data.specialOccasionDays;

    if (this.isAdminUser) {
      this.vacationForm.controls.employee.setValidators([Validators.required]);
    }

    if (this.model.id != 0) {
      this.vacationForm.patchValue({
        startDate: this.model.startDate,
        endDate: this.model.endDate,
        isSpecial: this.model.isSpecial,
        specialOccasionId: this.model.specialOccasionId,
        employee: this.isAdminUser
          ? this.employees.find((e) => e.id === this.model.employeeId)
          : new Employee(),
      });
    }
  }

  filter(): void {
    const filterValue = this.input.nativeElement.value.toLowerCase();
    this.filteredEmployees = filterValue
      ? of(
          this.employees.filter((option) =>
            option.fullName.toLowerCase().includes(filterValue)
          )
        )
      : of(this.employees.slice());
  }

  displayFn(employee: Employee): string {
    return employee && employee.id ? employee.fullName : '';
  }

  vacationDateFilter = (d: Date | null): boolean => {
    const month = moment(d).month();
    const day = moment(d).day();
    if (this.isAdminUser) {
      return day !== 0 && day !== 6;
    }
    return day !== 0 && day !== 6 && month >= moment().month();
  };

  onSubmit() {
    this.mapVacationFormValuesToModel();
    if (this.model.id === 0) {
      this.createVacation();
    } else {
      this.updateVacation();
    }
  }

  updateVacation(): void {
    this.vacationService.update(this.model).subscribe({
      next: () => {
        this.snackBar.open(
          this.translateService.instant('vacation.updated'),
          '',
          { panelClass: 'snackbar-success' }
        );
        this.dialogRef.close(true);
      },
      error: (err) => {
        const translatedError = this.translateService.instant(
          'error_messages.' + err.error.message
        );
        this.snackBar.open(
          this.translateService.instant('general.error') + translatedError,
          '',
          { panelClass: 'snackbar-error' }
        );
      },
    });
  }

  createVacation(): void {
    this.vacationService.create(this.model).subscribe({
      next: () => {
        this.snackBar.open(
          this.translateService.instant('vacation.created'),
          '',
          { panelClass: 'snackbar-success' }
        );
        this.dialogRef.close(true);
      },
      error: (err) => {
        const translatedError = this.translateService.instant(
          'error_messages.' + err.error.message
        );
        this.snackBar.open(
          this.translateService.instant('general.error') + translatedError,
          '',
          { panelClass: 'snackbar-error' }
        );
      },
    });
  }

  mapVacationFormValuesToModel() {
    this.model.startDate = formatDate(
      this.vacationForm.value.startDate!,
      'yyyy-MM-dd',
      'en-GB'
    );
    this.model.endDate = formatDate(
      this.vacationForm.value.endDate!,
      'yyyy-MM-dd',
      'en-GB'
    );
    this.model.isSpecial = this.vacationForm.value.isSpecial ?? false;
    if (!this.model.isSpecial) {
      this.model.specialOccasionId = null;
    } else {
      this.model.specialOccasionId = this.vacationForm.value.specialOccasionId!;
    }
    this.model.employeeId = (
      this.vacationForm.controls.employee.value as Employee
    ).id;
  }

  onClose() {
    this.dialogRef.close(false);
  }
}

function specialOccasionIdValidator(): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    const isSpecial: boolean = form.get('isSpecial')?.value;
    const specialOccasionId: number | null =
      form.get('specialOccasionId')?.value;
    if (isSpecial && (specialOccasionId == null || specialOccasionId <= 0)) {
      return { specialOccasionId: true };
    }
    return null;
  };
}
