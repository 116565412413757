<div class="container">
  <div class="container-header">
    @if(this.isAdminUser){
    <button mat-raised-button color="primary" (click)="addTeamBuilding()">
      {{ "teambuilding.request" | translate }}
    </button>
    }
    <span class="spacer"></span>
    <mat-form-field>
      <mat-label
        ><mat-icon>search</mat-icon
        >{{ "general.search" | translate }}</mat-label
      >
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="{{ 'general.search_placeholder' | translate }}"
        #input
      />
    </mat-form-field>
  </div>
  <div>
    <mat-card>
      <mat-card-header class="mat-card-header">
        <mat-card-title>
          <h4>{{ "teambuilding.container_header" | translate }}</h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="table-container">
          <table
            mat-table
            [dataSource]="this.dataSource"
            class="mat-elevation-z8"
            matSort
          >
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "teambuilding.table-headers.title" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.title }}</td>
            </ng-container>
            <ng-container matColumnDef="place">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "teambuilding.table-headers.place" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.placeName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="startDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "teambuilding.table-headers.start_date" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.startDate | date }}
              </td>
            </ng-container>
            <ng-container matColumnDef="endDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "teambuilding.table-headers.end_date" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.endDate | date }}
              </td>
            </ng-container>
            <ng-container matColumnDef="numberOfDays">
              <th mat-header-cell *matHeaderCellDef>
                {{
                  "teambuilding.table-headers.dead_line_check_in" | translate
                }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.numberOfDays }}
              </td>
            </ng-container>
            <ng-container matColumnDef="action" *ngIf="this.isAdminUser">
              <th mat-header-cell *matHeaderCellDef>
                {{ "teambuilding.table-headers.action" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <button
                  mat-button
                  color="primary"
                  (click)="editTeamBuilding(element.id)"
                  disabled="{{
                    element.approved != null || !isEventActive(element)
                  }}"
                >
                  <mat-icon>draw</mat-icon>
                </button>
                <button
                  mat-button
                  color="warn"
                  (click)="deleteTeamBuilding(element.id)"
                  disabled="{{
                    element.approved != null || !isEventActive(element)
                  }}"
                >
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="accept">
              <th mat-header-cell *matHeaderCellDef>
                {{ "teambuilding.table-headers.accept" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <button
                  mat-button
                  color="primary"
                  (click)="changeStatusOfTeamBuildingRequest(element, true)"
                  disabled="{{
                    element.approved != null || !isEventActive(element)
                  }}"
                >
                  <mat-icon class="green">thumb_up</mat-icon>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="reject">
              <th mat-header-cell *matHeaderCellDef>
                {{ "teambuilding.table-headers.reject" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <button
                  mat-button
                  color="warn"
                  (click)="changeStatusOfTeamBuildingRequest(element, false)"
                  disabled="{{
                    (element.userAcceptedEvent != null &&
                      !element?.userAcceptedEvent) ||
                      !isEventActive(element)
                  }}"
                >
                  <mat-icon class="red">thumb_down</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">
                {{ "general.no_data" | translate }} "{{ input.value }}"
              </td>
            </tr>
          </table>
        </div>
        <mat-paginator
          [pageSizeOptions]="[10, 25]"
          showFirstLastButtons="true"
          aria-label="Select page of team-building"
        ></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
