import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
    selector: 'app-parking',
    imports: [
        MatCardModule,
        MatToolbarModule,
        MatDividerModule,
        MatTableModule,
        CommonModule,
    ],
    templateUrl: './parking.component.html',
    styleUrl: './parking.component.scss'
})
export class ParkingComponent {
  displayedColumns: string[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
  ];

  parkingSlots = [
    '232',
    '233',
    '234',
    '235',
    '236',
    '237',
    '238',
    '239',
    '240',
  ];

  constructor() {
    this.generateNextFiveDays();
    this.initializeReservations();
    // this.displayedColumns.push(
    //   ...this.nextFiveDays.map((day) => day.toString())
    // );
  }

  generateNextFiveDays() {
    const today = new Date();
    for (let i = 0; i < 5; i++) {
      const date = new Date();
      date.setDate(today.getDate() + i);
      //   this.nextFiveDays.push(date);
    }
  }

  initializeReservations() {
    // this.reservations = this.parkingSlots.map(() => Array(5).fill(true));
  }

  isAvailable(spotId: number, dayIndex: number): boolean {
    return true;
    //  return this.reservations[spotId - 1][dayIndex];
  }

  bookSpot(spotId: number, dayIndex: number) {
    // this.reservations[spotId - 1][dayIndex] = false;
  }
}
