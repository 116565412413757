import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SpecialOccasionDayModel } from '../interfaces/specialOccasionDayModel';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { ConfrmationDialogComponent } from '../../utils/confrmation-dialog/confrmation-dialog.component';
import { SpecialOccasionDayService } from '../special-occasion-day.service';
import { FormsModule } from '@angular/forms';
import { SpecialOccasionDayDialogComponent } from '../special-occasion-day-dialog/special-occasion-day-dialog.component';
import { MatSort } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-special-occasion-day-list',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatCardModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    FormsModule,
    MatButtonModule,
    TranslateModule,
  ],
  templateUrl: './special-occasion-day-list.component.html',
  styleUrl: './special-occasion-day-list.component.scss',
})
export class SpecialOccasionDayListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource<SpecialOccasionDayModel>();
  page: number = 1;
  total: number;
  pageSize: number = 10;

  displayedColumns: string[] = ['description', 'actions'];

  dialog = inject(MatDialog);
  snackBar = inject(MatSnackBar);
  specialOccasionDayService = inject(SpecialOccasionDayService);
  tranlsateService = inject(TranslateService);

  ngOnInit(): void {
    this.getSpecialOccasionDayList();
  }

  openDialog() {
    this.dialog
      .open(SpecialOccasionDayDialogComponent, {
        data: { model: { id: 0, descritpion: '' } },
        width: '500px',
        maxHeight: '500px',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.getSpecialOccasionDayList();
        }
      });
  }

  deleteEntity(elementId: number) {
    const dialogRef = this.dialog.open(ConfrmationDialogComponent);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.specialOccasionDayService.delete(elementId).subscribe({
          next: () => {
            this.snackBar.open(
              this.tranlsateService.instant('special-occasion.deleted'),
              '',
              {
                panelClass: ['snackbar-success'],
              }
            );
            this.getSpecialOccasionDayList();
          },
          error: (error) => {
            this.snackBar.open(
              this.tranlsateService.instant('special-occasion.error') + error,
              '',
              {
                panelClass: ['snackbar-error'],
              }
            );
          },
        });
      }
    });
  }

  editEntity(model: SpecialOccasionDayModel) {
    this.dialog
      .open(SpecialOccasionDayDialogComponent, {
        data: { model: model },
        width: '500px',
        maxHeight: '500px',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.getSpecialOccasionDayList();
        }
      });
  }

  getSpecialOccasionDayList() {
    this.specialOccasionDayService
      .getAll()
      .subscribe((response: SpecialOccasionDayModel[]) => {
        this.dataSource = new MatTableDataSource<SpecialOccasionDayModel>(
          response
        );
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.total = response.length;
      });
  }
}
