<div class="container">
  <div>
    <mat-card>
      <mat-card-header class="mat-card-header">
        <mat-card-title>
          <h4>{{ (this.id ? 'teambuilding.edit' :'teambuilding.add') | translate }}</h4>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div>
          <form [formGroup]="teambuildingForm">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'teambuilding.title' | translate }}</mat-label>
                <input matInput formControlName="title" placeholder="{{ 'teambuilding.title' | translate }}" required>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>{{ 'teambuilding.place' | translate }}</mat-label>
                <input matInput formControlName="placeName" placeholder="{{ 'teambuilding.place' | translate }}" required>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>{{ 'teambuilding.place_address' | translate }}</mat-label>
                <input matInput formControlName="placeAddress" placeholder="{{ 'teambuilding.place_address' | translate }}" required>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'teambuilding.start_date' | translate }}</mat-label>
                <input matInput formControlName="startDate" placeholder="{{ 'teambuilding.start_date' | translate }}"
                  [matDatepicker]="picker" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <mat-form-field>
                <mat-label>{{ 'teambuilding.end_date' | translate }}</mat-label>
                <input matInput formControlName="endDate" placeholder="{{ 'teambuilding.end_date' | translate }}"
                  [matDatepicker]="picker2" required>
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
     
              <mat-form-field>
                <mat-label>{{ 'teambuilding.dead_line_check_in' | translate }}</mat-label>
                <input matInput formControlName="deadlineForCheckIn" placeholder="{{ 'teambuilding.dead_line_check_in' | translate }}"
                  [matDatepicker]="picker3" required>
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
              </mat-form-field>

              <mat-form-field>
                <mat-select required disableOptionCentering="true" formControlName="emailType"
                  placeholder="Select email Type" name="emailType">
                  <mat-option>--</mat-option>
                  <mat-option value="teambuilding">
                    {{ 'teambuilding.teambuilding_lable' | translate }}
                  </mat-option>
                  <mat-option value="trip">
                    {{ 'teambuilding.trip_label' | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <button mat-button [disabled]="this.teambuildingForm.invalid" (click)="showTeamBuildingEmailPreview(teambuildingForm.value)">
                {{ 'teambuilding.email_preview' | translate }} </button>
          
            <div class="extension-panel">
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title> {{ 'teambuilding.select_employees' | translate}} </mat-panel-title>
                  <mat-panel-description> {{ 'teambuilding.select_employes_description' | translate}}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-selection-list  formControlName="employeesOnEvent">
                  @for (employee of employees; track employee) {
                  <mat-list-option [value]="employee.id">{{employee.fullName}}</mat-list-option>
                  }
                </mat-selection-list>
              </mat-expansion-panel>
            </div>
            </div>
          </form>
        </div>
        <div class="action-buttons">
          <button button mat-button  (click)="navigateToTeamBuidlingList();">{{'buttons.cancel' | translate}}</button>
          <button button mat-button  [disabled]="teambuildingForm.invalid"
          (click)="id ? updateData() : saveData()">{{'buttons.save' | translate}}</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>