import { Component, OnInit, inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { RouterModule } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { clearGroupsInStorage } from '../utils/storage-utils';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
} from '@azure/msal-browser';
import { HomeService } from './home.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UserProfile } from './interfaces/userProfile';
import { RoleService } from '../utils/role.service';
import { groups } from '../../environments/environment';
import { LoadingBarComponent } from '../utils/loading-bar/loading-bar.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../shared/theme.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss',
    imports: [
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatIconModule,
        MatGridListModule,
        MatMenuModule,
        AsyncPipe,
        MatExpansionModule,
        RouterModule,
        LoadingBarComponent,
        MatCardModule,
        MatButtonToggleModule,
        FormsModule,
        TranslateModule,
    ]
})
export class HomeComponent implements OnInit {
  private breakpointObserver = inject(BreakpointObserver);

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  imageToShow: any;
  profileImage: any;
  loginDisplay = false;
  domSanitizer = inject(DomSanitizer);
  roleService = inject(RoleService);
  authService = inject(MsalService);
  homeService = inject(HomeService);
  msalBroadcastService = inject(MsalBroadcastService);
  tranlsateService = inject(TranslateService);
  msalGuardConfig = inject<MsalGuardConfiguration>(MSAL_GUARD_CONFIG);
  themeService = inject(ThemeService);
  //varables
  currentUserProfile: UserProfile = { displayName: '', jobTitle: '', mail: '' };
  isAdminUser: boolean;
  authGroups = groups;

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    this.isAdminUser = this.roleService.checkIfUserIsInGroup(
      this.authGroups.EofficeAdmin
    );
    this.getProfilePicture();
    this.homeService.getUserProfile().subscribe((data) => {
      this.currentUserProfile = data as UserProfile;
    });
  }

  onProfileClick() {}

  onLanguageChange(event: string) {
    this.tranlsateService.use(event);
    localStorage.setItem('language', event);
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  getProfilePicture() {
    this.homeService.getUserProfilePicture().subscribe((data) => {
      let objectURL = URL.createObjectURL(data);
      this.profileImage = this.domSanitizer.bypassSecurityTrustUrl(objectURL);
    });
  }

  logOut() {
    const activeAccount =
      this.authService.instance.getActiveAccount() ||
      this.authService.instance.getAllAccounts()[0];
    clearGroupsInStorage(activeAccount); // make sure to remove groups from storage
    localStorage.removeItem('account_username');
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        account: activeAccount,
      });
    } else {
      this.authService.logoutRedirect({
        account: activeAccount,
      });
    }
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        console.log(reader.result);
        const imgRes: any = reader.result;
        this.profileImage = this.domSanitizer.bypassSecurityTrustUrl(imgRes);
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  onThemeChange() {
    this.themeService.toggleTheme();
  }
}
