import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Seniority } from '../interfaces/seniority';
import { SeniorityService } from '../seniority.service';
import { SeniorityDialogComponent } from '../seniority-dialog/seniority-dialog.component';
import { ConfrmationDialogComponent } from '../../utils/confrmation-dialog/confrmation-dialog.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-seniority',
  standalone: true,
  imports: [
    MatExpansionModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    MatIconModule,
    TranslateModule,
  ],
  templateUrl: './seniority.component.html',
  styleUrl: './seniority.component.scss',
})
export class SeniorityComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = [
    'title',
    'unitPrice',
    'description',
    'active',
    'actions',
  ];
  dataSource = new MatTableDataSource<Seniority>();
  page: number = 1;
  total: number;
  pageSize: number = 5;

  seniorityService = inject(SeniorityService);
  dialog = inject(MatDialog);
  translateService = inject(TranslateService);

  ngOnInit(): void {
    this.getSeniorityList();
  }

  editEntity(model: any) {
    this.dialog
      .open(SeniorityDialogComponent, {
        data: { model: model },
        width: '500px',
        maxHeight: '500px',
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getSeniorityList();
        }
      });
  }

  deleteEntity(id: number) {
    const dialogRef = this.dialog.open(ConfrmationDialogComponent);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.seniorityService.delete(id).subscribe(() => {
          this.getSeniorityList();
        });
      }
    });
  }

  applyFilter($event: Event) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addSeniority() {
    this.dialog
      .open(SeniorityDialogComponent, {
        data: {
          model: {
            id: 0,
            title: '',
            unitPrice: 0,
            description: '',
            active: false,
          },
        },
        width: '500px',
        maxHeight: '500px',
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getSeniorityList();
        }
      });
  }

  private getSeniorityList() {
    this.seniorityService.getAll().subscribe((data: any) => {
      this.dataSource = new MatTableDataSource<Seniority>(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.total = data.length;
    });
  }
}
