import { Component, inject, OnInit } from '@angular/core';
import { HomeTileComponent } from '../home-tile/home-tile.component';
import { HomeService } from '../home/home.service';
import { RouterModule } from '@angular/router';
import { LandingPageModel } from './interfaces/LandingPageModel';

@Component({
  selector: 'app-home-content',
  standalone: true,
  imports: [HomeTileComponent, RouterModule],
  templateUrl: './home-content.component.html',
  styleUrl: './home-content.component.scss',
})
export class HomeContentComponent implements OnInit {
  homeService = inject(HomeService);

  landingPageData: LandingPageModel = {
    numberOfVacationDayRequests: 0,
    pendingEventInvites: 0,
    pendingWorkFromHomeRequests: 0,
    pendingSickLeaveRequests: 0,
    numberOfDaysInTheOffice: 0,
    numberOfWorkingDaysInTheMonth: 0,
  };

  ngOnInit(): void {
    this.homeService
      .getlandingPageData(new Date().getMonth() + 1 + '')
      .subscribe((data: any) => {
        this.landingPageData = data;
      });
  }
}
