export class MonthlyExpenses {
    id: number;
    insurance: number;
    other: number;
    planeTickets: number;
    lunch: number;
    travellingAllowance: number;
    total: number;
    month: number;
    year: number;
    employeeId: number;
}
